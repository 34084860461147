import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
    Area,
    LogoContainer,
    LogoRecord,
    Nav,
    PageContainer,
} from 'shared/components';
import Popups from './popups';
import { usePopup } from 'shared/hooks';
import { Paths } from 'shared/constants';
import * as assets from './assets';
import { loadBeforeDisplay } from 'shared/hocs';

const ClickItImg = styled.img.attrs(() => assets.clickIt)`
    cursor: pointer;
    left: 536px;
    position: absolute;
    top: 371px;
`;

const LogoContainerOuter = styled.div`
    left: 498px;
    position: absolute;
    top: 24px;
`;

const StyledNav = styled(Nav)`
    left: 566px;
    position: absolute;
    top: 105px;
`;

const ContactPageTitle = styled.img.attrs(
    () => assets.articlesAndInterviewsTitle
)`
    left: 57px;
    position: absolute;
    top: 23px;
`;

const TextContainer = styled.div`
    font-family: 'Courier New';
    font-size: 11px;
    left: 25px;
    position: absolute;
    top: 150px;
    width: 450px;
`;

const UL = styled.ul`
    padding: 0;
`;

const LI = styled.li`
    line-height: 150%;
    list-style: none;
    margin-bottom: 4px;
`;

const A = styled.a.attrs(() => ({ target: '_blank' }))`
    font-weight: bold;
    text-decoration: underline;

    &:hover {
        color: white;
    }
`;

const Date = styled.span`
    display: inline-block;
    font-weight: bold;
    margin: 0 12px;
`;

const Description = styled.em`
    color: #333;
    font-weight: bold;
    padding-top: 4px;
    white-space: nowrap;
`;

const ROOT_ID = 'articles-and-interviews-root';

const articles = [
    {
        href: 'https://www.youtube.com/watch?v=cHxMsawJsTc',
        title: 'Mixcon',
        date: 'August 2017',
        description: 'Mixing Masterclass',
    },
    {
        href: 'https://tapeop.com/interviews/60/bob-power/',
        title: 'Tape Op',
        date: 'August 2007',
        description: 'Seminal NYC Hip-Hop engineer ',
    },
    {
        href: 'https://behindthespeakers.com/low-end-bob-power/',
        title: 'Behind the Speakers',
        date: 'October 2017',
        description: 'How To Mix a Powerful Low End',
    },
    {
        href:
            'https://www.waxpoetics.com/blog/features/record-rundown/studio-rundown-with-bob-power/',
        title: 'Wax Poetics',
        date: 'May 2014',
        description: 'Studio Rundown',
    },
    {
        href:
            'https://daily.redbullmusicacademy.com/2014/07/bob-power-interview',
        title: 'Red Bull Interview',
        date: 'July 2014',
        description: 'on D’Angelo, Tribe Called Quest and De La Soul',
    },
    {
        href: 'https://www.youtube.com/watch?v=FPxXLn0rqAo',
        title: 'Red Bull Video Demonstration',
        date: 'July 2014',
        description: 'Studio Science',
    },
    {
        href:
            'https://www.okayplayer.com/news/secret-history-bob-power-the-low-end-theory.html',
        title: 'Okay Player',
        date: '2016',
        description: 'Secret History',
    },
    {
        href:
            'https://sonicscoop.com/2011/02/02/mix-and-zen-with-bob-power-musings-of-an-nyc-music-master/',
        title: 'Sonic Scoop',
        date: 'February 2011',
        description: 'Mix and Zen',
    },
    {
        href:
            'https://en.audiofanzine.com/sound-technique/editorial/articles/power-to-the-music.html',
        title: 'Audio Fanzine',
        date: 'March 2018',
        description: 'Power to the Music',
    },
    {
        href: 'https://bobbyoinnercircle.com/tag/bob-power/',
        title: 'Inner Circle',
        date: 'December 2017',
        description: '',
    },
    {
        href: 'https://www.emusician.com/artists/bob-power',
        title: 'Electronic Musician',
        date: 'October 2011',
        description: '',
    },
];

const ArticlesAndInterviews = () => {
    const { PopupModal, setCurrentPopup } = usePopup(Popups, ROOT_ID);
    return (
        <PageContainer id={ROOT_ID}>
            <img
                alt={assets.articlesAndInterviewsBg.src}
                src={assets.articlesAndInterviewsBg.src}
                useMap="#articles-and-interviews-map"
            />
            <map name="articles-and-interviews-map">
                <Area
                    alt="fender-tele-custom"
                    coords="586,508,52"
                    onClick={() => setCurrentPopup('FenderTeleCustom')}
                    shape="circle"
                />
                <Link to={Paths.HOME}>
                    <Area alt="logo-text" coords="591,43,715,55" shape="rect" />
                </Link>
            </map>
            <ContactPageTitle />
            <ClickItImg onClick={() => setCurrentPopup('FenderTeleCustom')} />
            <LogoContainerOuter>
                <LogoContainer>
                    <LogoRecord />
                </LogoContainer>
            </LogoContainerOuter>
            <StyledNav />
            <TextContainer>
                <UL>
                    {articles.map(config => (
                        <LI key={config.title}>
                            <A href={config.href}>- {config.title}</A>
                            <Date>{config.date}</Date>
                            <Description>{config.description}</Description>
                        </LI>
                    ))}
                    {/* <LI>
                        <strong>
                            <A>Mix Magazine</A> August 2003
                        </strong>{' '}
                        - The Roots
                    </LI>
                    <LI>
                        <strong>
                            <A>EQ</A> October 2002
                        </strong>
                        <br />
                        "Big Bottom" Three engineers get to the bottom of their
                        mixes
                    </LI>
                    <LI>
                        <strong>
                            <A>One World</A> 2001 Volume 6 Issue 2
                        </strong>{' '}
                        - <em>Soundscaping</em>
                    </LI>
                    <LI>
                        <strong>
                            <A>Ego Trip Magazine</A>
                        </strong>{' '}
                        - <em>In the Mix with Bob Power</em>
                    </LI>
                    <LI>
                        <strong>
                            <A>Rap Pages</A> February 1999
                        </strong>{' '}
                        - <em>The Sonic Architect</em>
                    </LI>
                    <LI>
                        <strong>
                            <A>Electronic Musician Magazine</A> December 1998
                        </strong>{' '}
                        - <em>Urban Players</em>
                    </LI>
                    <LI>
                        <strong>
                            <A>Billboard</A> July 1997
                        </strong>
                        <br />
                        <em>R&B Acts Find Soul Mate In Producer Bob Power</em>
                    </LI> */}
                </UL>
            </TextContainer>
            <PopupModal />
        </PageContainer>
    );
};

export default loadBeforeDisplay(ArticlesAndInterviews, assets.forPreload);
