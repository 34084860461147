import {
    contactBg,
    contactClickIt,
    contactEmailLink,
    contactPageTitle,
} from 'shared/assets/img';

import { guildF50, pensaSuhr, u67, vetta } from './popups';

export { contactBg, contactClickIt, contactEmailLink, contactPageTitle };

export const forPreload = {
    img: [
        contactBg,
        contactClickIt,
        contactEmailLink,
        contactPageTitle,
        guildF50,
        pensaSuhr,
        u67,
        vetta,
    ].map(x => x.src),
    audio: [guildF50, pensaSuhr, u67, vetta].map(x => x.audio || ''),
};
