import * as React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { Hideable } from 'shared/types';
import { fadeIn } from '../../styles';

import { contactArt } from 'pages/main/assets';

interface ContactArtProps extends Hideable {}

const ContactArtImg = styled.img.attrs(() => contactArt)`
    left: 300px;
    position: absolute;
    top: 205px;
    width: 176px;

    ${fadeIn}
`;

const ContactArt: React.FC<ContactArtProps> = props => (
    <CSSTransition in={props.isVisible} mountOnEnter timeout={1000}>
        <ContactArtImg />
    </CSSTransition>
);

export default ContactArt;
