import {
    studioBg,
    studioBobPower,
    studioContact,
    studioDiscography,
    studioHome,
    studioProjects,
    studioRecordBg,
    studioSubmissions,
    studioTimeline,
} from 'shared/assets/img';

import { aguilar, audioRack, console, stompboxes, synths } from './popups';

export {
    studioBg,
    studioBobPower,
    studioContact,
    studioDiscography,
    studioHome,
    studioProjects,
    studioRecordBg,
    studioSubmissions,
    studioTimeline,
};

export const forPreload = {
    img: [
        aguilar,
        audioRack,
        console,
        stompboxes,
        synths,
        studioBg,
        studioBobPower,
        studioContact,
        studioDiscography,
        studioHome,
        studioProjects,
        studioRecordBg,
        studioSubmissions,
        studioTimeline,
    ].map(x => x.src),
    audio: [aguilar, audioRack, console, stompboxes, synths]
        .map(x => x.audio || '')
        .filter(x => x),
};
