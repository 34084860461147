import aguilarJpg from './aguilar.jpg';
import articlesAndInterviewsBgJpg from './articles-and-interviews-bg.jpg';
import articlesAndInterviewsButtonHoverGif from './articles-and-interviews-button-hover.gif';
import articlesAndInterviewsButtonGif from './articles-and-interviews-button.gif';
import articlesAndInterviewsTitleJpg from './articles-and-interviews-title.jpg';
import audioRackJpg from './audio-rack.jpg';
import bioBgJpg from './bio-bg.jpg';
import bioPageTitleJpg from './bio-page-title.jpg';
import bobScribblesPng from './bob-scribbles.png';
import c12Jpg from './c12.jpg';
import c24Jpg from './c24.jpg';
import clickItGif from './click-it.gif';
import compressorsJpg from './compressors.jpg';
import consoleJpg from './console.jpg';
import contactArtPng from './contact-art.png';
import contactBgJpg from './contact-bg.jpg';
import contactClickItGif from './contact-click-it.gif';
import contactEmailLinkJpg from './contact-email-link.jpg';
import contactPageTitleJpg from './contact-page-title.jpg';
import fenderJazzBassJpg from './fender-jazz-bass.jpg';
import fenderStratJpg from './fender-strat.jpg';
import fenderTeleCustomJpg from './fender-tele-custom.jpg';
import fenderTeleDeluxeJpg from './fender-tele-deluxe.jpg';
import genelecsJpg from './genelecs.jpg';
import gibsonEs295Jpg from './gibson-es295.jpg';
import gibsonEs335Jpg from './gibson-es335.jpg';
import gibsonHowardRobertsJpg from './gibson-howard-roberts.jpg';
import gibsonJ200Jpg from './gibson-j200.jpg';
import glJpg from './gl.jpg';
import guildF50Jpg from './guild-f50.jpg';
import guitarsBackHoverJpg from './guitars-back-hover.jpg';
import guitarsBgJpg from './guitars-bg.jpg';
import interviewGif from './interview.gif';
import introAnimationJpg from './intro-animation.jpg';
import l47Jpg from './l47.jpg';
import l251Jpg from './l251.jpg';
import mainArrowPng from './main-arrow.png';
import mainBackgroundJpg from './main-background.jpg';
import mainBobTextJpg from './main-bob-text.jpg';
import mainBobPng from './main-bob.png';
import mainGuitarArtPng from './main-guitar-art.png';
import mainGuitarScribblesPng from './main-guitar-scribbles.png';
import mainGuitarPng from './main-guitar.png';
import mainMicrophoneLightningJpg from './main-microphone-lightning.jpg';
import mainMicrophonePng from './main-microphone.png';
import mainMixingBoardOverPng from './main-mixing-board-over.png';
import mainMixingBoardUnderPng from './main-mixing-board-under.png';
import martinD16hJpg from './martin-d16h.jpg';
import micClosetBackHoverJpg from './mic-closet-back-hover.jpg';
import micClosetBgJpg from './mic-closet-bg.jpg';
import mixRackJpg from './mix-rack.jpg';
import mixRoomHoverJpg from './mix-room-hover.jpg';
import mixRoomJpg from './mix-room.jpg';
import mixingAndMasteringGif from './mixing-and-mastering.gif';
import needQuietJpg from './need-quiet.jpg';
import neve1073Jpg from './neve-1073.jpg';
import neve1095Jpg from './neve-1095.jpg';
import pendulumJpg from './pendulum.jpg';
import pensaSuhrJpg from './pensa-suhr.jpg';
import projectsArticlesInterviewsHoverJpg from './projects-articles-interviews-hover.jpg';
import projectsBgJpg from './projects-bg.jpg';
import projectsClickItGif from './projects-click-it.gif';
import projectsTextPng from './projects-text.png';
import rhodesJpg from './rhodes.jpg';
import sadowskyJpg from './sadowsky.jpg';
import schoepsJpg from './schoeps.jpg';
import sessionStratJpg from './session-strat.jpg';
import sm57Jpg from './sm57.jpg';
import stompboxesJpg from './stompboxes.jpg';
import studioBgJpg from './studio-bg.jpg';
import studioBobPowerJpg from './studio-bob-power.jpg';
import studioContactJpg from './studio-contact.jpg';
import studioDiscographyJpg from './studio-discography.jpg';
import studioHomeJpg from './studio-home.jpg';
import studioProjectsJpg from './studio-projects.jpg';
import studioRecordBgJpg from './studio-record-bg.jpg';
import studioSubmissionsJpg from './studio-submissions.jpg';
import studioTimelineJpg from './studio-timeline.jpg';
import submissionsBgJpg from './submissions-bg.jpg';
import submissionsPageTitleJpg from './submissions-page-title.jpg';
import submissionsTitleClickItGif from './submissions-title-click-it.gif';
import suitcaseJpg from './suitcase.jpg';
import synthsJpg from './synths.jpg';
import timelineOverlayJpg from './timeline-overlay.jpg';
import timelineJpg from './timeline.jpg';
import u47Jpg from './u47.jpg';
import u67Jpg from './u67.jpg';
import vettaJpg from './vetta.jpg';
import vm1Jpg from './vm1.jpg';
import wurlitzerJpg from './wurlitzer.jpg';
import yamahaSg1500Jpg from './yamaha-sg1500.jpg';

export const aguilar = {
    alt: 'Aguilar',
    src: aguilarJpg,
};

export const articlesAndInterviewsBg = {
    alt: 'Articles & Interviews background',
    src: articlesAndInterviewsBgJpg,
};

export const articlesAndInterviewsButtonHover = {
    alt: 'Articles & Interviews button hover',
    src: articlesAndInterviewsButtonHoverGif,
};

export const articlesAndInterviewsButton = {
    alt: 'Articles & Interviews button',
    src: articlesAndInterviewsButtonGif,
};

export const articlesAndInterviewsTitle = {
    alt: 'Articles & Interviews title',
    src: articlesAndInterviewsTitleJpg,
};

export const audioRack = {
    alt: 'Audio rack',
    src: audioRackJpg,
};

export const bioBg = {
    alt: 'Bio background',
    src: bioBgJpg,
};

export const bioPageTitle = {
    alt: 'Bio title',
    src: bioPageTitleJpg,
};

export const bobScribbles = {
    alt: 'Bob scribbles',
    src: bobScribblesPng,
};

export const c12 = {
    alt: 'C12',
    src: c12Jpg,
};

export const c24 = {
    alt: 'C24',
    src: c24Jpg,
};

export const clickIt = {
    alt: 'Click it',
    src: clickItGif,
};

export const compressors = {
    alt: 'Compressors',
    src: compressorsJpg,
};

export const console = {
    alt: 'Console',
    src: consoleJpg,
};

export const contactArt = {
    alt: 'Contact art',
    src: contactArtPng,
};

export const contactBg = {
    alt: 'Contact background',
    src: contactBgJpg,
};

export const contactClickIt = {
    alt: 'Contact click it',
    src: contactClickItGif,
};

export const contactEmailLink = {
    alt: 'Contact email link',
    src: contactEmailLinkJpg,
};

export const contactPageTitle = {
    alt: 'Contact page title',
    src: contactPageTitleJpg,
};

export const fenderJazzBass = {
    alt: 'Fender jazz bass',
    src: fenderJazzBassJpg,
};

export const fenderStrat = {
    alt: 'Fender strat',
    src: fenderStratJpg,
};

export const fenderTeleCustom = {
    alt: 'Fender tele custom',
    src: fenderTeleCustomJpg,
};

export const fenderTeleDeluxe = {
    alt: 'Fender tele deluxe',
    src: fenderTeleDeluxeJpg,
};

export const genelecs = {
    alt: 'Genelecs',
    src: genelecsJpg,
};

export const gibsonEs295 = {
    alt: 'Gibson ES 295',
    src: gibsonEs295Jpg,
};

export const gibsonEs335 = {
    alt: 'Gibson ES 335',
    src: gibsonEs335Jpg,
};

export const gibsonHowardRoberts = {
    alt: 'Gibson Howard Roberts',
    src: gibsonHowardRobertsJpg,
};

export const gibsonJ200 = {
    alt: 'Gibson J200',
    src: gibsonJ200Jpg,
};

export const gl = {
    alt: 'GL',
    src: glJpg,
};

export const guildF50 = {
    alt: 'Guild F50',
    src: guildF50Jpg,
};

export const guitarsBackHover = {
    alt: 'Guitars back hover',
    src: guitarsBackHoverJpg,
};

export const guitarsBg = {
    alt: 'Guitars background',
    src: guitarsBgJpg,
};

export const interview = {
    alt: 'Interview',
    src: interviewGif,
};

export const introAnimation = {
    alt: 'Intro animation',
    src: introAnimationJpg,
};

export const l47 = {
    alt: 'L47',
    src: l47Jpg,
};

export const l251 = {
    alt: 'L251',
    src: l251Jpg,
};

export const mainArrow = {
    alt: 'Main arrow',
    src: mainArrowPng,
};

export const mainBackground = {
    alt: 'Main background',
    src: mainBackgroundJpg,
};

export const mainBobText = {
    alt: 'Main bob text',
    src: mainBobTextJpg,
};

export const mainBob = {
    alt: 'Main bob',
    src: mainBobPng,
};

export const mainGuitarArt = {
    alt: 'Main guitar art',
    src: mainGuitarArtPng,
};

export const mainGuitarScribbles = {
    alt: 'Main guitar scribbles',
    src: mainGuitarScribblesPng,
};

export const mainGuitar = {
    alt: 'Main guitar',
    src: mainGuitarPng,
};

export const mainMicrophoneLightning = {
    alt: 'Main microphone lightning',
    src: mainMicrophoneLightningJpg,
};

export const mainMicrophone = {
    alt: 'Main microphone',
    src: mainMicrophonePng,
};

export const mainMixingBoardOver = {
    alt: 'Main mixing board over',
    src: mainMixingBoardOverPng,
};

export const mainMixingBoardUnder = {
    alt: 'Main mixing board under',
    src: mainMixingBoardUnderPng,
};

export const martinD16h = {
    alt: 'Martin D16h',
    src: martinD16hJpg,
};

export const micClosetBackHover = {
    alt: 'Mic closet back hover',
    src: micClosetBackHoverJpg,
};

export const micClosetBg = {
    alt: 'Mic closet background',
    src: micClosetBgJpg,
};

export const mixRack = {
    alt: 'Mix rack',
    src: mixRackJpg,
};

export const mixRoomHover = {
    alt: 'Mix room hover',
    src: mixRoomHoverJpg,
};

export const mixRoom = {
    alt: 'Mix room',
    src: mixRoomJpg,
};

export const mixingAndMastering = {
    alt: 'Mixing and mastering',
    src: mixingAndMasteringGif,
};

export const needQuiet = {
    alt: 'Need quiet',
    src: needQuietJpg,
};

export const neve1073 = {
    alt: 'Neve 1073',
    src: neve1073Jpg,
};

export const neve1095 = {
    alt: 'Neve 1095',
    src: neve1095Jpg,
};

export const pendulum = {
    alt: 'Pendulum',
    src: pendulumJpg,
};

export const pensaSuhr = {
    alt: 'Pensa suhr',
    src: pensaSuhrJpg,
};

export const projectsArticlesInterviewsHover = {
    alt: 'Projects Articles & Interviews hover',
    src: projectsArticlesInterviewsHoverJpg,
};

export const projectsBg = {
    alt: 'Projects background',
    src: projectsBgJpg,
};

export const projectsClickIt = {
    alt: 'Projects click it',
    src: projectsClickItGif,
};

export const projectsText = {
    alt: 'Projects text',
    src: projectsTextPng,
};

export const rhodes = {
    alt: 'Rhodes',
    src: rhodesJpg,
};

export const sadowsky = {
    alt: 'Sadowsky',
    src: sadowskyJpg,
};

export const schoeps = {
    alt: 'Schoeps',
    src: schoepsJpg,
};

export const sessionStrat = {
    alt: 'Session strat',
    src: sessionStratJpg,
};

export const sm57 = {
    alt: 'SM57',
    src: sm57Jpg,
};

export const stompboxes = {
    alt: 'Stompboxes',
    src: stompboxesJpg,
};

export const studioBg = {
    alt: 'Studio background',
    src: studioBgJpg,
};

export const studioBobPower = {
    alt: 'Studio Bob Power',
    src: studioBobPowerJpg,
};

export const studioContact = {
    alt: 'Studio contact',
    src: studioContactJpg,
};

export const studioDiscography = {
    alt: 'Studio discography',
    src: studioDiscographyJpg,
};

export const studioHome = {
    alt: 'Studio home',
    src: studioHomeJpg,
};

export const studioProjects = {
    alt: 'Studio projects',
    src: studioProjectsJpg,
};

export const studioRecordBg = {
    alt: 'Studio record background',
    src: studioRecordBgJpg,
};

export const studioSubmissions = {
    alt: 'Studio submissions',
    src: studioSubmissionsJpg,
};

export const studioTimeline = {
    alt: 'Studio timeline',
    src: studioTimelineJpg,
};

export const submissionsBg = {
    alt: 'Submissions background',
    src: submissionsBgJpg,
};

export const submissionsPageTitle = {
    alt: 'Submissions page title',
    src: submissionsPageTitleJpg,
};

export const submissionsTitleClickIt = {
    alt: 'Submissions title click it',
    src: submissionsTitleClickItGif,
};

export const suitcase = {
    alt: 'Suitcase',
    src: suitcaseJpg,
};

export const synths = {
    alt: 'Synths',
    src: synthsJpg,
};

export const timelineOverlay = {
    alt: 'Timeline overlay',
    src: timelineOverlayJpg,
};

export const timeline = {
    alt: 'Timeline',
    src: timelineJpg,
};

export const u47 = {
    alt: 'U47',
    src: u47Jpg,
};

export const u67 = {
    alt: 'U67',
    src: u67Jpg,
};

export const vetta = {
    alt: 'Vetta',
    src: vettaJpg,
};

export const vm1 = {
    alt: 'VM1',
    src: vm1Jpg,
};

export const wurlitzer = {
    alt: 'Wurlitzer',
    src: wurlitzerJpg,
};

export const yamahaSg1500 = {
    alt: 'Yamaha SG-1500',
    src: yamahaSg1500Jpg,
};
