import {
    submissionsBg,
    submissionsPageTitle,
    submissionsTitleClickIt,
    clickIt,
} from 'shared/assets/img';

import { rhodes, wurlitzer } from './popups';

export {
    submissionsBg,
    submissionsPageTitle,
    submissionsTitleClickIt,
    clickIt,
};

export const forPreload = {
    img: [
        rhodes,
        wurlitzer,
        submissionsBg,
        submissionsPageTitle,
        submissionsTitleClickIt,
        clickIt,
    ].map(x => x.src),
    audio: [rhodes, wurlitzer].map(x => x.audio || ''),
};
