import * as React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';

import { NavContainer, NavDot, NavItem } from 'shared/components/nav';
import { Animatable, Hoverable, Nav, Hideable } from 'shared/types';
import { fadeIn } from '../../styles';

interface MainNavProps {
    activeNav: Nav | null;
    items: NavSection[];
}

interface NavSection extends Partial<Animatable>, Hideable, Hoverable {
    external?: boolean;
    nav: Nav;
    title: string;
    to: string;
    onClick?: () => void;
}

const MainNavContainer = styled(NavContainer)`
    left: 572px;
    position: absolute;
    top: 106px;
`;

const NavItemWithFade = styled(NavItem)`
    ${fadeIn}
`;

const MainNav: React.FC<MainNavProps> = props => (
    <MainNavContainer>
        {props.items.map(item => (
            <CSSTransition
                in={item.isVisible}
                key={item.title}
                mountOnEnter
                timeout={1000}
            >
                <NavItemWithFade
                    onMouseEnter={item.onMouseEnter}
                    onMouseLeave={item.onMouseLeave}
                    onClick={item.onClick}
                >
                    {props.activeNav === item.nav ? (
                        <NavDot
                            isAnimating={
                                item.isAnimating
                                    ? props.activeNav === item.nav
                                    : false
                            }
                        />
                    ) : null}
                    {!item.external ? (
                        <Link to={item.to}>{item.title}</Link>
                    ) : (
                        <a
                            href={item.to}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {item.title}
                        </a>
                    )}
                </NavItemWithFade>
            </CSSTransition>
        ))}
    </MainNavContainer>
);

export default MainNav;
