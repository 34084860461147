import {
    compressors,
    genelecs,
    mixRack,
    needQuiet,
    neve1073,
    neve1095,
    pendulum,
} from 'shared/components/popups';

export {
    compressors,
    genelecs,
    mixRack,
    needQuiet,
    neve1073,
    neve1095,
    pendulum,
};

export default {
    Compressors: compressors.component,
    Genelecs: genelecs.component,
    MixRack: mixRack.component,
    NeedQuiet: needQuiet.component,
    Neve1073: neve1073.component,
    Neve1095: neve1095.component,
    Pendulum: pendulum.component,
};
