import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import * as assets from './assets';
import PageContainer from 'shared/components/page-container';
import Area from 'shared/components/area';
import { Paths } from 'shared/constants';
import { usePopup } from 'shared/hooks';
import Popups from './popups';
import { LogoContainer, LogoRecord } from 'shared/components/logo';
import { Nav } from 'shared/components/nav';
import { SubmissionsText } from './components';
import { loadBeforeDisplay } from 'shared/hocs';

const SubmissionsTitle = styled.img.attrs(() => ({
    ...assets.submissionsPageTitle,
    useMap: '#submissions-title-map',
}))`
    left: 0;
    position: absolute;
    top: 23px;
`;

const TitleClickItGif = styled.img.attrs(() => assets.submissionsTitleClickIt)`
    cursor: pointer;
    left: 0;
    position: absolute;
    top: 56px;
`;

const ClickItImg = styled.img.attrs(() => assets.clickIt)`
    cursor: pointer;
    left: 536px;
    position: absolute;
    top: 371px;
`;

const LogoContainerOuter = styled.div`
    left: 498px;
    position: absolute;
    top: 24px;
`;

const ROOT_ID = 'submissions-root';

const SubmissionsNav = styled(Nav)`
    left: 566px;
    position: absolute;
    top: 105px;
`;

const StyledSubmissionsText = styled(SubmissionsText)`
    left: 22px;
    position: absolute;
    top: 177px;
`;

const Submissions = () => {
    const { PopupModal, setCurrentPopup } = usePopup(Popups, ROOT_ID);
    return (
        <PageContainer id={ROOT_ID}>
            <img
                alt={assets.submissionsBg.alt}
                src={assets.submissionsBg.src}
                useMap="#submissions-map"
            />
            <map name="submissions-map">
                <Area
                    alt="wurlitzer"
                    coords="586,508,52"
                    onClick={() => setCurrentPopup('Wurlitzer')}
                    shape="circle"
                />
                <Link to={Paths.HOME}>
                    <Area alt="logo-text" coords="591,43,715,55" shape="rect" />
                </Link>
            </map>
            <SubmissionsTitle />
            <map name="submissions-title-map">
                <Area
                    alt="rhodes"
                    coords="33,81,47,118,106,90,117,107,127,66,93,35,102,58"
                    onClick={() => setCurrentPopup('Rhodes')}
                />
            </map>
            <TitleClickItGif onClick={() => setCurrentPopup('Rhodes')} />
            <ClickItImg onClick={() => setCurrentPopup('Wurlitzer')} />
            <LogoContainerOuter>
                <LogoContainer>
                    <Link to={Paths.HOME}>
                        <LogoRecord />
                    </Link>
                </LogoContainer>
            </LogoContainerOuter>
            <SubmissionsNav />
            <StyledSubmissionsText />
            <PopupModal />
        </PageContainer>
    );
};

export default loadBeforeDisplay(Submissions, assets.forPreload);
