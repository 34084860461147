import { guitarsBackHover, guitarsBg } from 'shared/assets/img';
import {
    fenderJazzBass,
    fenderStrat,
    fenderTeleCustom,
    fenderTeleDeluxe,
    gibsonEs295,
    gibsonEs335,
    gibsonHowardRoberts,
    gibsonJ200,
    gl,
    guildF50,
    martinD16h,
    pensaSuhr,
    sadowsky,
    sessionStrat,
    yamahaSg1500,
} from './popups';

export { guitarsBackHover, guitarsBg };

export const forPreloadBeforeDisplay = {
    img: [guitarsBackHover, guitarsBg].map(x => x.src),
    audio: [],
};

export const forPreload = {
    img: [
        guitarsBackHover,
        guitarsBg,
        fenderJazzBass,
        fenderStrat,
        fenderTeleCustom,
        fenderTeleDeluxe,
        gibsonEs295,
        gibsonEs335,
        gibsonHowardRoberts,
        gibsonJ200,
        gl,
        guildF50,
        martinD16h,
        pensaSuhr,
        sadowsky,
        sessionStrat,
        yamahaSg1500,
    ].map(x => x.src),
    audio: [
        fenderJazzBass,
        fenderStrat,
        fenderTeleCustom,
        fenderTeleDeluxe,
        gibsonEs295,
        gibsonEs335,
        gibsonHowardRoberts,
        gibsonJ200,
        gl,
        guildF50,
        martinD16h,
        pensaSuhr,
        sadowsky,
        sessionStrat,
        yamahaSg1500,
    ].map(x => x.audio || ''),
};
