import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

interface ModalProps {
    children: ReactNode;
    isOpen: boolean;
    onClose: () => void;
    root?: HTMLElement | null;
}

const ModalContent = styled.div`
    z-index: 11;
`;

const ModalWrapper = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;

    &.appear {
        opacity: 0;

        ${ModalContent} {
            transform: translateY(-20px);
        }
    }

    &.appear-active {
        opacity: 1;
        transition: opacity 200ms;

        ${ModalContent} {
            transform: translateY(0);
            transition: transform 200ms;
        }
    }

    &.exit {
        opacity: 1;
    }

    &.exit-active {
        opacity: 0;
        transition: opacity 200ms;
    }
`;

const Overlay = styled.div`
    background-color: black;
    left: 0;
    position: fixed;
    top: 0;
    height: 100%;
    opacity: 0.5;
    width: 100%;
    z-index: 10;
`;

const ModalHeader = styled.div`
    background-color: black;
    display: flex;
    justify-content: flex-end;
    padding: 4px 8px;
`;

const Button = styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 2px 4px;
`;

const CloseIcon = styled.svg`
    height: 12px;
    stroke: white;
    stroke-width: 2px;
    width: 12px;
`;

const Modal = (props: ModalProps) => {
    const [isClosing, setIsClosing] = React.useState(false);

    function handleExit() {
        setIsClosing(false);
        props.onClose();
    }

    return props.root
        ? ReactDOM.createPortal(
              <CSSTransition
                  appear
                  in={props.isOpen && !isClosing}
                  mountOnEnter
                  unmountOnExit
                  timeout={200}
                  onExited={handleExit}
              >
                  <ModalWrapper>
                      <Overlay onClick={() => setIsClosing(true)} />
                      <ModalContent>
                          <ModalHeader>
                              <Button onClick={() => setIsClosing(true)}>
                                  <CloseIcon>
                                      <line x1="0" y1="0" x2="12" y2="12" />
                                      <line x1="12" y1="0" x2="0" y2="12" />
                                  </CloseIcon>
                              </Button>
                          </ModalHeader>
                          {props.children}
                      </ModalContent>
                  </ModalWrapper>
              </CSSTransition>,
              props.root
          )
        : null;
};

Modal.defaultProps = {
    root: document.getElementById('root'),
};

export default Modal;
