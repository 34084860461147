import styled, { css, keyframes } from 'styled-components';

import { LogoContainer } from 'shared/components/logo';
import { Animatable } from 'shared/types';

interface MainLogoContainerProps extends Animatable {}

const grow = keyframes`
    0% {}
    50% {
        transform: scale(1.3) translateY(8px);
    }
    100% {
    }
`;

export default styled(LogoContainer)<MainLogoContainerProps>`
    ${props =>
        props.isAnimating &&
        css`
            animation: ${grow} 750ms;
        `}
`;
