import * as React from 'react';
import styled, { css } from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { Animatable, Hoverable, Hideable } from 'shared/types';
import { fadeIn } from '../../styles';

import { mainGuitar } from 'pages/main/assets';

interface MainGuitarProps extends Animatable, Hideable, Hoverable {}

interface MainGuitarImgProps extends Pick<MainGuitarProps, 'isAnimating'> {}

const commonGuitarStyles = css`
    left: 40px;
    position: absolute;
    top: 173px;
    width: 196px;
`;

const MainGuitarImg = styled.img.attrs(() => mainGuitar)<MainGuitarImgProps>`
    ${commonGuitarStyles}
    ${fadeIn}
    transition: all 200ms linear;
    z-index: 2;
    ${props =>
        props.isAnimating &&
        css`
            left: 28px;
            top: 145px;
            transform: rotate(40deg);
            width: 230px;
        `}
`;

const MainGuitarHitBox = styled.div`
    ${commonGuitarStyles}
    cursor: pointer;
    height: 293.5px;
    z-index: 3;
`;

const MainGuitar: React.FC<MainGuitarProps> = props => (
    <React.Fragment>
        <CSSTransition in={props.isVisible} mountOnEnter timeout={1000}>
            <MainGuitarImg isAnimating={props.isAnimating} />
        </CSSTransition>
        <CSSTransition in={props.isVisible} mountOnEnter timeout={1000}>
            <MainGuitarHitBox {...props} />
        </CSSTransition>
    </React.Fragment>
);

export default MainGuitar;
