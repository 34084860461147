import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { Animatable, Hoverable, Hideable } from 'shared/types';

import { mainMixingBoardOver } from 'pages/main/assets';

interface MixingBoardOverProps extends Animatable, Hideable, Hoverable {}

const inOut = keyframes`
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

// eslint-disable-next-line
const MixingBoardOverImg = styled.img.attrs(() => mainMixingBoardOver)<
    MixingBoardOverProps
>`
    cursor: pointer;
    opacity: ${props => (props.isAnimating ? 1 : 0)};
    position: absolute;
    top: 486px;
    transition: opacity 500ms;
    width: 695px;
    z-index: 2;

    &.enter {
        opacity: 0;
    }

    &.enter-active {
        animation: ${inOut} 5s;
    }
`;

const MixingBoardOver: React.FC<MixingBoardOverProps> = props => (
    <CSSTransition in={props.isVisible} mountOnEnter timeout={5000}>
        <MixingBoardOverImg {...props} />
    </CSSTransition>
);

export default MixingBoardOver;
