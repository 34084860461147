import React, { useEffect, useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { Circle } from 'rc-progress';
import { fadeIn } from 'pages/main/components/main-content/styles';

const Container = styled.div`
    align-items: center;
    margin-top: 64px;

    ${fadeIn}
`;

const ProgressContainer = styled.div`
    height: 100px;
    position: relative;
    width: 100px;
`;

const Overlay = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;

const Percentage = styled.h4`
    color: white;
    font-family: ${({ theme }) => theme.fonts.main.family};
    margin: 0;
`;

const LoadingText = styled.h4`
    color: white;
    font-family: ${({ theme }) => theme.fonts.main.family};
    text-align: center;
`;

const fade3 = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const fade2 = keyframes`
    0% {
        opacity: 0.66;
    }
    33% {
        opacity: 0;
    }
    83% {
        opacity: 1;
    }
    100% {
        opacity: 0.66;
    }
`;

const fade1 = keyframes`
    0% {
        opacity: 0.33;
    }
    16.5% {
        opacity: 0;
    }
    65.5% {
        opacity: 1;
    }
    100% {
        opacity: 0.33;
    }
`;

const Ellipsis1 = styled.span`
    animation: ${fade1} 1.5s infinite;
    animation-timing-function: linear;
`;

const Ellipsis2 = styled.span`
    animation: ${fade2} 1.5s infinite;
    animation-timing-function: linear;
`;

const Ellipsis3 = styled.span`
    animation: ${fade3} 1.5s infinite;
    animation-timing-function: linear;
`;

interface Props {
    percent: number;
}

export default function LoadingIndicator({ percent }: Props) {
    const [isVisible, setIsVisible] = useState(false);
    const isMounted = useRef(true);

    useEffect(() => {
        setTimeout(() => {
            if (isMounted.current === true) {
                setIsVisible(true);
            }
        }, 2000);
        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <CSSTransition in={isVisible} mountOnEnter timeout={1000}>
            <Container>
                <ProgressContainer>
                    <Circle
                        percent={percent}
                        strokeColor="#97D4A8"
                        strokeLinecap="square"
                        strokeWidth={5}
                        trailColor="#000"
                    />
                    <Overlay>
                        <Percentage>{Math.round(percent)}%</Percentage>
                    </Overlay>
                </ProgressContainer>
                <LoadingText>
                    Loading <Ellipsis1>.</Ellipsis1>
                    <Ellipsis2>.</Ellipsis2>
                    <Ellipsis3>.</Ellipsis3>
                </LoadingText>
            </Container>
        </CSSTransition>
    );
}
