import * as React from 'react';
import styled, { css } from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { Animatable, Hoverable, Hideable } from 'shared/types';

interface TimelineTextProps extends Animatable, Hideable, Hoverable {}

interface TimelineTextSvgProps extends Pick<TimelineTextProps, 'isAnimating'> {}

const timelineTextCommonStyles = css`
    left: 166px;
    position: absolute;
    top: 72px;
    transform: scale(0.7143);
    width: 308px;
`;

const TimelineTextSvg = styled.svg<TimelineTextSvgProps>`
    ${timelineTextCommonStyles}
    transition: all 400ms linear;

    ${props =>
        props.isAnimating &&
        css`
            transform: scale(1) translate(116px, -24px);
        `}

    .cls-1 {
        opacity: 0.2;
    }

    .cls-2 {
        fill: white;
        opacity: 0.5;
    }

    .cls-3 {
        fill: white;
    }

    .timeline-text {
        fill: #555;
    }

    &.enter {
        opacity: 0;
        transform: scale(0.7143) translateX(-50px);
    }

    &.enter-active {
        opacity: 1;
        transform: scale(0.7143) translateX(0);
        transition: all 1s;
    }
`;

const TimelineTextHitBox = styled.div`
    ${timelineTextCommonStyles}
    cursor: pointer;
    height: 139px;
    z-index: 1;
`;

const TimelineText: React.FC<TimelineTextProps> = props => (
    <React.Fragment>
        <CSSTransition in={props.isVisible} mountOnEnter timeout={1000}>
            <TimelineTextHitBox {...props} />
        </CSSTransition>
        <CSSTransition in={props.isVisible} mountOnEnter timeout={1000}>
            <TimelineTextSvg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 216.1 97.19"
                isAnimating={props.isAnimating}
            >
                <g id="Layer_2" data-name="Layer 2">
                    <g
                        id="timeline_thin_arrow_dark"
                        data-name="timeline thin arrow dark"
                    >
                        <path
                            className="cls-1"
                            d="M96.05,32.37c-.43.54-1.68.43-2.23,1-1.21-.85-2.09,1.22-3.31.12-1.05.61-2,0-3,.7-.61.07-1.33.27-1.77-.42l-7.8.85c-.85,1.2-2.34,0-3.31.12l0-.25c1.72-.06,2.63-.78,4.39-.47C85.17,33.19,90.19,32.89,96.05,32.37Z"
                        />
                        <path
                            className="cls-1"
                            d="M72.9,34.78l0,.37c-3.17.35-6.66,1.1-10,.85l.19-.51C66.33,35.5,69.57,34.78,72.9,34.78Z"
                        />
                        <path
                            className="cls-1"
                            d="M108.23,31c0,1.23-1.71.19-2,1.08-3.08,0-6,.29-9,.37.24-1.14,1.68-.31,2.52-.53C102.21,31.2,105.45,31.58,108.23,31Z"
                        />
                        <path
                            className="cls-1"
                            d="M120.28,29.71l.07.61c-2.58,0-4.56,1.11-6.83.62-.84.22-1.49,1-2.28.62a.87.87,0,0,0-1.34.15l-.11.14c-.37-.09-.16-.36-.32-.71,1.64-.79,3.3-.24,4.82-1C116.29,30.39,118.08,29.83,120.28,29.71Z"
                        />
                        <path
                            className="cls-1"
                            d="M130.65,28.69l0,.49c-3.14.59-5.61.49-8.89,1l-.18-.48A57,57,0,0,1,130.65,28.69Z"
                        />
                        <path
                            className="cls-1"
                            d="M144.18,27.34c.09.85-1.07.36-1.4.76-3.19.11-6.32.82-9.64.82l-.06-.49A66.69,66.69,0,0,1,144.18,27.34Z"
                        />
                        <path
                            className="cls-1"
                            d="M156.82,25.7c6.76-.12,13-1.43,19.54-1.53C178,23.62,180,24,181.65,23c1.19.73,2.22,0,3.47.11l15.72-1.6.07.61c-3,.7-6,.16-8.73,1.32-1.93-.77-4.26.47-6.62.36-5.59.74-11,.72-16.44,1.8-.43.54-1.24-.11-1.85,0-3.61.77-7.53-.16-10.68,1.54-1.19-.73-2.84-.06-3.94-.06l-7.31.8-.16-.35C148.68,25.73,153,27.1,156.82,25.7Z"
                        />
                    </g>
                    <g id="timeline_thin_arrow" data-name="timeline thin arrow">
                        <path
                            className="cls-2"
                            d="M195.08,13.32a10.61,10.61,0,0,0,3.13-.2c.88.85,2.51-.59,3.32.58.86-.45,1.71.59,2.58-.17,1,.46,2.1-.46,3,.38.82.49,1.94-.25,2.78-.08.08.11.11.29.3.33l2.43-.33c0,.43.45.48.71.56s.36-.06.53-.15c.08.49.67.27.94.41.23-.1.58-.22.8,0,1,.33.06,1.11.09,1.66-.4.25-1.22.13-1.37.78-.53.89-1.57-.12-2.4.51-.4.19-.66.85-1.06.3a.51.51,0,0,0-.35.55c-1.2.25-2.35.81-3.5.63-1,.58-1.8.35-2.91.78-.85.13-2.16.1-2.56,1a.55.55,0,0,0-.32-.44c-1,.16-2.76-.18-3.51.93-.8.07-1.68.77-2.37-.05-.31.36-.72-.25-1,.17-.21,1-1.54.43-2.17.78-1.57.63-2.81.27-4.41.72-.32.67-1.11.05-1.58.56-.72-.19-1.53.5-2.07-.1-.58.22-1.69-.1-2,.76a18.39,18.39,0,0,0-3.66.4c-.5-.41-1.37,0-1.9.13-.3.48-1.07.29-1.49.42-.73.06-1.83.55-2.51.16a7.92,7.92,0,0,0-2.64.61l-2.89.53c-.75-.44-1.79,0-2.6.05a6.63,6.63,0,0,1-4.19.55c-.93.4-2,.13-2.66.8-1,.05-1.95.63-2.84.09-.76.68-1.82-.14-2.49.71-1,0-2.26.62-3.26.22-1,.09-2.4.08-3.22.77-1.63.63-3.14-.24-4.51.48-.35-.19-.53.21-.68.42L138,30.8c0-.06-.14-.1-.21-.16-1.77-.2-3,1.05-4.75,1-.37.37-.93,0-1.42,0a11.44,11.44,0,0,0-2.84.46,3.42,3.42,0,0,1-2.67.37,11.63,11.63,0,0,0-4.26.88,16.64,16.64,0,0,0-4.33.51l-.15.21c-1.77.54-3.74-.08-5.5.52-1.17.06-1.84.91-3,.73-.8-.73-1.32.65-2.1,0-.56.34-1.48,0-1.88.67a22.48,22.48,0,0,0-5.37.56c-.68.36-1.6.07-2.13.59l-.61-.27c-1.65.51-3.55.26-5.09,1.13A2.07,2.07,0,0,0,90.05,38l-4.42.66,0-.24c2.21-.55,4.72-.77,6.88-1.24,5.07-.45,10.5-1.76,15.84-2.19a5.51,5.51,0,0,1,2.41-.39c8.06-1.61,16.73-2.46,24.79-4,1.92-.06,3.82-.55,5.71-.79,6.9-1.06,14.65-2.25,21.86-3.29,6.32-.83,12.8-2.19,19.47-3.15,3.13-.25,6.91-1.05,10.24-1.53l.24-.47c0-1.49-.79-2.91-.52-4.32-.24-1.14-.95-2.83-.5-4.26C193.27,12.12,193.88,13.57,195.08,13.32Z"
                        />
                        <path
                            className="cls-2"
                            d="M32.32,46.63c-.2.28-.83.26-1.09.55-.62-.4-1,.66-1.65.14-.5.33-1,0-1.48.43-.3,0-.66.17-.9-.17l-3.87.63c-.39.62-1.17.06-1.65.14l0-.12c.86-.08,1.29-.46,2.18-.35C26.91,47.32,29.41,47,32.32,46.63Z"
                        />
                        <path
                            className="cls-2"
                            d="M20.82,48.43l0,.18a27.79,27.79,0,0,1-5,.68L16,49C17.56,49,19.16,48.51,20.82,48.43Z"
                        />
                        <path
                            className="cls-2"
                            d="M38.37,45.65c0,.62-.85.14-1,.59-1.54.07-3,.3-4.46.41.09-.57.83-.19,1.25-.32C35.37,45.89,37,46,38.37,45.65Z"
                        />
                        <path
                            className="cls-2"
                            d="M44.35,44.69l0,.3c-1.28.08-2.25.67-3.39.49-.42.13-.72.55-1.12.36a.44.44,0,0,0-.67.11l0,.07c-.19,0-.09-.17-.18-.34.8-.44,1.65-.21,2.38-.64A15.88,15.88,0,0,0,44.35,44.69Z"
                        />
                        <path
                            className="cls-2"
                            d="M49.51,43.92l0,.24c-1.55.37-2.79.39-4.41.71l-.1-.23A27,27,0,0,1,49.51,43.92Z"
                        />
                        <path
                            className="cls-2"
                            d="M56.23,42.89c.06.43-.53.21-.68.42-1.59.14-3.14.57-4.8.65l0-.24A34.21,34.21,0,0,1,56.23,42.89Z"
                        />
                        <path
                            className="cls-2"
                            d="M62.49,41.75c3.38-.23,6.47-1,9.73-1.26.81-.32,1.83-.17,2.61-.73.61.34,1.11,0,1.73,0l7.81-1.2,0,.3c-1.48.43-3,.24-4.32.89-1-.34-2.12.34-3.3.34-2.77.51-5.48.64-8.16,1.32-.2.28-.63,0-.93,0-1.78.47-3.76.11-5.29,1-.61-.34-1.42,0-2,.07l-3.63.59-.09-.18C58.44,42,60.64,42.55,62.49,41.75Z"
                        />
                    </g>
                    <g id="timeline_time_lines" data-name="timeline time lines">
                        <path
                            className="cls-3"
                            d="M56.33,67.5c-.49.31-1-.33-1.52-.19l-1.22-1.54c-.91-7.47-1.42-15.25-2.81-22.31-.37-.14-.66-.37-.53-.82.7-.08.87-.89,1.68-.72a.61.61,0,0,1-.16.9,4.36,4.36,0,0,1,1,4c1,1.38,1.72,3.68.85,5.36.12,1,1.19,1.2,1.55,2-1.09,2.14,0,5.18.21,7.54C56.92,63.21,56.3,65.65,56.33,67.5Z"
                        />
                        <path
                            className="cls-3"
                            d="M95.88,21.37c-2.35,1.41-.26,4.82-.35,7.22l-.43.68c.25,2.26.91,4.38.6,6.46-.36,0-.8-.06-1-.48,1.39-1.36-1.39-2.19-.28-3.68.19-1.7-.35-3.57-.52-5.31.53-1.23-.18-3-.29-4.38-3.44.06-7.56.93-11.47,1.42-.37-.12-.35-.56-.4-.91l1.61-.5"
                        />
                        <path
                            className="cls-3"
                            d="M162.45,1.52l.62,3.3c-1.19.55-.15,1.47-.51,2.24-.64-.84-.08-2.7-.64-3.95.33-.57-.67-1.63.28-1.95a1.38,1.38,0,0,1-.29-.76,1.73,1.73,0,0,1,.54-.4C163,.53,162.31,1,162.45,1.52Z"
                        />
                        <path
                            className="cls-3"
                            d="M158,5.53l-.33,0c.26-.56-.5-.79,0-1.26-.86-.48-.3-1.81-.6-2.63l.55-.34C157.24,2.72,157.82,4.1,158,5.53Z"
                        />
                        <path
                            className="cls-3"
                            d="M160.64,3.94c.64.31,0,.86.17,1.3l-.27,0c-.47-.6.37-1.3-.36-1.8.45-.66-.61-1.18.09-1.87C160.5,2.37,160.47,3.16,160.64,3.94Z"
                        />
                        <path
                            className="cls-3"
                            d="M165.86,3.86c0,.26-.27.5,0,.66l-.17.16a1,1,0,0,1-.39-.94c.6-.48-.51-.87-.05-1.39,0-.32-.57-.85,0-1.19C165.8,1.89,165.29,3.08,165.86,3.86Z"
                        />
                        <path
                            className="cls-3"
                            d="M162.76,8.63c0,1.39.68,2.69.51,4,.8,1.09-.46,2.11.4,3.12-.21.89.18,1.9-.26,2.61,0,.87.68,2.23-.1,2.8a16.06,16.06,0,0,1-.54-4.7c.75-.89-1-1.85.34-2.49-.23-1.29.08-2.46-.44-3.45.42-.85,0-2,0-3C163.06,7.86,162.52,8.26,162.76,8.63Z"
                        />
                        <path
                            className="cls-3"
                            d="M160.77,8.09h.07l0,.26h-.06c0-.34-.43-.21-.39-.41C160.5,8,160.75,7.89,160.77,8.09Z"
                        />
                        <polygon
                            className="cls-3"
                            points="157.82 9.86 157.89 9.85 157.94 10.24 157.88 10.25 157.82 9.86"
                        />
                        <polygon
                            className="cls-3"
                            points="157.89 10.38 157.96 10.37 158.01 10.76 157.94 10.77 157.89 10.38"
                        />
                        <rect
                            className="cls-3"
                            x="158.33"
                            y="13.36"
                            width="0.07"
                            height="0.85"
                            transform="translate(-0.48 20.02) rotate(-7.22)"
                        />
                        <path
                            className="cls-3"
                            d="M158.42,16.59l-.19,0c.18-.69-.44-1.33-.12-2C158.58,15.19,158.23,16.09,158.42,16.59Z"
                        />
                        <path
                            className="cls-3"
                            d="M161.44,17.93l-.33.5c-.42-1.2.14-2.53-.15-3.75l.3-.24A29,29,0,0,1,161.44,17.93Z"
                        />
                        <path
                            className="cls-3"
                            d="M171.76,13.76l-.26,0c-.15-.11-.13-.45,0-.66C171.81,13.16,171.67,13.57,171.76,13.76Z"
                        />
                        <path
                            className="cls-3"
                            d="M158.46,17.38c-.06.54.38.88-.18,1.22l-.15-1.18Z"
                        />
                        <path
                            className="cls-3"
                            d="M166.19,17.78c.22.17,0,.33,0,.4l-.11-.38Z"
                        />
                        <polygon
                            className="cls-3"
                            points="161.34 20.19 161.46 20.17 161.51 20.5 161.38 20.52 161.34 20.19"
                        />
                        <path
                            className="cls-3"
                            d="M164.36,23.71c-.17.21-.56.27-.51.59.3.29-.1.81.14,1.11h-.13c-.2-1,0-1.84-.62-2.76a1.42,1.42,0,0,1,.19-1.08C164.25,21.74,163.7,23.19,164.36,23.71Z"
                        />
                        <path
                            className="cls-3"
                            d="M164.44,26.87a.47.47,0,0,1-.68-.11,1.28,1.28,0,0,1,.16-.81C164.56,25.73,164.46,26.54,164.44,26.87Z"
                        />
                        <path
                            className="cls-3"
                            d="M164.28,28.74c-.27,1,.38,1.87-.11,2.73s.92,1.54.4,2.6a12,12,0,0,0,.61,3.69.81.81,0,0,0-.23.29c.67.58-.15,1.41.25,2-.31.7.63,1.83-.38,2.16.14.58.87.62.56,1.32-.14.41-.87.44-.53,1.06.83.22-.42.78.39.94.17.31-.65,1.14.18,1.37a.72.72,0,0,1-.23.82c.48,1.2.1,2.31.31,3.47a.37.37,0,0,1-.36.24c.21-.42-.51-.86,0-1.32l-.71-5.54c1-1.65-.59-4,.14-6.1-.4-1.54-.36-3.33-.89-4.85a6.71,6.71,0,0,0,0-2.64c.62-.35.21-1,.33-1.57-.4-.61.14-1.47-.21-2.16C164.08,27.71,164,28.32,164.28,28.74Z"
                        />
                        <path
                            className="cls-3"
                            d="M162.15,31.2c-.13,1,.85,2,.1,2.83.16,1.24.76,2.35.53,3.64A1.83,1.83,0,0,1,163,39.5l-.13,0a16.29,16.29,0,0,0-.81-4.27c.08-.93,0-1.45,0-2.45C162,32.35,161.44,31.36,162.15,31.2Z"
                        />
                        <path
                            className="cls-3"
                            d="M160.11,38.54c-.11.15-.21.36-.43.25-.45-.47.27-1-.25-1.48,0-.79-.47-1.6,0-2.19C159.82,36.26,159.7,37.4,160.11,38.54Z"
                        />
                        <polygon
                            className="cls-3"
                            points="162.67 40.4 162.73 40.39 162.82 41.04 162.75 41.05 162.67 40.4"
                        />
                        <rect
                            className="cls-3"
                            x="162.86"
                            y="41.5"
                            width="0.07"
                            height="0.79"
                            transform="translate(-3.89 20.15) rotate(-6.99)"
                        />
                        <rect
                            className="cls-3"
                            x="163.07"
                            y="43.32"
                            width="0.07"
                            height="0.39"
                            transform="translate(-4.18 20.89) rotate(-7.23)"
                        />
                        <rect
                            className="cls-3"
                            x="162.96"
                            y="44.33"
                            width="0.13"
                            height="1.9"
                            transform="translate(-4.4 20.87) rotate(-7.23)"
                        />
                        <rect
                            className="cls-3"
                            x="163.16"
                            y="46.61"
                            width="0.13"
                            height="0.33"
                            transform="translate(-4.49 20.28) rotate(-7.01)"
                        />
                        <polygon
                            className="cls-3"
                            points="163.22 47.27 163.35 47.26 163.42 47.78 163.29 47.79 163.22 47.27"
                        />
                        <polygon
                            className="cls-3"
                            points="163.4 48.64 163.52 48.62 163.67 49.73 163.54 49.75 163.4 48.64"
                        />
                        <polygon
                            className="cls-3"
                            points="163.59 50.2 163.73 50.19 163.77 50.51 163.64 50.53 163.59 50.2"
                        />
                        <polygon
                            className="cls-3"
                            points="163.39 50.69 163.46 50.68 163.49 50.94 163.43 50.95 163.39 50.69"
                        />
                        <path
                            className="cls-3"
                            d="M163.94,54.92l-.33,0c0-1.18-.73-2.61-.1-3.36C163.33,52.82,164.1,53.64,163.94,54.92Z"
                        />
                        <polygon
                            className="cls-3"
                            points="163.71 55.74 164.03 55.64 163.91 55.72 163.71 55.74"
                        />
                        <polygon
                            className="cls-3"
                            points="166.13 62.25 166.2 62.24 166.26 62.76 166.2 62.77 166.13 62.25"
                        />
                        <polygon
                            className="cls-3"
                            points="164.45 64.05 164.51 64.05 164.55 64.37 164.49 64.38 164.45 64.05"
                        />
                        <path
                            className="cls-3"
                            d="M164.21,64.81c.15,1.17.69,2.29.39,3.52-.13,0-.11.15-.24.23C164.33,67.31,163.75,65.86,164.21,64.81Z"
                        />
                        <path
                            className="cls-3"
                            d="M166.13,64.83a24.41,24.41,0,0,0,.48,3.18c0,.2-.24.23-.44.19-.69-.71.3-1.76-.4-2.6C165.94,65.38,165.59,64.7,166.13,64.83Z"
                        />
                        <path
                            className="cls-3"
                            d="M52.48,34.33l.62,3.29c-1.19.55-.14,1.48-.51,2.25-.64-.84-.08-2.7-.64-3.95.33-.57-.67-1.64.28-2a1.32,1.32,0,0,1-.29-.75,1.47,1.47,0,0,1,.54-.4C53,33.33,52.35,33.82,52.48,34.33Z"
                        />
                        <path
                            className="cls-3"
                            d="M48,38.34l-.33,0c.26-.56-.5-.79,0-1.26-.86-.49-.3-1.81-.6-2.64l.55-.33C47.27,35.53,47.85,36.91,48,38.34Z"
                        />
                        <path
                            className="cls-3"
                            d="M50.67,36.74c.64.32,0,.87.17,1.31l-.26,0c-.48-.6.36-1.3-.37-1.81.45-.65-.61-1.18.09-1.86C50.53,35.17,50.5,36,50.67,36.74Z"
                        />
                        <path
                            className="cls-3"
                            d="M55.89,36.67c0,.26-.27.5,0,.66l-.17.15a1,1,0,0,1-.39-.94c.6-.47-.51-.86,0-1.38,0-.33-.58-.85,0-1.19C55.83,34.69,55.32,35.88,55.89,36.67Z"
                        />
                        <path
                            className="cls-3"
                            d="M52.79,41.43c0,1.4.68,2.69.51,4,.8,1.09-.46,2.11.4,3.13-.21.89.18,1.89-.26,2.61,0,.87.69,2.23-.1,2.79a16,16,0,0,1-.54-4.69c.75-.89-1-1.85.34-2.49-.23-1.3.08-2.46-.44-3.45.42-.85-.05-2-.05-3C53.09,40.67,52.55,41.07,52.79,41.43Z"
                        />
                        <path
                            className="cls-3"
                            d="M50.8,40.9h.07l0,.26h-.06c0-.34-.43-.22-.39-.42C50.53,40.8,50.78,40.7,50.8,40.9Z"
                        />
                        <rect
                            className="cls-3"
                            x="47.88"
                            y="42.66"
                            width="0.07"
                            height="0.39"
                            transform="translate(-4.43 5.54) rotate(-6.32)"
                        />
                        <polygon
                            className="cls-3"
                            points="47.92 43.18 47.99 43.17 48.04 43.56 47.97 43.57 47.92 43.18"
                        />
                        <polygon
                            className="cls-3"
                            points="48.31 46.18 48.37 46.17 48.48 47.02 48.41 47.02 48.31 46.18"
                        />
                        <path
                            className="cls-3"
                            d="M48.46,49.4l-.2,0c.18-.68-.44-1.33-.12-2C48.61,48,48.26,48.9,48.46,49.4Z"
                        />
                        <path
                            className="cls-3"
                            d="M51.47,50.73l-.33.51c-.42-1.2.14-2.53-.15-3.75l.3-.24A28.79,28.79,0,0,1,51.47,50.73Z"
                        />
                        <path
                            className="cls-3"
                            d="M61.79,46.57l-.26,0c-.15-.11-.13-.45,0-.66C61.84,46,61.7,46.38,61.79,46.57Z"
                        />
                        <path
                            className="cls-3"
                            d="M48.49,50.19c-.06.54.38.88-.18,1.21l-.15-1.17Z"
                        />
                        <path
                            className="cls-3"
                            d="M56.22,50.59c.22.17,0,.32,0,.4l-.11-.39Z"
                        />
                        <rect
                            className="cls-3"
                            x="51.38"
                            y="52.99"
                            width="0.13"
                            height="0.33"
                            transform="translate(-6.32 6.95) rotate(-7.28)"
                        />
                        <path
                            className="cls-3"
                            d="M54.4,56.51c-.17.22-.57.27-.52.6.3.29-.1.8.14,1.1l-.13,0c-.2-1,0-1.85-.62-2.76a1.45,1.45,0,0,1,.19-1.09C54.28,54.54,53.73,56,54.4,56.51Z"
                        />
                        <path
                            className="cls-3"
                            d="M54.47,59.68a.48.48,0,0,1-.68-.11,1.26,1.26,0,0,1,.17-.82C54.59,58.54,54.49,59.34,54.47,59.68Z"
                        />
                        <path
                            className="cls-3"
                            d="M54.31,61.55c-.27,1,.38,1.87-.11,2.73s.93,1.53.4,2.59a12,12,0,0,0,.61,3.7c-.13.08-.17.22-.23.29.67.58-.15,1.41.25,2-.31.7.63,1.84-.38,2.17.14.58.87.61.56,1.32-.14.41-.87.44-.52,1.06.82.22-.43.78.38.94.17.31-.65,1.14.18,1.37a.71.71,0,0,1-.23.82c.49,1.19.1,2.3.31,3.47a.38.38,0,0,1-.36.24c.21-.42-.51-.86,0-1.33l-.71-5.53c1-1.65-.59-4,.14-6.1-.4-1.54-.36-3.33-.89-4.85a6.94,6.94,0,0,0,0-2.65c.61-.34.2-1,.32-1.56-.4-.61.15-1.47-.21-2.16C54.12,60.52,54,61.13,54.31,61.55Z"
                        />
                        <path
                            className="cls-3"
                            d="M52.18,64c-.13,1,.85,2,.1,2.83.16,1.24.76,2.35.53,3.64A1.83,1.83,0,0,1,53,72.31h-.13A16.22,16.22,0,0,0,52,68.06c.08-.94,0-1.46,0-2.45C52,65.16,51.47,64.16,52.18,64Z"
                        />
                        <path
                            className="cls-3"
                            d="M50.15,71.35c-.12.15-.22.36-.44.25-.45-.47.27-1-.25-1.49,0-.79-.47-1.59,0-2.19C49.85,69.07,49.73,70.21,50.15,71.35Z"
                        />
                        <polygon
                            className="cls-3"
                            points="52.7 73.2 52.77 73.2 52.85 73.85 52.78 73.85 52.7 73.2"
                        />
                        <polygon
                            className="cls-3"
                            points="52.84 74.31 52.91 74.3 53.01 75.08 52.94 75.09 52.84 74.31"
                        />
                        <polygon
                            className="cls-3"
                            points="53.08 76.13 53.14 76.13 53.19 76.52 53.13 76.52 53.08 76.13"
                        />
                        <polygon
                            className="cls-3"
                            points="52.88 77.15 53.01 77.13 53.25 79.02 53.12 79.04 52.88 77.15"
                        />
                        <rect
                            className="cls-3"
                            x="53.19"
                            y="79.42"
                            width="0.13"
                            height="0.33"
                            transform="translate(-10.14 7.81) rotate(-7.66)"
                        />
                        <polygon
                            className="cls-3"
                            points="53.25 80.08 53.38 80.06 53.45 80.58 53.32 80.6 53.25 80.08"
                        />
                        <polygon
                            className="cls-3"
                            points="53.42 81.45 53.56 81.43 53.7 82.54 53.57 82.55 53.42 81.45"
                        />
                        <polygon
                            className="cls-3"
                            points="53.63 83.01 53.76 82.99 53.8 83.32 53.67 83.33 53.63 83.01"
                        />
                        <polygon
                            className="cls-3"
                            points="53.42 83.5 53.49 83.49 53.52 83.75 53.46 83.76 53.42 83.5"
                        />
                        <path
                            className="cls-3"
                            d="M54,87.73l-.33,0c0-1.19-.73-2.62-.1-3.36C53.37,85.62,54.13,86.45,54,87.73Z"
                        />
                        <polygon
                            className="cls-3"
                            points="53.74 88.55 54.06 88.44 53.94 88.53 53.74 88.55"
                        />
                        <polygon
                            className="cls-3"
                            points="54.48 96.86 54.54 96.85 54.58 97.18 54.52 97.19 54.48 96.86"
                        />
                    </g>
                    <g
                        className="timeline-text"
                        id="text_timeline_dark"
                        data-name="text timeline dark"
                    >
                        <path d="M6.38,36C6,34.72,5.66,34.24,5,34.3a.76.76,0,0,0-.4.18,1.32,1.32,0,0,0-.48,1l-.35,2.12a.85.85,0,0,1-.71.69c-.48.05-.82-.13-1.09-.83L.16,32.88C-.07,32.3-.1,32,.41,32c.35,0,.56.34.69.77l.51,1.75c.31,1.05.78,1.33,1.08,1.31.59,0,.94-.47,1.05-1.29L4,32.2a.78.78,0,0,1,.74-.72c.66-.06.88.13,1.15,1.12l.47,1.66c.22.79.67,1.17,1.12,1.13s.8-.43,1-1.34l.38-2.39a.66.66,0,0,1,.52-.53.36.36,0,0,1,.42.29,3.28,3.28,0,0,1-.15,1L8.5,37.06a1,1,0,0,1-.82.79C7,37.92,6.87,37.51,6.38,36Z" />
                        <path d="M17.57,33.53l-1.87.13c-1.84.17-2.46.55-2.37,1.56a2,2,0,0,0,2.14,1.57,1.87,1.87,0,0,0,1-.3l.72-.57c.14-.1.16-.23.46-.25.8-.07.07,1.51-2.24,1.72a3.15,3.15,0,0,1-3.56-3.12c-.19-2.19.81-3.71,2.94-3.9a2.91,2.91,0,0,1,3.36,2.57C18.2,33.29,18,33.48,17.57,33.53Zm-2.66,0,.51,0a1.35,1.35,0,0,0,1.29-1.25A1.53,1.53,0,0,0,14.92,31c-1.13.1-1.75.76-1.69,1.53S14,33.63,14.91,33.55Z" />
                        <path d="M28,34.52c-.11-1.22.93-2,2.28-2.3s2.09-.93,2-1.55a1.48,1.48,0,0,0-1.56-1.42l-.24,0c-.86.08-1.16,1-1.82,1.06-.17,0-.35.06-.37-.17-.05-.6,1.1-1.09,2.17-1.18,3.14-.28,3,.9,3.26,3.77l.19,2.16c0,.39-.13.61-.48.64s-.85-.13-1.32-.09c-.89.08-1.09.52-2,.6A1.79,1.79,0,0,1,28,34.52Zm2.71.92a2,2,0,0,0,1.91-2.08,1.27,1.27,0,0,0-1.45-1.13c-1,.09-2.09,1-2,2A1.4,1.4,0,0,0,30.67,35.44Z" />
                        <path d="M39.36,28.37c.24,0,.51,0,.55.43s-.17.46-.62.77a2.67,2.67,0,0,0-.92.8,2.16,2.16,0,0,0-.29,1.34l.22,2.54c.06.69-.12,1-.5,1.06-.77.07-.91-.09-1-1.34l-.39-4.47c-.06-.68.14-1.06.64-1.11s1.3.13,1.45.11C39,28.46,39,28.4,39.36,28.37Z" />
                        <path d="M47.82,30.84,46,31c-1.84.16-2.46.54-2.38,1.55a2,2,0,0,0,2.15,1.57,1.8,1.8,0,0,0,1-.3l.73-.57c.14-.1.16-.22.46-.25.8-.07.07,1.52-2.24,1.72a3.14,3.14,0,0,1-3.56-3.11c-.2-2.2.8-3.72,2.94-3.91a2.93,2.93,0,0,1,3.36,2.57C48.46,30.61,48.3,30.8,47.82,30.84Zm-2.65,0,.5-.05A1.35,1.35,0,0,0,47,29.57a1.52,1.52,0,0,0-1.79-1.3c-1.13.1-1.76.75-1.69,1.52S44.28,31,45.17,30.87Z" />
                        <path d="M58.22,31.83c-.11-1.21.93-2,2.27-2.29s2.1-.94,2-1.56A1.46,1.46,0,0,0,61,26.57l-.24,0c-.86.08-1.17,1-1.82,1.06-.18,0-.35.06-.37-.18-.06-.59,1.1-1.08,2.16-1.18,3.14-.28,3,.9,3.26,3.77l.2,2.17c0,.38-.13.61-.48.64s-.85-.14-1.33-.09c-.89.08-1.09.51-2,.6A1.79,1.79,0,0,1,58.22,31.83Zm2.71.92a2,2,0,0,0,1.9-2.08,1.25,1.25,0,0,0-1.44-1.12c-1,.08-2.09,1-2,2A1.4,1.4,0,0,0,60.93,32.75Z" />
                        <path d="M68.49,31.86a.63.63,0,0,1-.64.8c-.71.07-.82-.16-1-1.7l-.59-6.67c-.07-.8,0-1.23.63-1.28s.8.26.85.85Z" />
                        <path d="M72.66,31.49c0,.5-.17.76-.65.8-.71.07-.82-.16-1-1.7l-.59-6.67c-.07-.8,0-1.23.64-1.28s.79.26.85.85Z" />
                        <path d="M82.31,29.69c-.11-1.21.93-2,2.27-2.29s2.1-.93,2-1.56a1.48,1.48,0,0,0-1.56-1.41l-.24,0c-.86.08-1.16,1-1.82,1.06-.17,0-.35.06-.37-.18,0-.59,1.1-1.08,2.16-1.18,3.15-.27,3,.9,3.27,3.78l.19,2.16c0,.39-.13.61-.48.64s-.85-.13-1.32-.09c-.89.08-1.09.51-2,.6A1.79,1.79,0,0,1,82.31,29.69Zm2.71.93a2,2,0,0,0,1.91-2.08,1.26,1.26,0,0,0-1.45-1.13c-1,.09-2.09,1-2,2A1.39,1.39,0,0,0,85,30.62Z" />
                        <path d="M96.4,25.23l.35,4c.06.59-.2.68-.41.7s-.49-.08-.52-.5l-.28-2.72c-.19-2.22-1.15-2.61-2-2.54-1.16.11-1.78,1.18-1.65,2.63l.26,3a.49.49,0,0,1-.45.58c-.33,0-.46-.11-.5-.58l-.47-5.22a.52.52,0,0,1,.52-.61c.47,0,1,0,1.61,0a9.41,9.41,0,0,1,1.72-.48C96,23.3,96.31,24.25,96.4,25.23Z" />
                        <path d="M106.55,27.54c-.11-1.21.93-2,2.28-2.29s2.09-.93,2-1.56a1.47,1.47,0,0,0-1.56-1.41l-.24,0c-.86.08-1.16,1-1.82,1.06-.17,0-.35.06-.37-.18,0-.59,1.1-1.08,2.16-1.18,3.15-.28,3,.9,3.27,3.78l.19,2.16c0,.38-.13.61-.48.64s-.85-.13-1.32-.09c-.89.08-1.09.51-2,.6A1.79,1.79,0,0,1,106.55,27.54Zm2.71.93a2,2,0,0,0,1.91-2.09,1.26,1.26,0,0,0-1.45-1.12c-1,.09-2.09,1-2,2A1.4,1.4,0,0,0,109.26,28.47Z" />
                        <path d="M125.68,26.71c0,.15-.1.58-.39.6s-.52-.1-.56-.52l-.22-2.52c-.16-1.83-.88-2.81-2.18-2.7-1.78.16-1.6,2.53-1.56,3l.23,2.54c0,.15-.1.61-.39.64s-.52-.14-.56-.55l-.24-2.7c-.16-1.81-.86-2.67-2.14-2.56s-1.75,1.15-1.59,3l.24,2.61c0,.15-.1.61-.4.6s-.42,0-.51-.37c0-.15-.14-.91-.26-2.25L115,23.33c-.12-1.3,0-1.52.51-1.57.18,0,.13,0,.78,0-.17,0,.42,0,.48,0a4.64,4.64,0,0,0,1.08-.28,4.91,4.91,0,0,1,.82-.22l.3,0a1,1,0,0,1,.54.07l.71.23a1.94,1.94,0,0,0,.81.11,1.89,1.89,0,0,0,.71-.15l.65-.39a2.58,2.58,0,0,1,1-.3c1.24-.11,1.86.41,2,1.71Z" />
                        <path d="M128,25.64c-.11-1.22.93-2,2.28-2.29s2.09-.94,2-1.56a1.47,1.47,0,0,0-1.56-1.41l-.24,0c-.86.07-1.16,1-1.82,1.05-.17,0-.35.07-.37-.17,0-.59,1.1-1.08,2.17-1.18,3.14-.28,3,.9,3.26,3.77l.19,2.17c0,.38-.13.61-.48.64s-.85-.14-1.32-.1c-.89.08-1.09.52-2,.6A1.78,1.78,0,0,1,128,25.64Zm2.71.92a2,2,0,0,0,1.91-2.08,1.26,1.26,0,0,0-1.45-1.12c-1,.08-2.09,1-2,2A1.39,1.39,0,0,0,130.7,26.56Z" />
                        <path d="M138.27,25.67c0,.5-.17.76-.65.8-.71.06-.82-.16-1-1.71l-.59-6.66c-.07-.8,0-1.23.64-1.28s.79.25.85.85Z" />
                        <path d="M144.71,19.33a4.07,4.07,0,0,1,1-.2c.59-.06.76.2.8.67L147,26c.12,1.4-.62,2.21-3,2.42-2,.18-2.64-.51-2.66-.75s.11-.43.35-.45c.59,0,1.34,1.05,2.38,1A1.87,1.87,0,0,0,146,26.33a1.17,1.17,0,0,0-1.42-1.19,5.51,5.51,0,0,0-.65.11c.3,0-.55.17-.79.17-1.25,0-2.42-.66-2.61-2.79-.17-1.9.81-3.36,2.11-3.47a5.27,5.27,0,0,1,1.15.1A5.58,5.58,0,0,0,144.71,19.33Zm-.85,5.54a2.18,2.18,0,0,0,1.86-2.55c-.14-1.57-1-2.81-2.3-2.7a2.2,2.2,0,0,0-1.86,2.65C141.72,24,142.61,25,143.86,24.87Z" />
                        <path d="M149.31,23.75c-.11-1.22.93-2,2.28-2.3s2.09-.93,2-1.55a1.49,1.49,0,0,0-1.56-1.42l-.24,0c-.86.08-1.16,1-1.81,1.06-.18,0-.36.06-.38-.17,0-.6,1.1-1.09,2.17-1.18,3.14-.28,3,.9,3.26,3.77l.19,2.16c0,.39-.12.61-.48.64s-.85-.13-1.32-.09c-.89.08-1.09.52-2,.6A1.79,1.79,0,0,1,149.31,23.75Zm2.71.92a2,2,0,0,0,1.91-2.08,1.27,1.27,0,0,0-1.45-1.13c-1,.09-2.09,1-2,2A1.4,1.4,0,0,0,152,24.67Z" />
                        <path d="M168.44,22.91c0,.15-.1.58-.39.61s-.52-.11-.56-.52l-.22-2.52c-.16-1.84-.88-2.82-2.18-2.7-1.78.15-1.6,2.53-1.56,3l.23,2.55c0,.15-.09.61-.39.63s-.52-.13-.56-.55l-.24-2.69c-.16-1.81-.86-2.67-2.14-2.56s-1.75,1.14-1.58,3l.23,2.61c0,.14-.1.6-.4.6s-.42,0-.51-.37c0-.15-.14-.92-.26-2.25l-.19-2.19c-.12-1.31,0-1.53.52-1.57.17,0,.12,0,.77,0a2.71,2.71,0,0,0,.48,0,4.69,4.69,0,0,0,1.09-.27,4.63,4.63,0,0,1,.81-.22l.3,0a1.12,1.12,0,0,1,.54.07l.71.24a2,2,0,0,0,.82.1,1.82,1.82,0,0,0,.7-.15l.65-.38a2.42,2.42,0,0,1,1-.3c1.25-.11,1.86.4,2,1.71Z" />
                        <path d="M18,47.13A3.11,3.11,0,0,1,17.17,41c2.2-.2,3.52.76,3.7,2.8A2.92,2.92,0,0,1,18,47.13Zm0-.58a2.24,2.24,0,0,0,1.87-2.67c-.16-1.83-1.25-2.47-2.35-2.37a2.2,2.2,0,0,0-1.9,2.54C15.72,45.72,16.65,46.67,18,46.55Z" />
                        <path d="M25.21,38.82a1.29,1.29,0,0,0-.23.68.88.88,0,0,0,.16.41c.09.12.53.34.56.58s-.14.22-.45.57a1.51,1.51,0,0,0-.4,1.16l.24,2.7c.1,1.2,0,1.42-.51,1.47s-.64-.16-.75-1.36l-.26-2.64a1.6,1.6,0,0,0-.46-1.07c-.18-.19-.51-.32-.52-.53s.16-.28.41-.67a5.39,5.39,0,0,0,.46-1.38c.1-.35.37-.87,1.39-1,.57-.05.77.11.8.39S25.69,38.35,25.21,38.82Z" />
                        <path d="M39.52,42l-1.65.12c-1.62.14-2.17.48-2.09,1.37a1.78,1.78,0,0,0,1.89,1.39,1.64,1.64,0,0,0,.89-.27l.64-.5c.13-.09.15-.2.41-.22.7-.07.06,1.33-2,1.52a2.79,2.79,0,0,1-3.14-2.76c-.17-1.93.71-3.27,2.59-3.44a2.58,2.58,0,0,1,3,2.27C40.08,41.77,39.94,41.94,39.52,42Zm-2.35,0,.45,0c.63-.06,1.19-.58,1.14-1.1a1.33,1.33,0,0,0-1.58-1.15c-1,.08-1.55.66-1.49,1.34S36.39,42.07,37.17,42Z" />
                        <path d="M44.62,42.16c.37,0,.65-.35.76-.91l.37-2.12a.82.82,0,0,1,.75-.75c.08,0,.45,0,.5.32a2.81,2.81,0,0,1-.12.73l-1.1,4.47a.9.9,0,0,1-.83.78,1.06,1.06,0,0,1-1.25-.76l-1.85-4.18c-.3-.66-.19-.91.23-.94.6-.06.8,0,1.16,1.21C43.72,41.52,44,42.21,44.62,42.16Z" />
                        <path d="M54.06,40.69l-1.65.12c-1.62.14-2.17.48-2.1,1.37a1.78,1.78,0,0,0,1.89,1.39,1.65,1.65,0,0,0,.9-.27l.64-.5c.13-.09.14-.2.41-.22.7-.07.06,1.33-2,1.52A2.79,2.79,0,0,1,49,41.34c-.17-1.93.71-3.27,2.59-3.44a2.58,2.58,0,0,1,3,2.26C54.62,40.48,54.47,40.65,54.06,40.69Zm-2.35,0,.45,0a1.2,1.2,0,0,0,1.14-1.1,1.33,1.33,0,0,0-1.58-1.15c-1,.08-1.55.66-1.49,1.34S50.93,40.78,51.71,40.71Z" />
                        <path d="M59.38,37.37c.2,0,.44,0,.48.38s-.15.41-.55.68a2.3,2.3,0,0,0-.81.7,2,2,0,0,0-.26,1.19l.2,2.24c.05.61-.11.91-.44.94-.68.06-.8-.09-.9-1.19l-.35-3.94c0-.61.13-.94.57-1s1.14.11,1.28.1C59,37.45,59,37.4,59.38,37.37Z" />
                        <path d="M61.45,37.69c-.21-.51.12-.67.38-.7.63-.05.73.44.8.64l.58,1.8c.25.79.48,1.32,1,1.28.13,0,.62-.11.78-1,.12-.72.13-1.17.32-2.35a.75.75,0,0,1,.7-.72c.31,0,.61.13.52.56L65,43.64a1.87,1.87,0,0,1-1.75,1.68c-.47,0-.82,0-.84-.29s.56-.55.75-.7a1.11,1.11,0,0,0,.47-1,4.11,4.11,0,0,0-.38-1.28Z" />
                        <path d="M80.14,38.37l-1.65.12c-1.62.15-2.17.48-2.09,1.37a1.77,1.77,0,0,0,1.89,1.39,1.74,1.74,0,0,0,.89-.26l.65-.51c.12-.09.14-.2.4-.22.7-.06.06,1.34-2,1.52A2.78,2.78,0,0,1,75.11,39c-.17-1.94.71-3.28,2.59-3.45a2.58,2.58,0,0,1,3,2.27C80.7,38.16,80.56,38.33,80.14,38.37Zm-2.35,0,.45,0c.63-.06,1.19-.58,1.14-1.11A1.35,1.35,0,0,0,77.8,36.1c-1,.09-1.55.67-1.49,1.35S77,38.46,77.79,38.4Z" />
                        <path d="M83.78,38.85a2,2,0,0,0,.13-.91,1.73,1.73,0,0,0-.28-.81l-1-1.29c-.15-.22-.26-.53,0-.55s.55,0,1.1.56a1,1,0,0,0,.88.42c.26,0,.57-.13.94-.74.25-.45.5-.57.74-.6s.34,0,.35.18a8.64,8.64,0,0,1-.77,1.65,2.46,2.46,0,0,0-.23,1,2.12,2.12,0,0,0,.38,1l1.13,1.53c.24.32.18.51-.08.53-.58.06-.7-.14-1.06-.61s-.56-.74-1.06-.7-.73.35-1.08.94-.41.69-.77.73-.51-.06-.33-.42Z" />
                        <path d="M92.66,40.49c-.21,0-.93-.05-1.14,0a1.13,1.13,0,0,0-.9.55,2,2,0,0,0-.15,1.28.47.47,0,0,1-.4.54c-.36,0-.53-.09-.59-.77L88.9,35.5c-.06-.65.3-.74.54-.76s.74.07,1,0c.47,0,.94-.39,1.62-.46A2.6,2.6,0,0,1,94.75,37C94.93,39.07,94.25,40.35,92.66,40.49ZM92.09,40c1.26-.11,1.93-1.17,1.78-2.85-.12-1.38-1-2.42-2.1-2.31-1.83.16-1.75,2.34-1.71,2.78C90.19,39.07,91.1,40.13,92.09,40Z" />
                        <path d="M101.78,36.45l-1.65.12c-1.63.15-2.17.48-2.1,1.37a1.9,1.9,0,0,0,2.79,1.13l.64-.51c.13-.09.14-.2.4-.22.71-.06.07,1.34-2,1.52a2.78,2.78,0,0,1-3.14-2.75c-.17-1.94.71-3.28,2.59-3.45a2.58,2.58,0,0,1,3,2.27C102.34,36.24,102.19,36.41,101.78,36.45Zm-2.35,0,.45,0A1.2,1.2,0,0,0,101,35.33a1.35,1.35,0,0,0-1.58-1.15c-1,.09-1.55.67-1.49,1.35S98.65,36.54,99.43,36.48Z" />
                        <path d="M107.09,33.13c.21,0,.45,0,.49.38s-.15.41-.55.68a2.29,2.29,0,0,0-.81.71,2,2,0,0,0-.26,1.18l.2,2.25c0,.6-.11.9-.45.93-.67.06-.79-.08-.89-1.18l-.35-3.95c0-.6.13-.93.57-1a10.89,10.89,0,0,1,1.27.1C106.73,33.22,106.73,33.17,107.09,33.13Z" />
                        <path d="M110.56,30.72c0-.26-.3-.32-.56-.3s-.4.17-.38.38.16.33.55.29C110.33,31.08,110.58,30.93,110.56,30.72Zm.43,3.41c-.1-1.12-.12-1.41-.75-1.35-.45,0-.55.36-.49,1l.33,3.71c.1,1.2.2,1.35.77,1.3.37,0,.52-.36.47-1Z" />
                        <path d="M118.5,35l-1.65.12c-1.62.14-2.17.48-2.09,1.37a1.76,1.76,0,0,0,1.88,1.39,1.65,1.65,0,0,0,.9-.27l.64-.5c.13-.09.14-.2.41-.22.7-.07.06,1.34-2,1.52a2.78,2.78,0,0,1-3.14-2.76c-.17-1.93.71-3.27,2.59-3.44a2.58,2.58,0,0,1,3,2.27C119.06,34.76,118.92,34.93,118.5,35Zm-2.35,0,.45,0c.63-.05,1.19-.58,1.14-1.1a1.34,1.34,0,0,0-1.58-1.15c-1,.09-1.55.66-1.49,1.34S115.37,35.06,116.15,35Z" />
                        <path d="M126.19,33.14l.31,3.53c0,.52-.18.59-.37.61s-.42-.07-.46-.43l-.24-2.41c-.17-2-1-2.3-1.8-2.23-1,.09-1.57,1-1.46,2.31l.24,2.62a.44.44,0,0,1-.41.51c-.29,0-.4-.1-.44-.52l-.41-4.6a.46.46,0,0,1,.46-.54c.41,0,.89,0,1.42,0a9.6,9.6,0,0,1,1.51-.43C125.83,31.43,126.11,32.28,126.19,33.14Z" />
                        <path d="M133.24,32c-.29,0-.86-.45-1.57-.39-1,.09-1.77,1.05-1.65,2.46.11,1.26.86,2.3,2.07,2.19,1-.08,1.15-.65,1.67-.7.27,0,.41.07.43.31s-.87,1.23-2.23,1.35a2.93,2.93,0,0,1-3.37-3,3.05,3.05,0,0,1,2.79-3.56c1.23-.11,2.35.31,2.4.84C133.8,31.79,133.6,31.91,133.24,32Z" />
                        <path d="M141,33l-1.65.12c-1.62.14-2.17.48-2.09,1.37a1.77,1.77,0,0,0,1.89,1.39,1.68,1.68,0,0,0,.9-.27l.64-.5c.12-.09.14-.2.4-.22.7-.06.07,1.34-2,1.52A2.78,2.78,0,0,1,136,33.63c-.17-1.94.71-3.28,2.6-3.45a2.58,2.58,0,0,1,3,2.27C141.57,32.76,141.43,32.93,141,33Zm-2.34,0,.44,0c.63-.05,1.19-.58,1.14-1.1a1.34,1.34,0,0,0-1.58-1.15c-1,.09-1.54.66-1.48,1.34S137.88,33.06,138.67,33Z" />
                        <path d="M74.81,49.42c-.37-1.13-.64-1.55-1.22-1.5a.65.65,0,0,0-.35.16,1.19,1.19,0,0,0-.43.85l-.3,1.88a.78.78,0,0,1-.63.61c-.42,0-.73-.12-1-.74l-1.59-4c-.2-.51-.23-.77.22-.81s.5.3.61.69l.45,1.54c.27.92.69,1.17,1,1.15.52,0,.83-.42.93-1.14l.26-2a.69.69,0,0,1,.66-.64c.58,0,.77.12,1,1l.42,1.47c.19.69.59,1,1,1s.71-.38.85-1.18L77,45.59a.59.59,0,0,1,.46-.47.31.31,0,0,1,.36.26,2.58,2.58,0,0,1-.13.88l-1,4.1a.89.89,0,0,1-.73.69C75.33,51.11,75.24,50.75,74.81,49.42Z" />
                        <path d="M84.68,47.24,83,47.36c-1.62.14-2.17.48-2.09,1.37a1.77,1.77,0,0,0,1.89,1.38,1.58,1.58,0,0,0,.9-.26l.64-.5c.12-.09.14-.2.4-.23.71-.06.07,1.34-2,1.52a2.78,2.78,0,0,1-3.15-2.75c-.17-1.93.71-3.28,2.6-3.44a2.57,2.57,0,0,1,3,2.26C85.24,47,85.1,47.2,84.68,47.24Zm-2.34,0,.44,0c.63-.06,1.19-.58,1.14-1.1A1.32,1.32,0,0,0,82.35,45c-1,.08-1.55.66-1.49,1.34S81.55,47.33,82.34,47.26Z" />
                        <path d="M87.11,44.61l-.18-2c0-.31.08-.56.24-.58s.3.19.33.53l.19,2c0,.31-.06.53-.25.55S87.14,44.92,87.11,44.61Z" />
                        <path d="M92.53,47.17c.36,0,.65-.35.76-.91l.37-2.11a.84.84,0,0,1,.75-.76c.08,0,.44,0,.5.33a2.65,2.65,0,0,1-.12.72l-1.11,4.47a.88.88,0,0,1-.82.79,1.07,1.07,0,0,1-1.26-.76l-1.84-4.18c-.3-.66-.19-.91.23-.95.6-.05.79,0,1.16,1.22C91.63,46.54,91.9,47.23,92.53,47.17Z" />
                        <path d="M102,45.7l-1.65.12c-1.62.15-2.17.48-2.09,1.37A1.9,1.9,0,0,0,101,48.32l.64-.51c.12-.09.14-.19.4-.22.71-.06.07,1.34-2,1.52a2.77,2.77,0,0,1-3.14-2.75c-.18-1.94.71-3.28,2.59-3.45a2.58,2.58,0,0,1,3,2.27C102.53,45.49,102.38,45.66,102,45.7Zm-2.34,0,.45,0a1.2,1.2,0,0,0,1.14-1.11,1.35,1.35,0,0,0-1.58-1.15c-1,.09-1.55.67-1.49,1.35S98.84,45.79,99.62,45.73Z" />
                        <path d="M116.12,44.45l-1.65.12c-1.62.14-2.17.48-2.09,1.37a1.77,1.77,0,0,0,1.89,1.38,1.58,1.58,0,0,0,.9-.26l.64-.5c.12-.09.14-.2.4-.23.71-.06.07,1.34-2,1.52a2.77,2.77,0,0,1-3.14-2.75c-.18-1.93.71-3.28,2.59-3.44a2.58,2.58,0,0,1,3,2.26C116.68,44.24,116.54,44.41,116.12,44.45Zm-2.34,0,.44,0c.63-.06,1.19-.58,1.15-1.1a1.33,1.33,0,0,0-1.58-1.15c-1,.08-1.55.66-1.49,1.34S113,44.54,113.78,44.47Z" />
                        <path d="M121.23,44.62c.36,0,.65-.34.76-.91l.36-2.11a.83.83,0,0,1,.75-.75c.08,0,.45,0,.51.32a3.11,3.11,0,0,1-.12.72l-1.11,4.48a.88.88,0,0,1-.83.78,1.06,1.06,0,0,1-1.25-.76l-1.84-4.18c-.3-.66-.19-.91.23-.94.6-.06.79,0,1.16,1.21C120.33,44,120.6,44.68,121.23,44.62Z" />
                        <path d="M130.66,43.16l-1.65.12c-1.62.14-2.17.48-2.09,1.37A1.77,1.77,0,0,0,128.81,46a1.58,1.58,0,0,0,.9-.26l.64-.5c.12-.1.14-.2.4-.23.71-.06.07,1.34-2,1.52a2.78,2.78,0,0,1-3.15-2.75c-.17-1.93.71-3.28,2.6-3.44a2.57,2.57,0,0,1,3,2.26C131.22,43,131.08,43.12,130.66,43.16Zm-2.34,0,.44,0c.63-.06,1.19-.58,1.14-1.1a1.33,1.33,0,0,0-1.57-1.15c-1,.08-1.55.66-1.49,1.34S127.53,43.25,128.32,43.18Z" />
                        <path d="M136,39.84c.21,0,.45,0,.48.38s-.15.4-.54.68a2.11,2.11,0,0,0-.81.7,2,2,0,0,0-.27,1.18L135,45c.06.6-.1.91-.44.94-.68.06-.8-.09-.9-1.19l-.35-4c0-.6.13-.93.58-1s1.14.11,1.27.1C135.62,39.92,135.61,39.87,136,39.84Z" />
                        <path d="M147,39a3.19,3.19,0,0,0,.75-.17,3.85,3.85,0,0,1,.62-.16c1.13-.1,1.74.53,1.85,1.87l.3,3.37c0,.08,0,.52-.29.55-.52,0-.62-.1-.65-.47l-.2-2.22c-.14-1.67-.74-2.46-1.79-2.37s-1.51,1-1.35,2.83l.18,2c0,.08,0,.53-.3.56-.52,0-.57-.24-.6-.32a.59.59,0,0,1,0-.23l-.63-7a.48.48,0,1,1,.95,0C145.87,38.52,146.28,39.09,147,39Z" />
                        <path d="M152.51,42.9c-.09-1.07.82-1.76,2-2s1.85-.82,1.8-1.37A1.3,1.3,0,0,0,155,38.26l-.21,0c-.76.07-1,.88-1.61.93-.15,0-.31,0-.33-.15,0-.53,1-1,1.91-1,2.78-.25,2.66.79,2.88,3.32l.17,1.91c0,.34-.11.54-.42.57s-.75-.12-1.17-.08c-.78.07-1,.45-1.8.53A1.59,1.59,0,0,1,152.51,42.9Zm2.39.82a1.77,1.77,0,0,0,1.69-1.84,1.12,1.12,0,0,0-1.28-1c-.86.08-1.84.9-1.77,1.74A1.23,1.23,0,0,0,154.9,43.72Z" />
                        <path d="M165.93,42.5c0,.39-.18.6-.45.62a5.81,5.81,0,0,1-1,0c-.32,0-.14,0-.55.05,0,0-.72.27-.77.27s0,0-.2.08a.92.92,0,0,1-.26,0c-1.57.14-2.71-.76-2.89-2.85-.09-1,.09-3.12,2.18-3.3.42,0,1.06.06,1.38,0a.81.81,0,0,0,.79-.54c.38-1.06-.22-1.85.57-1.92.42,0,.52.19.55.61ZM163,43c.42,0,1.85-.51,1.65-2.83-.13-1.41-1-2.44-2-2.3-.52.07-1.92.33-1.71,2.76C161,42.09,161.82,43.08,163,43Z" />
                        <path d="M168.33,42.21c-.05-.55.19-.78.61-.82.6,0,.79.25.84.8l.07.78c.12,1.39-.93,2.09-1,1.72,0-.13.18-.67.16-.88C169,43.18,168.4,43,168.33,42.21Z" />
                        <path d="M47.74,66.79c0,.14-.09.51-.35.54s-.46-.09-.49-.46l-.2-2.22c-.14-1.62-.77-2.49-1.92-2.39-1.57.14-1.41,2.23-1.37,2.65l.19,2.25c0,.13-.08.54-.34.56s-.46-.12-.49-.48l-.21-2.38c-.14-1.6-.77-2.36-1.89-2.26s-1.54,1-1.4,2.63l.2,2.3c0,.13-.08.53-.34.53s-.38,0-.46-.33c0-.13-.12-.81-.22-2l-.18-1.94c-.1-1.15,0-1.34.46-1.38.16,0,.11,0,.69,0-.16,0,.37,0,.42,0a4.08,4.08,0,0,0,.95-.24,5.79,5.79,0,0,1,.72-.2l.27,0a1.17,1.17,0,0,1,.48.06l.62.21a1.84,1.84,0,0,0,.72.1,1.7,1.7,0,0,0,.62-.14l.58-.34a2.18,2.18,0,0,1,.87-.26c1.1-.1,1.64.35,1.74,1.5Z" />
                        <path d="M53.86,61.55c0-.55.24-.58.39-.59s.42.17.46.67L55.1,66a.5.5,0,0,1-.53.62c-.44,0-.77-.06-1.21,0s-1.07.38-1.57.43c-1.18.1-1.61-.31-1.74-1.82l-.3-3.32c0-.37.09-.54.32-.56s.49.14.52.51l.25,2.85c0,.39.27,1.87,1.77,1.74a1.84,1.84,0,0,0,1.49-2.19Z" />
                        <path d="M59.12,63.66c-1.43-.11-1.89-.75-1.94-1.38-.11-1.2,1-1.94,2.29-2.05,1-.08,2,.11,2.05.53,0,.13-.08.25-.34.27-.47,0-.84-.24-1.55-.18s-1.16.5-1.12,1,.48.93,2,1.12c1.23.15,1.92.46,2,1.43s-.88,2-2.34,2.13-2.55-.22-2.59-.64c0-.24.11-.3.32-.32.44,0,1,.44,2.06.37s1.32-.61,1.26-1.19S60.58,63.77,59.12,63.66Z" />
                        <path d="M65.37,57.82c0-.26-.29-.31-.55-.29s-.41.17-.39.38.16.33.56.29C65.14,58.19,65.39,58,65.37,57.82Zm.44,3.42c-.1-1.13-.13-1.41-.75-1.36-.45,0-.55.37-.5,1l.33,3.72c.11,1.2.2,1.35.78,1.3.36,0,.52-.36.47-1Z" />
                        <path d="M73,60.39c-.28,0-.85-.45-1.56-.39a2,2,0,0,0-1.65,2.47c.11,1.26.86,2.3,2.06,2.19,1-.09,1.16-.66,1.68-.7q.39,0,.42.3c0,.34-.86,1.24-2.22,1.36a2.94,2.94,0,0,1-3.37-3,3,3,0,0,1,2.79-3.57c1.23-.11,2.35.32,2.39.84C73.52,60.24,73.32,60.36,73,60.39Z" />
                        <path d="M75.74,63.55c-.1-1.08.82-1.76,2-2s1.85-.82,1.8-1.37a1.31,1.31,0,0,0-1.38-1.25l-.21,0c-.76.07-1,.88-1.6.93-.16,0-.31.06-.33-.15,0-.52,1-1,1.91-1,2.77-.25,2.65.79,2.88,3.33L81,63.9c0,.34-.11.53-.43.56s-.75-.12-1.16-.08c-.79.07-1,.45-1.8.53A1.58,1.58,0,0,1,75.74,63.55Zm2.39.81a1.76,1.76,0,0,0,1.68-1.83,1.11,1.11,0,0,0-1.27-1c-.87.08-1.85.9-1.77,1.74A1.23,1.23,0,0,0,78.13,64.36Z" />
                        <path d="M84.81,63.57a.57.57,0,0,1-.57.71c-.63.06-.73-.15-.85-1.51l-.52-5.88c-.06-.71,0-1.08.56-1.13s.7.23.75.75Z" />
                        <path d="M93.32,62c-.09-1.08.82-1.76,2-2s1.85-.82,1.8-1.37a1.3,1.3,0,0,0-1.37-1.25l-.21,0c-.76.07-1,.88-1.61.93-.15,0-.31.06-.33-.15,0-.52,1-1,1.91-1,2.78-.25,2.66.79,2.88,3.33l.17,1.91c0,.34-.11.53-.42.56s-.75-.12-1.17-.08c-.78.07-1,.45-1.8.53A1.58,1.58,0,0,1,93.32,62Zm2.39.81A1.77,1.77,0,0,0,97.4,61a1.12,1.12,0,0,0-1.28-1c-.86.08-1.84.9-1.77,1.74A1.23,1.23,0,0,0,95.71,62.8Z" />
                        <path d="M105.75,58.05l.32,3.53c0,.52-.19.59-.37.61s-.43-.07-.46-.43L105,59.35c-.17-2-1-2.3-1.81-2.23-1,.09-1.56,1-1.45,2.31l.23,2.62a.43.43,0,0,1-.4.51c-.29,0-.4-.1-.44-.52l-.41-4.6a.45.45,0,0,1,.45-.54c.42,0,.9,0,1.42,0a9,9,0,0,1,1.52-.42C105.39,56.34,105.68,57.19,105.75,58.05Z" />
                        <path d="M114.22,60.92a.51.51,0,0,1-.45.62,6,6,0,0,1-1,0c-.31,0-.13,0-.54,0a6.51,6.51,0,0,1-.77.28c.08,0,0,0-.21.07a.66.66,0,0,1-.26,0c-1.56.14-2.7-.76-2.88-2.85-.09-1,.09-3.12,2.18-3.3.42,0,1.06.06,1.37,0a.82.82,0,0,0,.8-.54c.38-1.06-.22-1.85.57-1.92.41,0,.51.19.55.61Zm-2.91.47c.42,0,1.85-.51,1.64-2.83-.12-1.42-1-2.44-2-2.3-.52.07-1.92.33-1.71,2.76C109.33,60.51,110.11,61.5,111.31,61.39Z" />
                        <path d="M126.27,60.62a3.12,3.12,0,0,1-.81-6.18c2.2-.19,3.52.77,3.71,2.81A3,3,0,0,1,126.27,60.62Zm0-.58a2.24,2.24,0,0,0,1.87-2.67c-.16-1.83-1.25-2.47-2.34-2.38a2.21,2.21,0,0,0-1.91,2.55C124,59.21,124.94,60.15,126.25,60Z" />
                        <path d="M131.32,53.45c.05-.06,0,0,.11-.8a.57.57,0,0,1,.56-.5c.26,0,.48,0,.6.45a2.33,2.33,0,0,0,.37.91c.18.33.71.36.74.65s-.27.21-.66.93a3.16,3.16,0,0,0-.12,1.3l.11,1.25a1.87,1.87,0,0,0,.65,1.37c.23.14.64.36.66.6s-.17.49-.56.52a1.51,1.51,0,0,1-1.85-1.6l-.21-2.27a2,2,0,0,0-.47-1.25c-.16-.22-.45-.3-.48-.59S131.19,53.8,131.32,53.45Z" />
                        <path d="M138.08,53.65a3.19,3.19,0,0,0,.75-.17,3.6,3.6,0,0,1,.62-.16c1.12-.1,1.73.53,1.85,1.86l.3,3.38c0,.08,0,.52-.29.55-.53,0-.62-.1-.65-.47l-.2-2.22c-.15-1.68-.74-2.47-1.79-2.37s-1.52,1-1.36,2.83l.19,2c0,.08,0,.53-.3.55-.52.05-.57-.23-.61-.31a.66.66,0,0,1,0-.23l-.63-7a.48.48,0,1,1,1,0C137,53.14,137.4,53.71,138.08,53.65Z" />
                        <path d="M148.62,55.39l-1.65.12c-1.62.15-2.17.48-2.09,1.37A1.9,1.9,0,0,0,147.67,58l.64-.51c.12-.09.14-.2.4-.22.71-.06.06,1.34-2,1.52a2.78,2.78,0,0,1-3.15-2.75c-.17-1.94.71-3.28,2.6-3.45a2.58,2.58,0,0,1,3,2.27C149.18,55.18,149,55.35,148.62,55.39Zm-2.34,0,.44,0c.63,0,1.19-.58,1.14-1.1a1.34,1.34,0,0,0-1.58-1.15c-1,.09-1.54.67-1.48,1.34S145.49,55.48,146.28,55.41Z" />
                        <path d="M153.94,52.07c.21,0,.45,0,.48.38s-.15.41-.54.68a2.19,2.19,0,0,0-.81.71A1.92,1.92,0,0,0,152.8,55l.2,2.25c.06.6-.1.9-.44.93-.68.06-.8-.08-.9-1.18l-.35-4c-.05-.6.13-.93.58-1s1.14.11,1.27.09C153.58,52.16,153.57,52.11,153.94,52.07Z" />
                        <path d="M161.5,55.55c-.37-1.12-.64-1.55-1.22-1.5a.67.67,0,0,0-.35.17,1.12,1.12,0,0,0-.43.85l-.31,1.87a.75.75,0,0,1-.63.61c-.42,0-.72-.12-1-.73l-1.59-4c-.21-.51-.23-.77.21-.81s.5.3.62.68l.45,1.54c.27.93.69,1.18,1,1.16.52,0,.83-.42.92-1.14l.27-2a.68.68,0,0,1,.66-.63c.57,0,.77.11,1,1l.42,1.46c.19.7.59,1,1,1s.71-.38.85-1.19l.33-2.11a.6.6,0,0,1,.46-.46.3.3,0,0,1,.37.26,2.85,2.85,0,0,1-.13.88l-1,4.09a.9.9,0,0,1-.73.7C162,57.25,161.93,56.88,161.5,55.55Z" />
                        <path d="M167.36,48.77c0-.26-.29-.31-.55-.29s-.41.17-.39.38.16.32.55.29C167.13,49.14,167.38,49,167.36,48.77Zm.44,3.42c-.1-1.13-.13-1.42-.76-1.36-.44,0-.54.37-.49,1l.33,3.72c.11,1.2.2,1.35.78,1.3.36,0,.52-.37.47-1Z" />
                        <path d="M172.21,53.63c-1.43-.11-1.89-.76-1.94-1.39-.11-1.2,1-1.93,2.29-2,.94-.09,2,.11,2.05.53,0,.13-.08.24-.34.26-.47,0-.84-.24-1.55-.18s-1.17.5-1.12,1,.48.93,2,1.11c1.23.16,1.91.47,2,1.43s-.88,2-2.34,2.13-2.55-.22-2.59-.64c0-.23.11-.29.32-.31.44,0,1,.43,2.06.37s1.31-.62,1.26-1.2S173.67,53.73,172.21,53.63Z" />
                        <path d="M182.47,52.39l-1.65.12c-1.62.14-2.17.48-2.09,1.37a1.78,1.78,0,0,0,1.89,1.39,1.64,1.64,0,0,0,.89-.27l.64-.5c.13-.09.14-.2.41-.22.7-.07.06,1.34-2,1.52A2.79,2.79,0,0,1,177.44,53c-.17-1.93.71-3.27,2.59-3.44a2.58,2.58,0,0,1,3,2.27C183,52.18,182.89,52.35,182.47,52.39Zm-2.34,0,.44,0c.63,0,1.19-.58,1.14-1.1a1.33,1.33,0,0,0-1.58-1.15c-1,.09-1.55.66-1.49,1.34S179.34,52.48,180.13,52.41Z" />
                    </g>
                </g>
            </TimelineTextSvg>
        </CSSTransition>
    </React.Fragment>
);

export default TimelineText;
