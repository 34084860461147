import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import NavList from './nav-list';
import NavItem from './nav-item';
import NavDot from './nav-dot';
import ArticlesAndInterviews from './articles-and-interviews';
import { Paths, DISCOGRAPHY_LINK } from 'shared/constants';

const StyledNavDot = styled(NavDot)`
    opacity: 0;
`;

const StyledNavItem = styled(NavItem)`
    &:hover {
        ${StyledNavDot} {
            opacity: 1;
        }
    }
`;

const StyledArticlesAndInterviews = styled(ArticlesAndInterviews)`
    position: absolute;
    left: 91px;
    top: 132px;
`;

const NavEntry = (props: { children: React.ReactNode }) => (
    <StyledNavItem>
        <StyledNavDot />
        {props.children}
    </StyledNavItem>
);

const NavLink = (props: { children: React.ReactNode; path: string }) => (
    <Link to={props.path}>
        <NavEntry>{props.children}</NavEntry>
    </Link>
);

const Nav = (props: { className?: string }) => (
    <div className={props.className}>
        <NavList>
            <NavLink path={Paths.STUDIO}>Studio</NavLink>
            <NavLink path={Paths.PROJECTS}>Projects</NavLink>
            <NavEntry>
                <a
                    href={DISCOGRAPHY_LINK}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Discography
                </a>
            </NavEntry>
            <NavLink path={Paths.BIO}>Bob Power</NavLink>
            <NavLink path={Paths.TIMELINE}>Timeline</NavLink>
            <NavLink path={Paths.SUBMISSIONS}>Submissions</NavLink>
            <NavLink path={Paths.CONTACT}>Contact</NavLink>
            <StyledArticlesAndInterviews />
        </NavList>
    </div>
);

export default Nav;
