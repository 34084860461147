import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { introAnimation } from 'pages/main/assets';

const play = keyframes`
    100% {
        background-position: 0 -30380px;
    }
`;

const Img = styled.div<MainAnimationProps>`
    ${props =>
        props.isPlaying
            ? css`
                  animation: ${play} 4s steps(49);
              `
            : css`
                  filter: blur(10px);
                  opacity: 0.5;
              `}
    background: url(${introAnimation.src}) 0 0;
    height: 620px;
    width: 770px;
`;

interface MainAnimationProps {
    isPlaying: boolean;
}

const MainAnimation: React.FC<MainAnimationProps> = props => {
    return <Img {...props} />;
};

export default React.memo(MainAnimation);
