import styled from 'styled-components';

export default styled.li`
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 10px;
    letter-spacing: 4.5px;
    line-height: 100%;
    margin-bottom: 31px;
`;
