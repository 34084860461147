import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withRouter, RouteComponentProps, Switch } from 'react-router';
import { Route } from 'react-router-dom';

import {
    ArticlesAndInterviews,
    Bio,
    Contact,
    Guitars,
    Main,
    MicCloset,
    MixRoom,
    Projects,
    Studio,
    Submissions,
    Timeline,
    NoMatch,
} from 'pages';
import { Paths } from 'shared/constants';

const AppContainer = styled.div`
    display: flex;
    justify-content: center;
`;

interface AppProps extends RouteComponentProps {}

const App = (props: AppProps) => {
    const [shouldPlayIntro, setShouldPlayIntro] = useState(true);

    useEffect(() => {
        const unlisten = props.history.listen(() => {
            setShouldPlayIntro(false);
        });
        return unlisten;
    }, [props.history]);

    return (
        <AppContainer>
            <Switch>
                <Route
                    path={Paths.HOME}
                    exact
                    render={() => (
                        <Main
                            shouldPlayIntro={shouldPlayIntro}
                            setShouldPlayIntro={setShouldPlayIntro}
                        />
                    )}
                />
                <Route path={Paths.STUDIO} exact component={Studio} />
                <Route path={Paths.MIC_CLOSET} exact component={MicCloset} />
                <Route path={Paths.MIX_ROOM} exact component={MixRoom} />
                <Route path={Paths.GUITARS} exact component={Guitars} />
                <Route path={Paths.PROJECTS} exact component={Projects} />
                <Route path={Paths.BIO} exact component={Bio} />
                <Route path={Paths.TIMELINE} exact component={Timeline} />
                <Route path={Paths.SUBMISSIONS} exact component={Submissions} />
                <Route path={Paths.CONTACT} exact component={Contact} />
                <Route
                    path={Paths.ARTICLES_AND_INTERVIEWS}
                    exact
                    component={ArticlesAndInterviews}
                />
                <Route component={NoMatch} />
            </Switch>
        </AppContainer>
    );
};

export default withRouter(App);
