import * as React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { Paths, DISCOGRAPHY_LINK } from 'shared/constants';
import {
    studioBobPower,
    studioContact,
    studioDiscography,
    studioHome,
    studioProjects,
    studioSubmissions,
    studioTimeline,
} from 'pages/studio/assets';

const linkStyles = css`
    cursor: pointer;
    opacity: 0;
    position: absolute;

    &:hover {
        opacity: 1;
    }
`;

const BobPowerLink = styled.img.attrs(() => studioBobPower)`
    ${linkStyles}
    left: 0;
    top: 501px;
`;

const ContactLink = styled.img.attrs(() => studioContact)`
    ${linkStyles}
    right: 18px;
    top: 464px;
`;

const DiscographyLink = styled.img.attrs(() => studioDiscography)`
    ${linkStyles}
    left: 183px;
    top: 0;
`;

const HomeLink = styled.img.attrs(() => studioHome)`
    ${linkStyles}
    left: 680px;
    top: 0;
`;

const ProjectsLink = styled.img.attrs(() => studioProjects)`
    ${linkStyles}
    left: 0;
    top: 23px;
`;

const SubmissionsLink = styled.img.attrs(() => studioSubmissions)`
    ${linkStyles}
    right: 18px;
    top: 85px;
`;

const TimelineLink = styled.img.attrs(() => studioTimeline)`
    ${linkStyles}
    left: 0;
    top: 147px;
`;

const Links = () => (
    <React.Fragment>
        <Link to={Paths.BIO}>
            <BobPowerLink />
        </Link>
        <Link to={Paths.CONTACT}>
            <ContactLink />
        </Link>
        <a href={DISCOGRAPHY_LINK} target="_blank" rel="noopener noreferrer">
            <DiscographyLink />
        </a>
        <Link to={Paths.HOME}>
            <HomeLink />
        </Link>
        <Link to={Paths.PROJECTS}>
            <ProjectsLink />
        </Link>
        <Link to={Paths.SUBMISSIONS}>
            <SubmissionsLink />
        </Link>
        <Link to={Paths.TIMELINE}>
            <TimelineLink />
        </Link>
    </React.Fragment>
);

export default Links;
