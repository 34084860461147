import React from 'react';
import { interview } from 'pages/main/assets';

export default function Interview() {
    return (
        <a
            href="https://www.youtube.com/watch?v=uCIL5suG4UU"
            target="_blank"
            rel="noopener noreferrer"
        >
            <img alt={interview.alt} src={interview.src} />
        </a>
    );
}
