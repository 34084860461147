import * as React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { Hideable } from 'shared/types';
import { fadeIn } from '../../styles';

import { mainMixingBoardUnder } from 'pages/main/assets';

interface MixingBoardUnderProps extends Hideable {}

const MixingBoardUnderImg = styled.img.attrs(() => mainMixingBoardUnder)`
    position: absolute;
    top: 486px;
    width: 695px;
    z-index: 0;

    ${fadeIn}
`;

const MixingBoardUnder: React.FC<MixingBoardUnderProps> = props => (
    <CSSTransition in={props.isVisible} mountOnEnter timeout={1000}>
        <MixingBoardUnderImg />
    </CSSTransition>
);

export default MixingBoardUnder;
