import {
    bobPowerMp3,
    contactMp3,
    introSoundtrackMp3,
    heartbeatMp3,
    projectsMp3,
    studioCloseMp3,
    studioEnterMp3,
    submissionsMp3,
    timelineMp3,
} from 'shared/assets/audio';

import {
    bobScribbles,
    contactArt,
    interview,
    introAnimation,
    mainArrow,
    mainBackground,
    mainBobText,
    mainBob,
    mainGuitarArt,
    mainGuitarScribbles,
    mainGuitar,
    mainMicrophoneLightning,
    mainMicrophone,
    mainMixingBoardOver,
    mainMixingBoardUnder,
    mixingAndMastering,
    projectsText,
} from 'shared/assets/img';

export {
    bobPowerMp3,
    contactMp3,
    introSoundtrackMp3,
    heartbeatMp3,
    projectsMp3,
    studioCloseMp3,
    studioEnterMp3,
    submissionsMp3,
    timelineMp3,
    bobScribbles,
    contactArt,
    interview,
    introAnimation,
    mainArrow,
    mainBackground,
    mainBobText,
    mainBob,
    mainGuitarArt,
    mainGuitarScribbles,
    mainGuitar,
    mainMicrophoneLightning,
    mainMicrophone,
    mainMixingBoardOver,
    mainMixingBoardUnder,
    mixingAndMastering,
    projectsText,
};

export const forPreload = {
    img: [
        bobScribbles,
        contactArt,
        interview,
        introAnimation,
        mainArrow,
        mainBackground,
        mainBobText,
        mainBob,
        mainGuitarArt,
        mainGuitarScribbles,
        mainGuitar,
        mainMicrophoneLightning,
        mainMicrophone,
        mainMixingBoardOver,
        mainMixingBoardUnder,
        mixingAndMastering,
        projectsText,
    ].map(x => x.src),
    audio: [
        bobPowerMp3,
        contactMp3,
        introSoundtrackMp3,
        heartbeatMp3,
        projectsMp3,
        studioCloseMp3,
        studioEnterMp3,
        submissionsMp3,
        timelineMp3,
    ],
};
