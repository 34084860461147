import createPopup from './create-popup';

import {
    aguilarMp3,
    audioRackMp3,
    c12Mp3,
    c24Mp3,
    compressorsMp3,
    consoleMp3,
    fenderJazzBassMp3,
    fenderStratMp3,
    fenderTeleCustomMp3,
    fenderTeleDeluxeMp3,
    genelecsMp3,
    gibsonEs295Mp3,
    gibsonEs335Mp3,
    gibsonHowardRobertsMp3,
    gibsonJ200Mp3,
    glMp3,
    guildF50Mp3,
    l251Mp3,
    l47Mp3,
    martinD16hMp3,
    mixRackMp3,
    needQuietMp3,
    neve1073Mp3,
    neve1095Mp3,
    pendulumMp3,
    pensaSuhrMp3,
    rhodesMp3,
    sadowskyMp3,
    schoepsMp3,
    sessionStratMp3,
    sm57Mp3,
    stompboxesMp3,
    suitcaseMp3,
    synthsMp3,
    u47Mp3,
    u67Mp3,
    vettaMp3,
    vm1Mp3,
    wurlitzerMp3,
    yamahaSg1500Mp3,
} from 'shared/assets/audio';
import {
    aguilar as aguilarImg,
    audioRack as audioRackImg,
    c12 as c12Img,
    c24 as c24Img,
    compressors as compressorsImg,
    console as consoleImg,
    fenderJazzBass as fenderJazzBassImg,
    fenderStrat as fenderStratImg,
    fenderTeleCustom as fenderTeleCustomImg,
    fenderTeleDeluxe as fenderTeleDeluxeImg,
    genelecs as genelecsImg,
    gibsonEs295 as gibsonEs295Img,
    gibsonEs335 as gibsonEs335Img,
    gibsonHowardRoberts as gibsonHowardRobertsImg,
    gibsonJ200 as gibsonJ200Img,
    gl as glImg,
    guildF50 as guildF50Img,
    l251 as l251Img,
    l47 as l47Img,
    martinD16h as martinD16hImg,
    mixRack as mixRackImg,
    needQuiet as needQuietImg,
    neve1073 as neve1073Img,
    neve1095 as neve1095Img,
    pendulum as pendulumImg,
    pensaSuhr as pensaSuhrImg,
    rhodes as rhodesImg,
    sadowsky as sadowskyImg,
    schoeps as schoepsImg,
    sessionStrat as sessionStratImg,
    sm57 as sm57Img,
    stompboxes as stompboxesImg,
    suitcase as suitcaseImg,
    synths as synthsImg,
    u47 as u47Img,
    u67 as u67Img,
    vetta as vettaImg,
    vm1 as vm1Img,
    wurlitzer as wurlitzerImg,
    yamahaSg1500 as yamahaSg1500Img,
} from 'shared/assets/img';

export const aguilar = createPopup({
    ...aguilarImg,
    audio: aguilarMp3,
});

export const audioRack = createPopup({
    ...audioRackImg,
    audio: audioRackMp3,
});

export const c12 = createPopup({
    ...c12Img,
    audio: c12Mp3,
});

export const c24 = createPopup({
    ...c24Img,
    audio: c24Mp3,
});

export const compressors = createPopup({
    ...compressorsImg,
    audio: compressorsMp3,
});

export const console = createPopup({
    ...consoleImg,
    audio: consoleMp3,
});

export const fenderJazzBass = createPopup({
    ...fenderJazzBassImg,
    audio: fenderJazzBassMp3,
});

export const fenderStrat = createPopup({
    ...fenderStratImg,
    audio: fenderStratMp3,
});

export const fenderTeleCustom = createPopup({
    ...fenderTeleCustomImg,
    audio: fenderTeleCustomMp3,
});

export const fenderTeleDeluxe = createPopup({
    ...fenderTeleDeluxeImg,
    audio: fenderTeleDeluxeMp3,
});

export const genelecs = createPopup({
    ...genelecsImg,
    audio: genelecsMp3,
});

export const gibsonEs295 = createPopup({
    ...gibsonEs295Img,
    audio: gibsonEs295Mp3,
});

export const gibsonEs335 = createPopup({
    ...gibsonEs335Img,
    audio: gibsonEs335Mp3,
});

export const gibsonHowardRoberts = createPopup({
    ...gibsonHowardRobertsImg,
    audio: gibsonHowardRobertsMp3,
});

export const gibsonJ200 = createPopup({
    ...gibsonJ200Img,
    audio: gibsonJ200Mp3,
});

export const gl = createPopup({
    ...glImg,
    audio: glMp3,
});

export const guildF50 = createPopup({
    ...guildF50Img,
    audio: guildF50Mp3,
});

export const l251 = createPopup({
    ...l251Img,
    audio: l251Mp3,
});

export const l47 = createPopup({
    ...l47Img,
    audio: l47Mp3,
});

export const martinD16h = createPopup({
    ...martinD16hImg,
    audio: martinD16hMp3,
});

export const mixRack = createPopup({
    ...mixRackImg,
    audio: mixRackMp3,
});

export const needQuiet = createPopup({
    ...needQuietImg,
    audio: needQuietMp3,
    audioOptions: {
        volume: 0.5,
    },
});

export const neve1073 = createPopup({
    ...neve1073Img,
    audio: neve1073Mp3,
});

export const neve1095 = createPopup({
    ...neve1095Img,
    audio: neve1095Mp3,
});

export const pendulum = createPopup({
    ...pendulumImg,
    audio: pendulumMp3,
});

export const pensaSuhr = createPopup({
    ...pensaSuhrImg,
    audio: pensaSuhrMp3,
});

export const rhodes = createPopup({
    ...rhodesImg,
    audio: rhodesMp3,
});

export const sadowsky = createPopup({
    ...sadowskyImg,
    audio: sadowskyMp3,
});

export const schoeps = createPopup({
    ...schoepsImg,
    audio: schoepsMp3,
});

export const sessionStrat = createPopup({
    ...sessionStratImg,
    audio: sessionStratMp3,
});

export const sm57 = createPopup({
    ...sm57Img,
    audio: sm57Mp3,
});

export const stompboxes = createPopup({
    ...stompboxesImg,
    audio: stompboxesMp3,
});

export const suitcase = createPopup({
    ...suitcaseImg,
    audio: suitcaseMp3,
});

export const synths = createPopup({
    ...synthsImg,
    audio: synthsMp3,
});

export const u47 = createPopup({
    ...u47Img,
    audio: u47Mp3,
});

export const u67 = createPopup({
    ...u67Img,
    audio: u67Mp3,
});

export const vetta = createPopup({
    ...vettaImg,
    audio: vettaMp3,
});

export const vm1 = createPopup({
    ...vm1Img,
    audio: vm1Mp3,
});

export const wurlitzer = createPopup({
    ...wurlitzerImg,
    audio: wurlitzerMp3,
});

export const yamahaSg1500 = createPopup({
    ...yamahaSg1500Img,
    audio: yamahaSg1500Mp3,
});
