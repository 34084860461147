import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import * as assets from './assets';
import { Area, PageContainer } from 'shared/components';
import { Paths, DISCOGRAPHY_LINK } from 'shared/constants';
import { loadBeforeDisplay } from 'shared/hocs';

const navigationAreas = {
    home: {
        coords: [[424, 11], [475, 28]],
        to: Paths.HOME,
    },
    discography: {
        coords: [[621, 1], [741, 17]],
        to: null,
    },
    submissions: {
        coords: [[729, 190], [747, 312]],
        to: Paths.SUBMISSIONS,
    },
    contact: {
        coords: [[723, 473], [738, 553]],
        to: Paths.CONTACT,
    },
    bio: {
        coords: [[0, 504], [18, 605]],
        to: Paths.BIO,
    },
    studio: {
        coords: [[0, 165], [14, 239]],
        to: Paths.STUDIO,
    },
    projects: {
        coords: [[0, 49], [14, 140]],
        to: Paths.PROJECTS,
    },
    logoText: {
        coords: [[593, 43], [714, 55]],
        to: Paths.HOME,
    },
};

const timelineAreaCoords = {
    '1952': [[203, 88], [220, 132], [314, 89], [296, 53]],
    '1955': [[43, 133], [172, 166], [173, 118], [62, 89]],
    '1962': [[20, 193], [28, 294], [147, 260], [119, 171]],
    '1964-1965': [[157, 203], [184, 270], [309, 175], [269, 135]],
    '1966': [[346, 82], [369, 33], [446, 41], [438, 93]],
    '1966 1/2': [[509, 19], [595, 29], [559, 119], [477, 92]],
    '1967-1970': [[336, 135], [315, 219], [372, 235], [387, 147]],
    '1970': [[478, 143], [472, 203], [542, 213], [536, 145]],
    '1972': [[545, 130], [583, 241], [748, 172], [689, 57]],
    '1974': [[559, 249], [557, 314], [634, 329], [671, 247]],
    '1975': [[381, 264], [389, 345], [549, 302], [516, 244]],
    '1979': [[180, 280], [182, 383], [374, 357], [364, 264]],
    '1981': [[95, 296], [112, 366], [164, 355], [161, 285]],
    '1982': [[0, 320], [11, 444], [103, 414], [78, 307]],
    '1982-2': [[108, 469], [247, 478], [228, 392], [124, 387]],
    '1984': [[257, 382], [268, 485], [462, 450], [430, 351]],
    '1985-1989': [[475, 338], [475, 416], [596, 419], [580, 334]],
    '1988-1994': [[629, 359], [602, 462], [710, 484], [721, 383]],
    '1991': [[489, 456], [497, 535], [599, 511], [564, 447]],
    '1994': [[366, 495], [360, 569], [457, 545], [451, 492]],
    '1995': [[269, 487], [332, 501], [327, 600], [241, 582]],
    '1997': [[150, 537], [224, 564], [228, 516], [174, 500]],
    now: [[21, 518], [39, 613], [141, 586], [100, 492]],
};

type Hovered = keyof typeof navigationAreas | keyof typeof timelineAreaCoords;

function formatCoordsForClipPath(coords: number[][]): string {
    return coords
        .map(point => point.map(coord => `${coord}px`).join(' '))
        .join(',');
}

function getFormattedNavigationClipPath(
    hovered: keyof typeof navigationAreas
): string {
    const { coords } = navigationAreas[hovered];
    const xValues = coords.map(point => point[0]);
    const yValues = coords.map(point => point[1]);
    return formatCoordsForClipPath([
        [Math.min(...xValues), Math.min(...yValues)],
        [Math.min(...xValues), Math.max(...yValues)],
        [Math.max(...xValues), Math.max(...yValues)],
        [Math.max(...xValues), Math.min(...yValues)],
    ]);
}

function getFormattedTimelineClipPath(
    hovered: keyof typeof timelineAreaCoords
): string {
    return formatCoordsForClipPath(timelineAreaCoords[hovered]);
}

function getCssForHover(hovered: Hovered) {
    const clipPath = isNavigationKey(hovered)
        ? getFormattedNavigationClipPath(hovered)
        : getFormattedTimelineClipPath(hovered);
    return css`
        clip-path: polygon(${clipPath});
        opacity: 1;
    `;
}

const isNavigationKey = (h: Hovered): h is keyof typeof navigationAreas =>
    h in navigationAreas;

const TimelineOverlayImg = styled.img.attrs(() => ({
    ...assets.timelineOverlay,
    useMap: '#timeline-map',
}))<{ hovered: Hovered | null }>`
    left: 0;
    opacity: 0;
    position: absolute;
    z-index: 1;

    ${props => props.hovered && getCssForHover(props.hovered)}
`;

const ROOT_ID = 'timeline-root';

const Timeline = () => {
    const [hovered, setHovered] = useState<Hovered | null>(null);
    const resetHovered = () => {
        setHovered(null);
    };
    return (
        <PageContainer id={ROOT_ID}>
            <img alt={assets.timeline.src} src={assets.timeline.src} />
            <TimelineOverlayImg hovered={hovered} />
            <map name="timeline-map">
                {Object.entries(navigationAreas).map(([key, value]) => {
                    const coords = value.coords.flat().join(',');
                    return value.to ? (
                        <Link key={key} to={value.to}>
                            <Area
                                alt={key}
                                coords={coords}
                                shape="rect"
                                onMouseEnter={() =>
                                    setHovered(
                                        key as keyof typeof navigationAreas
                                    )
                                }
                                onMouseLeave={resetHovered}
                            />
                        </Link>
                    ) : (
                        <Area
                            key={key}
                            alt={key}
                            coords={coords}
                            href={DISCOGRAPHY_LINK}
                            rel="noopener noreferrer"
                            shape="rect"
                            target="_blank"
                            onMouseEnter={() =>
                                setHovered(key as keyof typeof navigationAreas)
                            }
                            onMouseLeave={resetHovered}
                        />
                    );
                })}
                {Object.entries(timelineAreaCoords).map(([key, value]) => {
                    const coords = value.flat().join(',');
                    return (
                        <Area
                            key={key}
                            alt={key}
                            coords={coords}
                            shape="poly"
                            onMouseEnter={() =>
                                setHovered(
                                    key as keyof typeof timelineAreaCoords
                                )
                            }
                            onMouseLeave={resetHovered}
                        />
                    );
                })}
            </map>
        </PageContainer>
    );
};

// <Area
//     alt=""
//     coords="21,518,39,613,141,586,100,492"
//     shape="poly"
// />
export default loadBeforeDisplay(Timeline, assets.forPreload);
