import {
    projectsArticlesInterviewsHover,
    projectsBg,
    projectsClickIt,
} from 'shared/assets/img';

import { aguilar } from './popups';

export { projectsArticlesInterviewsHover, projectsBg, projectsClickIt };

export const forPreload = {
    img: [
        projectsArticlesInterviewsHover,
        projectsBg,
        projectsClickIt,
        aguilar,
    ].map(x => x.src),
    audio: [aguilar.audio || ''],
};
