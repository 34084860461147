import { micClosetBackHover, micClosetBg } from 'shared/assets/img';

import { c12, c24, l251, l47, schoeps, sm57, u47, u67, vm1 } from './popups';

export { micClosetBackHover, micClosetBg };

export const forPreloadBeforeDisplay = {
    img: [micClosetBackHover, micClosetBg].map(x => x.src),
    audio: [],
};

export const forPreload = {
    img: [
        micClosetBackHover,
        micClosetBg,
        c12,
        c24,
        l251,
        l47,
        schoeps,
        sm57,
        u47,
        u67,
        vm1,
    ].map(x => x.src),
    audio: [c12, c24, l251, l47, schoeps, sm57, u47, u67, vm1].map(
        x => x.audio || ''
    ),
};
