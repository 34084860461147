import * as React from 'react';
import styled, { css } from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { Animatable, Hideable } from 'shared/types';

interface GuitarTextProps extends Animatable, Hideable {}

const GuitarText1SVG = styled.svg<GuitarTextProps>`
    left: 26px;
    position: absolute;
    top: 212px;
    transition: all 200ms linear;
    width: 58px;

    ${props =>
        props.isAnimating &&
        css`
            left: 102px;
            top: 215px;
            transform: rotate(55deg);
            width: 35px;
        `}

    .cls-1 {
        fill: white;
    }
`;

const GuitarText1: React.FC<GuitarTextProps> = props => (
    <GuitarText1SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.14 79.54" {...props}>
        <g id="Layer_2" data-name="Layer 2">
            <g id="text_gear_junkie" data-name="text gear junkie">
                <path
                    className="cls-1"
                    d="M3.88,9.55,2.28,6.87l-.2-.17-.49-.93L1.26,4.59l-.38-.5L.38,3.9,0,3.3l.09-.47,1-.67.53-.07L3.57.89,3.64.43,3.87.09,4.52,0l.17.47.86,1.16,0,.15L6,2.25l.15.39.22.15L7.53,5.38,9.1,7.86l.56.28L9.92,9l1.37,1.57.06.28L12.29,12l1,1.76.16.1L14,15.07l-.5.73-.82.33-.83.52-.51.51-.84.54-.58.46-.49-.08L8.41,16.73l-.08-.43-.73-.86L5.15,11.57l-1-1.32Zm-1.76-6,.19.29.41-.26L2.94,3,2.47,2.9l-.05.42ZM5.36,9.77l.5-.13v.24l.79.29L6.29,9.5l0-.43-.75,0-.31.43Zm.83-5L5.86,5l.39.27.23.62.26-.45ZM9.87,16.45l.2.93.36-.65.53.08.7-.47-.27-.28Z"
                />
                <path
                    className="cls-1"
                    d="M25.66,44.83v-1l-.42-.6.11-.65-.64-1-.28-1.46,0-.68-.38-.66.05-.67-.56-.9-.52-1.53L23,34.75l-.72-1.66,0-.44-.13-.56,0-.59-.2-.42,0-1-.68-1.23,0-1,.16-.36.62-.23.71-.45.21.12,2-1.29,0-.25.74-.46.74.17.93.7.88.94.82.48.13.21.72.38.42.51.29.16L32.07,30l1.44,1,.18.39,1.63,1.06.43.82.57.15,1.17,1.09-.07.91h-.31L37,36l.31.58-.39.31-.16.61.46.16.16-.55.22-.07.31.19.66-.68-.54-1.06.4-.13.61.51,1,.46.46.74-.13.3-.88.34-3.3,2.18-.44-.08-.71-.67-.4-.23L34,38.22l-1-.65-.78.17-.15.48-.49.24-.76,0-.58.75-.62.2-.41.45L29,40.7l.32,1.53.29.46v1l-.36.84-2.82,1.68-.45-.1ZM23,31l.79-.79-.05-.48.52.12.29-.51.46.17.63-.73-.42-.31-.32.4-.18-.27L25,28l.19.11.51-.32.56-.07-.17-.28.37-.48.61.82.17.57h.63l.38.5.35-.16.28.34.23-.66-.57-.09-.23-.36-.89.07-.58-1.43-.32-.25-.59.18-.3,1-.13-.41-1,0,.18.24-.42.14-.19-.3-.35.22.12.63-.7.38.33-.75-.35-.3-.62.59.18.59-.25.22.33.72-.12.27Zm.92-1.8-.39.31L23.27,29l.33-.3Zm0,4.46.69.08.26-.9-.34-.14-.11.39-.31-.29,0-.23-.6,0Zm.22-1.68,1,.05.16-.2.73.15.23.36.06.8.56,1.09,0,.63,1.24,1.1.61-.09,1-.69.08-.43L29,33.48,27.47,32.4l-.95-1.29-.14-.43.52.16.12-.63-.59-.27-1.18.68L25,31h-.21Zm.75,6,.61.61.18-.12-.08-.33.38-.21.31-.83.54-.06.14-.38-.34-.49-.38,0-.17.75-.79-.33-.21.13.09.49Zm.1-6.54.4-.54.26.51-.37.23-.36,0Zm1.84,13.43H27l.26.51L27.6,45,27,44.58l.29-.31.44,0,.14.13.46-.19.08-.79.25-.41-.3-.48-.07-.67-.35-.1-.29-1.16-.56.13-.06.2.45.41-.25.42.38.75-1.2,1Zm1.76-7.07-.06.35H29l.59-.27-.17-.54H29l0,.37Zm.93-8,.48.75.37-.17v-.19h-.25l-.19-.5Zm2.27,2.58.56.38.51-.26.16.25-.33.34.59.33.64-.4-.7-1.17Zm3.32,1.91.2.32.75-.25-.43-.37-.42,0Zm2.06,4.89.52-.49.46-.55-.37,0-.92.32-.15.32Z"
                />
                <path
                    className="cls-1"
                    d="M27.05,45.37l3-2,1-.51.37-.58,1.06-.51.71.32.3.48.64.3.53.63.57.25.74,1,.39.17.31.7,1.15.69.34.39.68.38.35,1,1.52-.25.07-.4-.42-.15-1.24-2.17L39,44.16l-.42-.66,0-.66-.5-1.19-.21-1.25L37.5,40l.11-1.45.17-.29,2.15-1.36.33-.09,2.66-2,.66.13,2.12,2.86,1,2.12.71.83.73,1.82,1,1.23,3.21,5.13.25.61-.16.61-2,1.09-1.18.88-.75,0-.73-1.36L46.8,50l-.4-.74,0-.68L45.65,48l-.07-1.33-.79-.89-.55-.87-.48-.4-.56-.9-.06-.34-.55-.11.11-.49-.43-.23-.67.42L41.82,44l.76.9,0,1,.51,1,.11,1,.9,1.42.11.73.28.56-.08.72.52.73,0,1,.48.86-.15.57-1.35.92-.76.42-.75.18-.76-.74L41.39,55l-.39-.4-1-.5-.42-.67-.55-.26L38.1,51.9l-1.65-1.2-.8-1.07-.76-.58L34.28,49l-.27.49.83,1.11,1.95,3.61,1.27,1.6,1.3,2.1v.65l-1,.47-1.55,1.21-.43,0L36,59.92l-1.45-2.3-.28-.2-.17-.57-.28-.24-2.37-4.47-.78-.72-.15-.55-1-1.12.06-.42-1.73-1.82-.62-1.18Zm9.72,12.24.3.58.61-.51-.21-.38Zm1.57-17.54.55,1.13.27-.17.21-1.76-.56.12Zm.31-1.44.07.47.35-.45-.13-.21Zm.54,3.66.25.39,1-.35L40,41.76Zm1.47,1.42.27.47.47-.33L41,43.24Zm1.39,8.9.05.45.36-.2.3.33.18-.59-.49-.21Zm6.44-2.39.13.65.63.15-.19-.87-.62-.51-.51.51Zm1.89-.87.1.32.63-.24-.43-.31Z"
                />
                <path
                    className="cls-1"
                    d="M60,73.51l-3.39,2.14-.42-.09-.59-.75-.38,0-.62.39-.07,1.2-.38.27-.18.62-.3.19-.09.47-1.72,1-.19-.45-.55.34.33.37-.44.22-.6,0-.64.11-.56-.13-.6.16-1.37-.74-.81-.93,0-.47-.58-.4-.34-1.26.09-1.22.51-.92.35-.87.78-.33L48,71.27l1.2-.85.22-.47,1.4-.88.41-.51-.36-.93-1.15,0-.44-.29-1.16.6-.69,1.4-.16,1.48-.55.34-1.44,0L44,71.94l-.38-.09-.37-.43,0-.8.49-2.22.88-.85.48-1,.77-.45L46,65.7l1-.4.23-.21,2.09-1.26.42,0,.69-.33h1.44l1.49.34,1.09.65.6.65.1.46.56.63.13.56.48.92.35.29.7,1.48.55.62,1.48,2.24.42.25.32.66ZM45.2,68l-.41.26.18.66-.47-.09-.28.56.51.61,1.21-.22-.08-.37-.31.07Zm4.85,4.19-.11-.28-.35.31L49.28,72l-.32.21.27.43-.44.06,0,.38-1.18.27,0,.32.48,0,0,.52-.77-.09-.08.43-.58-.31-.26.71.31.54-.29.6.72.84.45,1,.47-.13,1.32.73.63-.42.4-.09,0-.44h.92l.39-.3-.08-.53,1,0-.23-.47-1,.06-.42-.21-.16.3.27.33-.28.37-.44-.2,0,0v-.49l-.73-.15-.11-.73-.51-.45v-.73l.22-.26-.15-.23-.48.24-.27-.47.69-.08.83-.72Zm-1.19-6.31-.34-.23-.37.17-.54-.24-.32.3.79.44ZM48.5,77.21v-.48l.55-.1,0,.66Zm4.36-6.15-.43-.27-1,.23-.46.8-.59.48.07.52-.37.69.14.62.61.86.6.36,1.13.06-.07.49.7-.41V75.1l.4,0,.19-.31-.36-.06.2-2.08Zm-.69-6.72-1,.07,0,.85.35-.22.2.38.64-.27Zm.5,13.09-.5.16L52,78l.21.28.66-.42ZM55,66.77l-.85-1.14-.27.34.18.53.52.11.11.58Zm.41,6.7.05-.32L55.16,73l-.56.7.23.41.24-.38.34.23.32-.36Zm.32,1.11-.06-.25-.54,0-.1.31Zm0-5.32.26-.19-.6-.91-.24.54ZM57.69,71,57,70.38l-.26.4.43.53.36,1.24.4,0-.06-.34.27-.24-.66-.64Z"
                />
            </g>
        </g>
    </GuitarText1SVG>
);

const GuitarText2SVG = styled.svg<GuitarTextProps>`
    left: 108px;
    position: absolute;
    top: 208px;
    transition: all 200ms linear;
    width: 60px;

    ${props =>
        props.isAnimating &&
        css`
            left: 215px;
            top: 202px;
            transform: rotate(35deg) scale(1.7, 1.7);
        `}

    .cls-1 {
        fill: white;
    }
`;

const GuitarText2: React.FC<GuitarTextProps> = props => (
    <GuitarText2SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.16 31.86" {...props}>
        <g id="Layer_2" data-name="Layer 2">
            <g id="text_gear_junkie" data-name="text gear junkie">
                <path
                    className="cls-1"
                    d="M2,28.67,2.67,28l-.14-.39-.34-.24-.61-.58L1.3,25.9,1.61,25l0-.5.52-.41.44-.2.07-.18-.15-.37-.31-.13-.6.25-.11-.29L.94,23l-.45-.58L0,21.26.3,21V19.88l.44-.65.6-.41.29-.3,1.15-.41.57-.58.36-.06.59-.21.72.15L5.8,17l-.16-.45.61-1.82.36-.35,1-.25.83-.29.33.15.06.51-.22.3L8,15.09l-.13.24.19.18.22-.19.28.33.24-.38.35.17.12.33-.24.47-1,.34-.27.36.39.49.42.12L8.7,18l.44.47.08.44.28.48.13.87-.45.6-.45,1.06L8.4,22l-.57.56L6.07,23l-.35.39-1.06,0L4,23.91l-.12.22.24.12L4,24.69l.49.14.25-.24.52-.18.71.26.58-.25.36.12L7.22,24l.46-.25.46.1,1.24-.59,1.13,0,1.52.85.09.43.79.73.21,1-.1,1-.63.53-.24,1.33-.59.43-1.42.51-.67.63-1.76.45-.38.24-.66-.05-1.26.48H4.56l-.35-.25-.51.13L2.3,30.29l-.34-1Zm.61-2.3-.1.28.29-.11-.07-.22Zm0-.26.25.07L3,25.84H2.57Zm.72-5.93.1.48.19.31.52.08.12.27.7.47.36-.24h.43L6,21.05l.32-.11.14-.5-.3-.7-.44-.49H5l-.27-.14-.46.09-.66.48ZM4,29.56l-.5.16-.21.21v.26L4,30.05ZM4,23.49l-.47.37.18.27.3-.35Zm.47,6.12-.2.25,0,.26.53.21-.1-.52Zm.33-1.32.33.68.35.28.51-.2.74.23.94-.13.59-.48.28,0,.91-.32.65-.93-.28-.72-.55-.25-.76.38-1-.06-2.38,1Zm1.79-9.83.1.23h.43L7,18.35l-.2-.11Zm.51-1.2.18.26.46,0L7.58,17l-.32,0Zm1.33,6.92.33.13.43-.13L8.91,24Zm2.88.45-.06.27.26.13.24-.28Zm.08,1.69.24.67.51-.31-.22-.62.24-.15.06-.33L12,25.23l-.62.22,0,.24h.29l.14.46Z"
                />
                <path
                    className="cls-1"
                    d="M15.75,18.13l-.29-1.08.09-.75-.08-.24.59-1.67.43-.67.63-.49.5-.62.77-.38L20,11.46l.69.11.91-.32,1.16.08.72.39.83.15.33.31.25.13.59,1,.45.31.07.46.38.4.35.66.08.41-.21.23-1.2.27-1.35.63h-.3l-.61.41-.55.2-.2,0-1.84.58-.33-.06-.12.29-.61-.1-.09.39.41,0,.53.37.09.51.55.56.91.17,1-.23.49-.73.22-1.15.3-.24,1.43-.37.75.05.81-.47.3,0,.11.32-.14.4v.7l-.35.83-.24.71-.78.88-.64.22-.29.5-.91.44-1.62.41-1.15.45-1.64-.35H19l-.42-.12-.69-.69-.61-.23-.39-.73-.43-.33-.18-.39-.47-.37-.22-1Zm1.08-2.77.51-.31-.08-.53Zm.74-1.48.09.25L18,14l.14-.48Zm.12,6.52.45.45.27-.23-.15-.26v-.31l-.39,0Zm.82.36-.28.28.33.3.25-.15v-.34Zm.12-4.19.87-.26-.09-.45.57,0L21,15.53l.47.08.67-.24-.1-.34.11-.2-.95-1-.87-.07-.57.22-.28.53-.26,1Zm.37,4,.25.26h.15l.05-.3Zm.63.91.93-.08-.37-.79-.37.22Zm.37-1.8-.11.19.31.14.1-.17Zm.46.3-.07.36.64.06.12.34.26-.34.36-.12-.77-.4-.14.23Zm.92,1,.18.32.17-.2.39.09.21-.5-.27-.13-.32.31Zm1.19-.24-.21.3.34,0,.19-.29Zm1.56-.55.27.26.28-.1.28.06.17-.31L24.57,20Zm.66-.86.27.33.12-.47-.27-.08Z"
                />
                <path
                    className="cls-1"
                    d="M43.58,14.63l-2.93,1-.3-.14L40,14.86l-.29-.06-.53.19-.26.9-.33.13-.25.45-.26.09L38,16.9l-1.47.45L36.44,17l-.48.17.18.34-.37.08-.44-.13-.5,0-.4-.2-.49,0-.9-.8-.46-.84.11-.34-.37-.41,0-1,.29-.9.54-.61.41-.6.65-.11.75-.73,1-.44.25-.31,1.21-.43.4-.32-.11-.76-.86-.23-.29-.3-1,.26-.76.93-.37,1.09-.48.17-1.08-.29-1.16.44-.27-.13-.2-.4.12-.6L32.12,8l.81-.48.54-.68.66-.21.22-.26.82-.13.2-.12,1.8-.59.33,0,.57-.14,1.09.24,1.07.52.71.68.34.59v.36l.32.58v.45l.21.77.21.28.28,1.24.31.56.73,1.94.27.27.13.55ZM33.35,7.91,33,8l0,.53-.34-.15-.31.38.28.54,1,.05V9.09h-.25Zm2.94,4,0-.23-.32.18-.2-.2-.28.1.13.38-.34,0-.11.28h-.93l0,.25.35.12-.06.4-.56-.2-.13.31-.39-.33-.32.49.15.46-.33.41.4.75.17.85.37,0,.88.78.54-.21h.31l.07-.33.7.15.34-.16,0-.42.79.15-.1-.39-.73-.12-.28-.24-.18.2.15.3-.27.23-.3-.22v0l.09-.37L35.34,15l0-.57L35.08,14l.12-.55.21-.16-.07-.2-.41.1-.12-.4.53,0,.75-.39Zm-2,3.55.09-.37.43,0-.09.5Zm2.23-8.51-.22-.24L36,6.77l-.36-.28-.3.17.52.47Zm2.12,4.62-.28-.29h-.78l-.48.53-.53.25,0,.42-.4.45v.49l.32.76.39.37.84.24-.13.36.6-.19.07-.3.31.05.19-.2-.26-.11.51-1.54Zm-1.23,4.77-.41,0-.23.23.11.25.57-.2Zm1.87-10-.78-.12-.13.64.3-.1.09.32L39.24,7Zm.86,7.45.1-.24L40,13.4l-.54.43.1.35.24-.25.22.24.3-.22Zm0,.89v-.2l-.4-.12-.12.22Zm.8-6-.45-1-.26.2,0,.43.38.18,0,.45Zm.15,2,.23-.1L41,9.81l-.27.36Zm1.16,1.64-.43-.58-.27.25.24.48.06,1,.31,0v-.27l.24-.13-.38-.6Z"
                />
                <path
                    className="cls-1"
                    d="M52.81,2.8l.26.15.09.39L53,3.69l-.58.2-.24-.12-.08-.35-.42.2L52,4l-.41.15-.49,1.13.21.79L51,6.7,51,7l.56.4.86,2-.06.71.43,1-.39.41L50.8,12l-1.34.56-.39-.08-.19-.29-.17-1L48.5,11,48.08,9.9,48,9.23l-.55-1.55V7.22l-.49-.83,0-1-.42-.25-.79-2,.21-.66.57-.28.51.19,1.66-.8.4.06.1.43L49,2.41l.06.74-.17.17.11.25-.42.11.19.37.54-.12.19-.36h.57l.2.36.56-.18L51,3.49l.4-.14.1-.41.26-.2.16.25.29-.17-.29-.32,0-.34-.16-.28,0-.43-.53.19L51,1.38l-.24.08-.29.48.14.66-.27.3-.24-.43L50,2l.1-.66.54-.66L51.3.41,51.78,0l.51.51.12,1ZM49.7,5,49.35,5l-.18-.25-.33.12L49,5.1l.66.17Zm.59.53L50,5.26l-.1.35-.24-.19-.42.2.15.3h.29l.18.49Zm-.53,5.2.33-.21-.31-.11-.19.16Zm.86-4.51-.24-.12-.25.13.16.26ZM51,7.93l-.78-.15V8l.25.09v.14l.36,0Zm.59,2.18-.17-.32-.29.26,0,.18Z"
                />
            </g>
        </g>
    </GuitarText2SVG>
);

const GuitarText3SVG = styled.svg<GuitarTextProps>`
    left: 117px;
    position: absolute;
    top: 238px;
    transition: all 200ms linear;
    width: 70px;

    ${props =>
        props.isAnimating &&
        css`
            left: 196px;
            top: 253px;
            transform: rotate(45deg) scale(1.7, 1.7);
        `}

    .cls-1 {
        fill: white;
    }
`;

const GuitarText3: React.FC<GuitarTextProps> = props => (
    <GuitarText3SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.36 32.73" {...props}>
        <g id="Layer_2" data-name="Layer 2">
            <g id="text_gear_junkie" data-name="text gear junkie">
                <path
                    className="cls-1"
                    d="M4.6,30l.23.81-.89,1.56-2.06.41-.48-.12-.14-.12L1,31.4l.51-.73,1.11-.15L2.9,30,2,26.45,1.12,22l-.29-1.1L0,17.35.22,17,1,16.67l.14,0,.35.19V18l-.08.17.52.26.3,2.26.59.44,0,2.33L3,24l.53.32.08.82-.12.66.29,1.07.72.37Z"
                />
                <path
                    className="cls-1"
                    d="M17.6,20.78l.14,2.13-.21.36.65.7.52,1.93-.57.14.21.81.2.11-.06.72-1.25,1.4-1.5.4-1.56-.33-.89-.62-1-1.9-.45-2.22-.94-4-.29-1.1-.48-1.74-.42-2.1.38-.66.74-.06.61.54-.17,1.06.4.36,1.53,6.72,1.14,3.63.66.38L16.67,27l.44-1,0-1-1.2-3.32L15.19,19l-.1-1.45-1-2.75.06-.88,1.4-.22.43.43.2.71-.22.32,1.21,4.48.41.33Z"
                />
                <path
                    className="cls-1"
                    d="M33.6,24.07l-.32.54-.79.22L31.12,23l-.42-.46-.53-1.31-1.09-1.42L28,17.86l-.29-1.09-.6-.55-.13-.11-.14.05.11,1,1.92,7.64-.2,1-.29.38-.46,0-.63-.6L26.18,21l-1-3.66.22-.32-.09-.89.28-.63,0-1.16.1-.17-.35-.2-.15-.52L25,13.3l-.14,0,.19-.81-.24-.33.4-.73.29-.07.16.57.18.1.57,1.16-.12.14.57.44.5.72L28,15l.69,1.36.64.75.4.9.33.63.49.31.14,0-1-4.09.16-.49-.3-1.1-.05-1.13.7.36-.19,1,1.06,1.12,1.08,4,.13,1.57L33,21.2ZM29.21,11.14l-.14-.57v-.31l.25.4.13.42Z"
                />
                <path
                    className="cls-1"
                    d="M47.34,20.67l-.05.13-.43.1L46,20.28l-.35-.76-.37-.2-.69-1.44-.43-1-.48-.3-.58-1-1,.26-.31.49.72,1.57-.2.36.72,1-.19.46.59,2.17-.19.37-.53.14-.66-.23-.8-3.44-.56-2.06-.16-.66-.14-1.58-.59-.55-.27-2.1L39,9.55,39.12,9l-.4-.9.22-.36.64-.18.56.32L40,8.63l.84,3.12,0,.32.11.43.52.26L42,12.2l.19-.35-.29-.54.31-.49,0-1.15.54-1.76L43,7.36l.14,0,.49.64.24-.06,0,.14-.1,0-.36-.22-.21.36.27,1.51-.32,2,.11,1-.21.38.5.71-.36.7,0,.1.14.12.23-.32.52.26.54.87.71,1.12.24.95.94.66L47,19.6l.47.3Z"
                />
                <path
                    className="cls-1"
                    d="M57.28,19l-.78.24-.74-.42-.61-2.17-.38-2.48L54,11.81l.18-1-.46-.58L53,7.12l-.2-.71L52.35,5l.17-.52.37-.25.39-.11.76.54.32,1.2-.51.88.52.18.34.15.1.43-.47.44.21,1.25.09.39.73.4.17.67-.59.6.76.51L56,13.13l.11.43-.34.38-.07.13.48.79-.17.3.07.26.85.53.49,1.2.32,1.17Z"
                />
                <path
                    className="cls-1"
                    d="M72.3,14.84l-.65.79-4.26.87-.93-.05-.71-1.12L65.42,13l-.87-3.16.09-.59-.24-.85.15,0L64.77,8l-.9-.66.06-1.3-.24-1.39-.48-2.34L64.62,1,66.16.37,68.08,0l.49.12L69,.61l0,.46-.46.83-2.13.43-.88-.06-1.39.24-.51.73.66.38.53.32,1,3.06-.32.54.07.28.37.17.9-.36L69.14,7l.52.28.12,1-.58.62-.67.19-1.06-.17-1.22.33L66,9.56l1,3.55.49,1.17.5.16,3.66-1,.7.52Z"
                />
            </g>
        </g>
    </GuitarText3SVG>
);

const GuitarText: React.FC<GuitarTextProps> = props => (
    <React.Fragment>
        <CSSTransition in={props.isVisible} mountOnEnter timeout={1000}>
            <GuitarText1 {...props} />
        </CSSTransition>
        <CSSTransition in={props.isVisible} mountOnEnter timeout={1000}>
            <GuitarText2 {...props} />
        </CSSTransition>
        <CSSTransition in={props.isVisible} mountOnEnter timeout={1000}>
            <GuitarText3 {...props} />
        </CSSTransition>
    </React.Fragment>
);

export default GuitarText;
