import * as React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { Hideable } from 'shared/types';
import { fadeIn } from '../../styles';

interface BobTextProps extends Hideable {}

const MainBobTextSvg = styled.svg`
    left: 138px;
    position: absolute;
    top: 40px;
    width: 180px;

    .cls-1 {
        opacity: 0.5;
    }

    .cls-2 {
        fill: white;
    }

    ${fadeIn}
`;

const MainBobText: React.FC<BobTextProps> = props => (
    <CSSTransition in={props.isVisible} mountOnEnter timeout={1000}>
        <MainBobTextSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180.01 52.08">
            <g id="Layer_2" data-name="Layer 2">
                <g id="text_great_music" data-name="text great music">
                    <g className="cls-1">
                        <path d="M12.21,43.87l-.4,1.52-1-1.14-.57-3.06-2.56-2L7.13,42l.55,3.15-.54,1-.6-.73-2-2.94L3.11,40.23,2.88,43,3,46.4l-.38,1-.66-.78L0,41.2l.6-1.33L2,39.57l1.75-1,2.62,1.22,2-1.95,2.79,1.57Z" />
                        <path d="M21.25,42.49l-1.32.13-2.12.49-2.69-1.42,2.37-3.3.65-1.17-1-1-1.87,1.85-.71-.52,2-2.66,4,2,1.49,4.22ZM18,41.32l.82-1.42-.59-.77-1.75,2Z" />
                        <path d="M31.23,38.23l.86.48L31,39.91l-1.82-.55-1.09-1-1-.78-.31,1.82L26,40.59l-1.51-.34-1.71-8.14.68-.67.79.27,1.83,3.42,1.07-.79L27,33.45l.42-.75,1.05-.49.14-.31.73.62L29,33l-.5,1.51-.39,1,.79.36Z" />
                        <path d="M39.49,33.25l-.58.44-2.29.78-.74.8,1.66.63L39.63,35l1.19.08L38,37.85,33.27,35l2.59-5,4.49,2.34Zm-2.89,0,1.15-1.19-1.45-.44-1,1.05Z" />
                        <path d="M59,31.34,56.1,34.8l-3.31-.65.46-1.07,2.68.66,1.32-1.58-1-.32-1.65.28-3.51-2.55,2-4,1.84-.49,1-.79,1.61,1.33Zm-4-.75,1.37-2.23-2.58-2.17-.64,2.66Z" />
                        <path d="M63.58,22.2,64.9,23,64,24.67,62.9,26.39l1,2.92-.44,1-.58.13-.92-.7L60,24.53l1.08-1.34,1.59-.41Z" />
                        <path d="M74.35,24l-.78-.26-2.27.86-1,1.2L72.49,27l1.71-2.32,1.56.77-2.91,3-4.54-3.05,2.21-5.29,4.79,2.66Zm-3.07-.6,1.64-1L71,21.75l-1.21,1.46Z" />
                        <path d="M84.63,24.37l-1.15.77-2,1L78.09,24.4,81,20.85l.67-1.08-.89-.6L78.91,21l-.73-.84,2.07-2.38,4,1.69L85,23.86Zm-2.86.15.66-2.09L82,22.15l-1.29,1.33Z" />
                        <path d="M86,17l.93-1.35-.4-.77,1.11-1.23,1.57.52.36.62,1.27.91-1.23,1.47.87.94,0,1.8L90.59,21l1.78.78-.92.86L88.8,21.45l-.87-2.53-.47-1.52Z" />
                        <path d="M114.52,15.76l-1,1.34-1.64-.61-.68-3-1.44-1.28-.5,1.73.22,3.24-.65.62-1.48,0-.76-3.28-1.45-1.34-.55,2,.38,3.29-.44,1.47-1.6-1-1.21-5.59.73-1.18,1.46,0,1.78-.94,2.35.27,2.28-.94,2.86.82Z" />
                        <path d="M116.86,13.85l-1.45-4.6L116,7.87h.49l1.33.71,1,3.21,1.41,2,.55-2.77-.36-3.14.3-1L121,6.5l1,1,1.86,5.59-1,.86-1.43.2-1.87.84Z" />
                        <path d="M128.14,6.82l1.8,1L132.6,9l-2.33,3.78-3.59-.92.52-1.14,2.77.52,1-1.46-2.68-.53-2.58-1.39,2.38-2.93h3.2l-.71,1.31-2.16-.34Z" />
                        <path d="M135.43,1.8,134,1.19l1-1.19,1.28.51Zm1.81,1.67,1.49,5.36-1,1.32-1.39-.91-2-5.22,1.15-.91.48-.3Z" />
                        <path d="M143.68.65l3.38.39-.92,1.26-2-.43-1,2.76,2.38,2L147.14,5l1.42.63-2.74,2.85-4.73-3.2Z" />
                    </g>
                    <path
                        className="cls-2"
                        d="M47.57,45.75l1-.76,1.65.39.8,2.09.44.86,1-.51-.34-2.51,1-1.34.77,1.08-1.13,5.64-.88,1.1-1.22-1.08-.76-2.07L49,48.55l-.58.5,0,2-1.21,1-1.11-.41-2.56-5.22.4-1.16,1.08.87.76,2.27.93.61.28-.87Z"
                    />
                    <path className="cls-2" d="M59.68,50.41l-1.48.26-.62-.37,1.94-9.61,1.25-1.14.7,1.13Z" />
                    <path
                        className="cls-2"
                        d="M80.57,46.89,77.79,50,74.7,48.87l.5-.65,2.54.68,1.56-1.48-1.1-.66-1.57.75-3.54-3.33,2.61-4.32,2,.74,1.12-.55,1,1Zm-3.34-.78,1.49-2.55L76.44,41l-1.26,2.75Z"
                    />
                    <path
                        className="cls-2"
                        d="M86.67,38.33l1,1-1,1.23-1.06,1.82.35,3-.44,1.1-.57.2-1.29-1-.19-5.59.59-1L85.75,39Z"
                    />
                    <path
                        className="cls-2"
                        d="M97.09,40.88l-.63.44-2.4.26-.65,1L95,44.07,97,42.53,98,43l-2.84,2.61L91,42l2.89-4.73,4.4,2.92Zm-2.85-.09,1.32-1.17-1.48-.9-1,1.21Z"
                    />
                    <path
                        className="cls-2"
                        d="M107.32,42.84,106,42.77,104,44l-2.65-1.94L104,38.61l1.53-.75-1.52-1-2,1.91L100.89,38l2.88-2,3.84,2,.88,4.38Zm-2.85-.13,1.63-2.13-1.22-.48L103,41.6Z"
                    />
                    <path
                        className="cls-2"
                        d="M109.49,35.73l.78-1.32.1-.74,1.09-1.45,1.73,1.05-.26,1,1.28.76-1,1.32.39,1,.1,1.72.52.7.71,1.44-.5,1.52-2.79-2.29,0-2.7-.87-1.29Z"
                    />
                    <path
                        className="cls-2"
                        d="M133.76,35.17l-3.1,4.26-1.28.7-.73.94-.74,1.22-.83-.53-1.33-8.16.57-1.31,1.36.41,1.77-.81ZM130,37.94l1.74-2.45-2.31-1.71-1.5,2.45Z"
                    />
                    <path
                        className="cls-2"
                        d="M142.55,34.49l-.74-.32-2.35.61-1.22.93,2,.79,2.09-1.25,1.17.77-3,2.33-4.35-3.28,3.12-4.57,4.09,2.77Zm-3-1.19,1.61-.66-1.67-.72L138,33.07Z"
                    />
                    <path
                        className="cls-2"
                        d="M150.89,36.8l-4.71-3.39,3.52-4.79,4.86,3.48Zm-.16-1.67,1.44-2.67L150,30.57,148.56,33Z"
                    />
                    <path
                        className="cls-2"
                        d="M164.83,30.42l-2.81,4-1.36.21-.68,1.6L159.37,38l-1.47-1-.79-8.25.63-1.05,1.31.11,1.75-1Zm-3.45,3,1.85-2.78-2.58-2.34-1.44,3.1Z"
                    />
                    <path className="cls-2" d="M169.56,24.19l.92,8.47-1,1.16-.9-1-1.36-8.4.31-1.15.42-.41Z" />
                    <path
                        className="cls-2"
                        d="M179.43,28.88l-.73-.23-2.38.39-.62.94,1.5,1.47,2-2,.82.95L177.4,32.9,173,29.42,176.16,25,180,27.67Zm-3-1.25,1.5-.61-1.57-.84-.93,1.16Z"
                    />
                </g>
            </g>
        </MainBobTextSvg>
    </CSSTransition>
);

export default MainBobText;
