import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Paths } from 'shared/constants';

const ArticlesAndInterviews = styled(Link).attrs(() => ({
    children: 'Articles & Interviews',
    to: Paths.ARTICLES_AND_INTERVIEWS,
}))`
    display: inline-block;
    font-family: ${props => props.theme.fonts.main.family};
    font-weight: 300;
    font-size: 8px;
    letter-spacing: 0.5px;
    max-width: 42px;
    z-index: 1;

    &:hover {
        transform: scale(1.1);
    }
`;

export default ArticlesAndInterviews;
