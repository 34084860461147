import { mixRoom, mixRoomHover } from 'shared/assets/img';

import {
    compressors,
    genelecs,
    mixRack,
    needQuiet,
    neve1073,
    neve1095,
    pendulum,
} from './popups';

export { mixRoom, mixRoomHover };

export const forPreloadBeforeDisplay = {
    img: [mixRoom, mixRoomHover].map(x => x.src),
    audio: [],
};

export const forPreload = {
    img: [
        mixRoom,
        mixRoomHover,
        compressors,
        genelecs,
        mixRack,
        needQuiet,
        neve1073,
        neve1095,
        pendulum,
    ].map(x => x.src),
    audio: [
        compressors,
        genelecs,
        mixRack,
        needQuiet,
        neve1073,
        neve1095,
        pendulum,
    ].map(x => x.audio || ''),
};
