import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { mixingAndMastering } from 'pages/main/assets';
import { Paths } from 'shared/constants';

const Img = styled.img.attrs(() => mixingAndMastering)`
    cursor: pointer;
`;

const MixingAndMastering = () => (
    <Link to={Paths.CONTACT}>
        <Img />
    </Link>
);

export default MixingAndMastering;
