import React, { useEffect } from 'react';
import { Howl } from 'howler';

interface PopUpProps {
    alt: string;
    audio: Howl | null;
    src: string;
}

const PopUp = (props: PopUpProps) => {
    useEffect(() => {
        if (props.audio) {
            props.audio.play();
        }
        return () => {
            if (props.audio) {
                props.audio.stop();
            }
        };
    }, [props.audio]);

    return <img alt={props.alt} src={props.src} />;
};

PopUp.defaultProps = {
    audio: null,
};

interface CreatePopUpProps {
    alt: string;
    src: string;
    audio?: string;
    audioOptions?: Partial<IHowlProperties>;
}

export default function createPopup(props: CreatePopUpProps) {
    const howl = props.audio
        ? new Howl({ src: [props.audio], ...props.audioOptions })
        : null;
    const component = () => <PopUp {...props} audio={howl} />;
    return { audio: props.audio, src: props.src, component };
}
