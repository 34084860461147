import {
    c12,
    c24,
    l251,
    l47,
    schoeps,
    sm57,
    u47,
    u67,
    vm1,
} from 'shared/components/popups';

export { c12, c24, l251, l47, schoeps, sm57, u47, u67, vm1 };

export default {
    C12: c12.component,
    C24: c24.component,
    L251: l251.component,
    L47: l47.component,
    Schoeps: schoeps.component,
    SM57: sm57.component,
    U47: u47.component,
    U67: u67.component,
    VM1: vm1.component,
};
