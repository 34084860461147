import {
    aguilar,
    audioRack,
    console,
    stompboxes,
    synths,
} from 'shared/components/popups';

export { aguilar, audioRack, console, stompboxes, synths };

export default {
    Aguilar: aguilar.component,
    AudioRack: audioRack.component,
    Console: console.component,
    Stompboxes: stompboxes.component,
    Synths: synths.component,
};
