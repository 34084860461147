import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { Animatable, Hideable } from 'shared/types';
import { fadeIn } from '../../styles';

interface LightningProps extends Animatable, Hideable {}

const grow = keyframes`
    to {
        transform: scale(3, 3) translate(25px, -50px);
    }
`;

const LightningSvg = styled.svg<LightningProps>`
    ${props =>
        props.isAnimating &&
        css`
            animation: ${grow} 700ms linear infinite;
        `}
    left: 417px;
    position: absolute;
    top: 147px;
    width: 110px;
    z-index: 1;

    .cls-1 {
        fill: white;
        opacity: 0.8;
    }

    ${fadeIn}
`;

const Lightning: React.FC<LightningProps> = props => (
    <CSSTransition in={props.isVisible} mountOnEnter timeout={1000}>
        <LightningSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107.53 97.3" {...props}>
            <g id="Layer_2" data-name="Layer 2">
                <g id="microphone_contact_lines" data-name="microphone contact lines">
                    <polygon
                        className="cls-1"
                        points="2.85 4.46 23.24 24.36 6.17 30.26 18.09 61.52 0 31.36 13.92 23.79 2.85 4.46"
                    />
                    <polyline
                        className="cls-1"
                        points="40.51 51.23 40.37 49.16 38.68 25.16 54.88 24.09 62.03 0 56.75 27.72 44.04 29.01"
                    />
                    <polygon
                        className="cls-1"
                        points="57.57 64.07 70.81 49.14 95.14 52.45 107.3 26.93 98.25 58.79 71.46 52.69 57.57 64.07"
                    />
                    <polygon
                        className="cls-1"
                        points="61.67 85.83 78.6 91.72 84.87 77.23 107.53 91.83 88.65 85.35 83.5 97.3 61.67 85.83"
                    />
                </g>
            </g>
        </LightningSvg>
    </CSSTransition>
);

export default Lightning;
