import React from 'react';
import styled from 'styled-components';
import { Paths } from 'shared/constants';
import { Link } from 'react-router-dom';

const Container = styled.div`
    color: white;
    font-family: ${({ theme }) => theme.fonts.main.family};
    text-align: center;
`;

const LinkToHome = styled(Link)`
    color: white;
    text-decoration: underline;
`;

export default function NoMatch() {
    return (
        <Container>
            <h4>Oops! This page doesn't exist.</h4>
            <LinkToHome to={Paths.HOME}>Back to home</LinkToHome>
        </Container>
    );
}
