import styled from 'styled-components';

const Area = styled.area`
    cursor: pointer;
    display: block;
    outline: none;
`;

Area.defaultProps = {
    shape: 'poly',
};

export default Area;
