import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { Animatable, Hideable } from 'shared/types';
import { fadeIn } from '../../styles';

const SubmissionsText1Svg = styled.svg`
    left: 380px;
    position: absolute;
    top: 383px;
    width: 190px;

    .cls-1 {
        opacity: 0.6;
    }

    .cls-2 {
        fill: white;
    }

    .cls-3 {
        opacity: 0.8;
    }

    ${fadeIn}
`;

const SubmissionsText1: React.FC = () => (
    <SubmissionsText1Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 182.83 48.21"
    >
        <g id="Layer_2" data-name="Layer 2">
            <g
                id="text_submissions_shadowy"
                data-name="text submissions shadowy"
            >
                <g className="cls-1">
                    <path
                        className="cls-2"
                        d="M53,2.81l-1.49-.18c-1.47-.16-2,0-2.1.8a1.59,1.59,0,0,0,1.45,1.51,1.62,1.62,0,0,0,.84-.07l.66-.32c.13-.06.16-.14.4-.12.64.07-.17,1.15-2,1A2.45,2.45,0,0,1,48.41,2.5,2.47,2.47,0,0,1,51.31,0a2.3,2.3,0,0,1,2.26,2.44C53.54,2.73,53.38,2.85,53,2.81Zm-2.09-.38.4,0a1.08,1.08,0,0,0,1.21-.74A1.2,1.2,0,0,0,51.31.48c-.9-.1-1.5.3-1.56.88S50.2,2.35,50.91,2.43Z"
                    />
                    <path
                        className="cls-2"
                        d="M56.33,3.72c.34,0,.65-.19.84-.65l.69-1.73a.75.75,0,0,1,.8-.51c.07,0,.4.07.39.36a2.43,2.43,0,0,1-.23.6L57.07,5.4a.81.81,0,0,1-.88.52,1,1,0,0,1-1-.86l-.93-3.87c-.15-.62,0-.81.37-.76.54,0,.7.16.83,1.23C55.64,3,55.77,3.66,56.33,3.72Z"
                    />
                    <path
                        className="cls-2"
                        d="M63.81,4l-1.49-.18c-1.47-.16-2,0-2.1.8A1.74,1.74,0,0,0,62.51,6l.66-.32c.13-.06.16-.15.4-.12.64.07-.17,1.15-2,1a2.46,2.46,0,0,1-2.33-2.88,2.48,2.48,0,0,1,2.9-2.48,2.3,2.3,0,0,1,2.26,2.44C64.35,3.89,64.19,4,63.81,4Zm-2.09-.39.4,0a1.08,1.08,0,0,0,1.21-.74,1.19,1.19,0,0,0-1.21-1.25c-.9-.1-1.49.29-1.56.88S61,3.51,61.72,3.58Z"
                    />
                    <path
                        className="cls-2"
                        d="M67.94,1.94c.19,0,.4.07.37.41s-.21.32-.61.48a2.07,2.07,0,0,0-.84.46,1.65,1.65,0,0,0-.44,1l-.21,1.94c0,.52-.24.76-.55.72-.62-.06-.7-.21-.6-1.16l.37-3.42c0-.52.27-.77.68-.72s1,.29,1.12.3C67.6,2,67.61,1.9,67.94,1.94Z"
                    />
                    <path
                        className="cls-2"
                        d="M68.55,2.44c-.1-.47.22-.55.46-.52.57.06.57.49.6.68l.21,1.62c.09.72.21,1.22.68,1.27.12,0,.57,0,.87-.73s.32-1,.69-1.94a.68.68,0,0,1,.75-.5c.28,0,.52.22.37.57L70.72,8.12a1.7,1.7,0,0,1-1.85,1.13c-.42-.05-.72-.17-.7-.4s.59-.37.8-.46a1,1,0,0,0,.58-.76,3.19,3.19,0,0,0-.12-1.16Z"
                    />
                    <path
                        className="cls-2"
                        d="M74.19,2.06s0,0,.24-.66A.51.51,0,0,1,75,1.07c.24,0,.42.12.46.49a2.23,2.23,0,0,0,.17.84c.11.31.58.43.55.68s-.28.13-.74.67a2.49,2.49,0,0,0-.34,1.09L75,5.92a1.57,1.57,0,0,0,.34,1.27c.18.16.51.42.49.63s-.24.39-.59.35a1.35,1.35,0,0,1-1.38-1.68l.21-2a1.71,1.71,0,0,0-.21-1.15c-.1-.21-.35-.33-.33-.58S74,2.34,74.19,2.06Z"
                    />
                    <path
                        className="cls-2"
                        d="M79,3.27a3.33,3.33,0,0,0,.7,0,2.7,2.7,0,0,1,.58,0c1,.11,1.45.75,1.33,1.9l-.32,2.92c0,.07-.07.45-.35.42-.48-.05-.53-.2-.5-.51L80.65,6c.15-1.45-.24-2.23-1.19-2.33s-1.53.62-1.7,2.18l-.19,1.76c0,.07-.07.45-.36.42-.47-.05-.47-.3-.48-.37a.74.74,0,0,1,0-.21l.66-6.08a.43.43,0,1,1,.84.16C78.11,2.65,78.38,3.21,79,3.27Z"
                    />
                    <path
                        className="cls-2"
                        d="M83.59,8c-.06.52-.25.77-.58.74-.53-.06-.58-.2-.47-1.24l.34-3.21c.06-.55.21-.81.61-.76.57.06.54.31.44,1.28Zm.13-5.91c-.36,0-.46-.17-.44-.35s.15-.28.41-.25.46.12.44.34S83.86,2.11,83.72,2.1Z"
                    />
                    <path
                        className="cls-2"
                        d="M89.55,5.73l-.32,3c0,.46-.27.48-.44.46s-.37-.13-.33-.45l.2-2.08c.18-1.7-.52-2.14-1.23-2.21-.92-.1-1.58.61-1.69,1.71l-.25,2.27a.37.37,0,0,1-.44.36c-.26,0-.35-.15-.31-.51l.43-4a.41.41,0,0,1,.5-.39c.37,0,.79.18,1.27.23a11,11,0,0,1,1.42-.1C89.52,4.22,89.63,5,89.55,5.73Z"
                    />
                    <path
                        className="cls-2"
                        d="M94.17,5A3.83,3.83,0,0,1,95,5c.47,0,.57.27.53.63L95,10.36c-.11,1.07-.81,1.56-2.71,1.36-1.61-.17-2-.79-2-1s.15-.3.34-.28c.47,0,.9,1,1.73,1.08a1.48,1.48,0,0,0,1.75-1.12c.07-.66-.25-1-.94-1.11a4.27,4.27,0,0,0-.52,0c.24,0-.46,0-.65,0-1-.17-1.8-.86-1.63-2.48.16-1.45,1.15-2.4,2.19-2.29a4,4,0,0,1,.88.26A6.7,6.7,0,0,0,94.17,5ZM92.66,9a1.7,1.7,0,0,0,1.85-1.63c.13-1.2-.38-2.26-1.4-2.37a1.7,1.7,0,0,0-1.86,1.7C91.11,8,91.66,8.91,92.66,9Z"
                    />
                    <path
                        className="cls-2"
                        d="M101,5.92c-.09-.47.23-.55.46-.53.57.06.57.5.6.69l.21,1.62c.09.72.21,1.21.68,1.26.12,0,.58,0,.87-.73.23-.59.32-1,.69-1.94a.68.68,0,0,1,.75-.49c.28,0,.52.22.37.57l-2.46,5.22a1.69,1.69,0,0,1-1.85,1.13c-.42,0-.72-.17-.7-.39s.59-.37.8-.47a.94.94,0,0,0,.58-.76,3.3,3.3,0,0,0-.11-1.15Z"
                    />
                    <path
                        className="cls-2"
                        d="M108.42,11.56a2.61,2.61,0,0,1-2.56-3,2.58,2.58,0,0,1,2.89-2.39c2,.22,3,1.26,2.82,3A2.6,2.6,0,0,1,108.42,11.56Zm.07-.49a2,2,0,0,0,2.13-2c.17-1.58-.69-2.32-1.68-2.42a2,2,0,0,0-2.14,1.83C106.64,10,107.31,10.94,108.49,11.07Z"
                    />
                    <path
                        className="cls-2"
                        d="M116.32,7.64c.05-.48.31-.45.45-.43s.34.21.3.64l-.41,3.78a.44.44,0,0,1-.57.44c-.41,0-.68-.18-1.08-.23s-1,.15-1.48.1c-1.06-.12-1.38-.54-1.24-1.85l.31-2.87c0-.32.17-.44.38-.42s.41.2.38.52l-.27,2.46a1.49,1.49,0,0,0,1.27,1.79,1.61,1.61,0,0,0,1.71-1.6Z"
                    />
                    <path
                        className="cls-2"
                        d="M127.6,13a.45.45,0,0,1-.5.45,4.62,4.62,0,0,1-.87-.21,1,1,0,0,0-.5,0,7.26,7.26,0,0,1-.73.11c.07,0,0,0-.19,0h-.24c-1.42-.15-2.28-1.12-2.09-2.93.1-.88.62-2.63,2.52-2.43.38,0,.93.24,1.22.27a.76.76,0,0,0,.8-.32c.52-.84.12-1.62.83-1.54.38,0,.43.25.39.61Zm-2.68-.1c.38,0,1.74-.11,2-2.13.13-1.22-.48-2.24-1.43-2.3-.48,0-1.77-.05-2,2.05C123.31,11.76,123.83,12.73,124.92,12.85Z"
                    />
                    <path
                        className="cls-2"
                        d="M131.29,14a2.61,2.61,0,0,1-2.56-3,2.59,2.59,0,0,1,2.89-2.39c2,.22,3,1.26,2.82,3A2.6,2.6,0,0,1,131.29,14Zm.07-.49a2,2,0,0,0,2.13-1.95c.17-1.58-.69-2.32-1.68-2.42A2,2,0,0,0,129.67,11C129.52,12.43,130.18,13.39,131.36,13.52Z"
                    />
                </g>
                <g className="cls-1">
                    <path
                        className="cls-2"
                        d="M10.42,14.76c-1.35-.37-1.67-1-1.61-1.63.12-1.12,1.36-1.57,2.59-1.44.92.1,1.91.47,1.87.86,0,.12-.13.21-.38.18-.46-.05-.77-.37-1.45-.45s-1.22.24-1.27.73.29.94,1.68,1.39c1.15.37,1.76.78,1.66,1.68s-1.21,1.67-2.64,1.51-2.41-.67-2.37-1.06c0-.22.16-.26.36-.24.44.05.92.59,1.91.73s1.38-.32,1.44-.86S11.8,15.13,10.42,14.76Z"
                    />
                    <path
                        className="cls-2"
                        d="M16.77,12.63a3.3,3.3,0,0,0,.75,0,4.35,4.35,0,0,1,.63,0c1.09.12,1.56.81,1.43,2.05l-.34,3.15c0,.07-.08.48-.38.45-.51-.06-.57-.21-.54-.55l.22-2.07c.17-1.56-.26-2.4-1.28-2.51s-1.64.66-1.82,2.35l-.21,1.9c0,.07-.07.48-.38.45-.51-.06-.51-.33-.53-.4a.89.89,0,0,1,0-.23l.7-6.55a.46.46,0,1,1,.91.17C15.81,12,16.11,12.56,16.77,12.63Z"
                    />
                    <path
                        className="cls-2"
                        d="M23.12,18.84a2.81,2.81,0,0,1-2.76-3.22A2.79,2.79,0,0,1,23.48,13c2.15.23,3.24,1.36,3,3.26A2.8,2.8,0,0,1,23.12,18.84Zm.08-.53a2.12,2.12,0,0,0,2.29-2.09c.18-1.71-.74-2.5-1.81-2.61a2.1,2.1,0,0,0-2.3,2C21.21,17.14,21.92,18.17,23.2,18.31Z"
                    />
                    <path
                        className="cls-2"
                        d="M31.63,14.62c.05-.51.34-.48.49-.47s.36.24.31.7L32,18.92a.49.49,0,0,1-.62.48c-.44,0-.73-.21-1.17-.25s-1.1.15-1.58.1c-1.15-.12-1.49-.58-1.34-2l.33-3.1c0-.34.18-.47.41-.44s.44.21.41.56l-.29,2.65a1.59,1.59,0,0,0,1.37,1.92,1.73,1.73,0,0,0,1.84-1.72Z"
                    />
                    <path
                        className="cls-2"
                        d="M34.41,19.38a.54.54,0,0,1-.67.54c-.62-.07-.67-.27-.54-1.54l.59-5.48c.07-.66.21-1,.74-.93s.64.34.59.83Z"
                    />
                    <path
                        className="cls-2"
                        d="M41,20a.49.49,0,0,1-.54.49,5.61,5.61,0,0,1-.93-.23,1,1,0,0,0-.54-.05l-.79.11c.08,0,0,0-.21,0a1,1,0,0,1-.25,0c-1.54-.16-2.46-1.2-2.25-3.15.1-.95.66-2.83,2.71-2.61.41,0,1,.25,1.31.29a.81.81,0,0,0,.86-.36c.57-.9.14-1.73.9-1.65.41,0,.47.27.42.66Zm-2.88-.11c.41,0,1.87-.12,2.1-2.29.14-1.31-.51-2.42-1.54-2.48-.51,0-1.9-.05-2.15,2.21C36.36,18.76,36.92,19.8,38.1,19.93Z"
                    />
                    <path
                        className="cls-2"
                        d="M47.46,19.25,48,14.18a.48.48,0,1,1,1,.08c-.15,1.34.12,1.76.94,1.85A11.91,11.91,0,0,1,51.16,16c1.48.16,2.27,1.26,2.08,3S52,21.9,50.65,21.76c-.82-.09-1.22-.41-1.78-.49s-.4.1-1,0S47.25,21.15,47.46,19.25Zm2.65,2a2.09,2.09,0,0,0,2.14-2.13c.18-1.68-.55-2.57-1.6-2.69-1.27-.13-2,.92-2.11,2.14C48.36,20.25,49.14,21.15,50.11,21.25Z"
                    />
                    <path
                        className="cls-2"
                        d="M58.82,19.64l-1.61-.2c-1.58-.17-2.17,0-2.26.87a1.71,1.71,0,0,0,1.56,1.62,1.64,1.64,0,0,0,.91-.08l.71-.34c.13-.06.17-.15.43-.13.69.08-.19,1.24-2.18,1a2.66,2.66,0,0,1-2.51-3.11A2.67,2.67,0,0,1,57,16.63a2.47,2.47,0,0,1,2.43,2.63C59.4,19.55,59.23,19.68,58.82,19.64Zm-2.25-.42.43.05a1.15,1.15,0,0,0,1.3-.8A1.28,1.28,0,0,0,57,17.13c-1-.11-1.61.32-1.68.95S55.8,19.14,56.57,19.22Z"
                    />
                    <path
                        className="cls-2"
                        d="M69.32,18.36a3.61,3.61,0,0,1,.85,0c.51.06.61.29.57.68l-.55,5.11c-.12,1.15-.87,1.68-2.92,1.47-1.73-.19-2.15-.85-2.13-1.05s.16-.32.37-.3c.51,0,1,1.06,1.86,1.16a1.6,1.6,0,0,0,1.89-1.2,1,1,0,0,0-1-1.2,5.16,5.16,0,0,0-.57,0c.26,0-.5,0-.7,0-1.06-.19-1.94-.92-1.75-2.67.17-1.56,1.23-2.58,2.35-2.46a5,5,0,0,1,1,.27A5,5,0,0,0,69.32,18.36Zm-1.63,4.33a1.83,1.83,0,0,0,2-1.75c.14-1.29-.41-2.44-1.5-2.56a1.85,1.85,0,0,0-2,1.84C66,21.63,66.62,22.58,67.69,22.69Z"
                    />
                    <path
                        className="cls-2"
                        d="M76.4,21.52l-1.6-.2c-1.59-.17-2.18,0-2.27.87a1.71,1.71,0,0,0,1.56,1.62,1.75,1.75,0,0,0,.91-.07l.71-.35c.14-.06.17-.15.43-.12.69.07-.19,1.23-2.18,1a2.66,2.66,0,0,1-2.51-3.1c.2-1.81,1.29-2.87,3.13-2.67A2.46,2.46,0,0,1,77,21.14C77,21.43,76.81,21.56,76.4,21.52Zm-2.25-.41.43,0a1.14,1.14,0,0,0,1.3-.79A1.29,1.29,0,0,0,74.58,19c-1-.11-1.61.32-1.68.95S73.38,21,74.15,21.11Z"
                    />
                    <path
                        className="cls-2"
                        d="M77.38,23.2c.11-1,1.12-1.46,2.31-1.48s1.93-.41,2-.92a1.24,1.24,0,0,0-1.09-1.39l-.2,0c-.74-.08-1.15.62-1.72.56-.15,0-.31,0-.28-.2.05-.49,1.1-.7,2-.6,2.71.29,2.4,1.22,2.15,3.58l-.19,1.78a.43.43,0,0,1-.51.44c-.26,0-.7-.25-1.11-.29-.76-.09-1,.23-1.82.15A1.53,1.53,0,0,1,77.38,23.2Zm2.15,1.19a1.68,1.68,0,0,0,2-1.36,1.07,1.07,0,0,0-1-1.15c-.85-.09-1.94.48-2,1.26A1.19,1.19,0,0,0,79.53,24.39Z"
                    />
                    <path
                        className="cls-2"
                        d="M86.77,20c.21,0,.43.07.39.44s-.21.34-.65.52a2.21,2.21,0,0,0-.9.49,1.7,1.7,0,0,0-.47,1l-.23,2.09c-.06.56-.27.81-.6.77-.66-.07-.75-.22-.64-1.25l.4-3.68c.06-.56.29-.83.73-.78s1.07.31,1.2.33C86.41,20,86.41,19.93,86.77,20Z"
                    />
                    <path
                        className="cls-2"
                        d="M92.26,23.22,90.65,23c-1.58-.17-2.17,0-2.26.87A1.7,1.7,0,0,0,90,25.51a1.64,1.64,0,0,0,.91-.08l.71-.34c.13-.06.17-.15.43-.13.69.08-.19,1.24-2.18,1a2.66,2.66,0,0,1-2.51-3.1,2.67,2.67,0,0,1,3.13-2.68,2.47,2.47,0,0,1,2.43,2.63C92.84,23.13,92.67,23.26,92.26,23.22ZM90,22.8l.43.05a1.15,1.15,0,0,0,1.3-.8,1.29,1.29,0,0,0-1.3-1.34c-1-.11-1.61.32-1.68.95S89.24,22.72,90,22.8Z"
                    />
                    <path
                        className="cls-2"
                        d="M99,26.26a.48.48,0,0,1-.54.48,5.43,5.43,0,0,1-.93-.22,1.09,1.09,0,0,0-.54-.06,6.27,6.27,0,0,1-.79.11c.08,0,0,0-.21,0h-.25c-1.54-.17-2.46-1.2-2.25-3.15.1-.95.66-2.84,2.71-2.62.41.05,1,.26,1.31.29a.81.81,0,0,0,.86-.35c.57-.9.14-1.74.9-1.65.41,0,.47.27.42.66Zm-2.88-.11c.41,0,1.87-.12,2.1-2.29.14-1.32-.51-2.42-1.54-2.48-.51,0-1.9-.06-2.15,2.21C94.38,25,94.94,26,96.12,26.15Z"
                    />
                    <path
                        className="cls-2"
                        d="M106.16,21.46c.06-.05,0,0,.26-.72a.56.56,0,0,1,.63-.35.49.49,0,0,1,.49.52,2.29,2.29,0,0,0,.19.91c.12.33.62.46.59.73s-.3.14-.8.73a2.64,2.64,0,0,0-.36,1.17L107,25.61a1.63,1.63,0,0,0,.37,1.37c.19.17.54.46.52.68s-.25.41-.64.37a1.46,1.46,0,0,1-1.48-1.81L106,24.1a1.82,1.82,0,0,0-.23-1.23c-.11-.23-.38-.36-.35-.63S106,21.76,106.16,21.46Z"
                    />
                    <path
                        className="cls-2"
                        d="M111.63,28.32a2.91,2.91,0,1,1,.36-5.8c2.15.23,3.24,1.36,3,3.26A2.8,2.8,0,0,1,111.63,28.32Zm.08-.53A2.12,2.12,0,0,0,114,25.7c.19-1.71-.74-2.5-1.81-2.61a2.1,2.1,0,0,0-2.3,2C109.72,26.62,110.44,27.65,111.71,27.79Z"
                    />
                    <path
                        className="cls-2"
                        d="M120.45,27.46c-.14-1.1-.32-1.54-.89-1.6a.75.75,0,0,0-.37.08,1.11,1.11,0,0,0-.56.71L118,28.3a.75.75,0,0,1-.72.44c-.41,0-.67-.24-.79-.85l-.79-4c-.1-.51-.07-.75.36-.7s.43.36.46.74l.15,1.49c.09.9.44,1.21.7,1.23.51.06.87-.22,1.1-.86l.63-1.81a.66.66,0,0,1,.75-.46c.56.06.72.25.78,1.09l.14,1.42c0,.67.37,1.05.76,1.09s.74-.21,1-.92l.71-1.87a.58.58,0,0,1,.53-.34c.26,0,.31.21.3.3a2.38,2.38,0,0,1-.29.79l-1.72,3.56a.89.89,0,0,1-.83.5C120.64,29.1,120.62,28.76,120.45,27.46Z"
                    />
                    <path
                        className="cls-2"
                        d="M123.87,28.18c.11-1,1.12-1.46,2.31-1.48s1.93-.41,2-.92a1.24,1.24,0,0,0-1.09-1.4l-.2,0c-.74-.08-1.15.62-1.72.56-.15,0-.31,0-.28-.2,0-.49,1.1-.7,2-.6,2.71.29,2.4,1.22,2.15,3.58l-.19,1.78a.43.43,0,0,1-.51.44c-.26,0-.7-.25-1.11-.29-.76-.09-1,.23-1.82.15A1.53,1.53,0,0,1,123.87,28.18ZM126,29.37A1.68,1.68,0,0,0,128,28a1.06,1.06,0,0,0-1-1.15c-.85-.09-1.94.48-2,1.26A1.19,1.19,0,0,0,126,29.37Z"
                    />
                    <path
                        className="cls-2"
                        d="M133.26,25c.21,0,.43.07.39.44s-.21.34-.65.52a2.21,2.21,0,0,0-.9.49,1.7,1.7,0,0,0-.47,1l-.23,2.09c-.06.56-.27.81-.6.77-.66-.07-.75-.22-.64-1.25l.4-3.68c.05-.56.29-.83.72-.78a12.49,12.49,0,0,1,1.21.33C132.9,25,132.9,24.91,133.26,25Z"
                    />
                    <path
                        className="cls-2"
                        d="M139.32,30.58a.48.48,0,0,1-.54.48,6.92,6.92,0,0,1-.93-.22,1,1,0,0,0-.54-.06c.05,0-.73.12-.78.11s0,0-.21,0h-.26c-1.53-.17-2.46-1.2-2.25-3.15.1-.95.67-2.84,2.71-2.62.41,0,1,.26,1.31.29a.83.83,0,0,0,.87-.35c.56-.9.13-1.74.9-1.66.41.05.46.28.42.67Zm-2.88-.12c.41.05,1.87-.12,2.11-2.28.14-1.32-.52-2.42-1.55-2.48-.51,0-1.9-.06-2.14,2.2C134.71,29.29,135.27,30.34,136.44,30.46Z"
                    />
                </g>
                <g className="cls-1">
                    <path
                        className="cls-2"
                        d="M18.86,36.64c0,.15-.21.55-.52.52s-.51-.21-.47-.62L18.14,34c.2-1.83-.34-2.94-1.71-3.08-1.86-.2-2.14,2.16-2.19,2.63L14,36.12c0,.15-.22.57-.53.54s-.5-.23-.46-.65l.29-2.69c.19-1.8-.36-2.79-1.69-2.93s-2,.77-2.23,2.6l-.28,2.6c0,.15-.21.58-.52.52s-.43-.11-.45-.47a19.27,19.27,0,0,1,.18-2.26L8.5,31.2c.14-1.31.29-1.5.84-1.44.19,0,.12,0,.8.17-.19,0,.43.11.49.12A5.09,5.09,0,0,0,11.8,30a5.46,5.46,0,0,1,.88,0L13,30a1.28,1.28,0,0,1,.55.18l.68.37a2.09,2.09,0,0,0,.82.27,1.92,1.92,0,0,0,.75,0l.75-.25a2.61,2.61,0,0,1,1.07-.09c1.31.14,1.84.76,1.7,2.06Z"
                    />
                    <path
                        className="cls-2"
                        d="M19.9,35.89c.13-1.22,1.35-1.77,2.8-1.8S25,33.59,25.11,33a1.51,1.51,0,0,0-1.33-1.69l-.25,0c-.9-.1-1.39.75-2.08.67-.18,0-.37,0-.35-.24.07-.59,1.35-.85,2.47-.73,3.28.36,2.91,1.48,2.6,4.35l-.23,2.16c0,.39-.25.57-.62.53s-.85-.3-1.34-.35c-.93-.1-1.22.29-2.22.18A1.85,1.85,0,0,1,19.9,35.89Zm2.61,1.44a2.06,2.06,0,0,0,2.37-1.66,1.3,1.3,0,0,0-1.26-1.39c-1-.11-2.36.59-2.46,1.53A1.44,1.44,0,0,0,22.51,37.33Z"
                    />
                    <path
                        className="cls-2"
                        d="M33.25,32.22s.34,0,.32.21-.22.33-.4.5c-.77.72-1.15,1-1.21,1.6a1.73,1.73,0,0,0,.12,1l1.28,2.68c.2.44.14.73-.23.69-.9-.09-1-.67-1.15-1l-.55-1a1.69,1.69,0,0,0-1.25-1,1.57,1.57,0,0,0-1.53,1.48c-.08.68-.39.91-.66.88-.44,0-.62-.36-.56-.95l.81-7.61c0-.26.17-.7.61-.65s.62.31.57.78l-.24,2.31a1.26,1.26,0,0,0,.9,1.47A2.44,2.44,0,0,0,31.6,33C32.62,32.18,32.75,32.16,33.25,32.22Z"
                    />
                    <path
                        className="cls-2"
                        d="M35.78,38.19c-.08.68-.33,1-.77,1-.68-.07-.75-.26-.61-1.62l.45-4.2c.08-.71.27-1,.8-1,.74.08.71.4.57,1.67ZM36,30.45c-.47-.05-.61-.21-.58-.45s.19-.36.53-.33.61.16.58.45S36.13,30.47,36,30.45Z"
                    />
                    <path
                        className="cls-2"
                        d="M43.57,35.21l-.42,4c-.07.59-.35.62-.57.6s-.48-.17-.44-.59l.26-2.72c.24-2.22-.67-2.8-1.6-2.9-1.21-.13-2.06.8-2.22,2.25l-.31,3a.5.5,0,0,1-.59.47c-.34,0-.45-.2-.4-.67l.56-5.2a.53.53,0,0,1,.65-.5c.5.05,1,.23,1.66.29A13.49,13.49,0,0,1,42,33.07C43.54,33.23,43.68,34.23,43.57,35.21Z"
                    />
                    <path
                        className="cls-2"
                        d="M49.61,34.24a3.93,3.93,0,0,1,1,0c.62.06.75.35.7.82l-.67,6.21c-.15,1.39-1.06,2-3.54,1.78-2.11-.23-2.62-1-2.6-1.27s.2-.4.45-.37c.62.07,1.18,1.29,2.27,1.41a1.91,1.91,0,0,0,2.28-1.46,1.19,1.19,0,0,0-1.22-1.45s-.25,0-.69,0c.31,0-.6,0-.85,0-1.29-.23-2.35-1.12-2.13-3.25.21-1.9,1.5-3.13,2.87-3a5.5,5.5,0,0,1,1.15.34A8.13,8.13,0,0,0,49.61,34.24Zm-2,5.26a2.24,2.24,0,0,0,2.43-2.13c.16-1.57-.5-3-1.84-3.1a2.22,2.22,0,0,0-2.43,2.22C45.61,38.21,46.33,39.36,47.63,39.5Z"
                    />
                    <path
                        className="cls-2"
                        d="M59.45,34.26c.07,0,0,0,.31-.86a.67.67,0,0,1,.77-.43c.31,0,.55.15.59.63a3,3,0,0,0,.23,1.1c.14.41.76.56.72.89s-.36.17-1,.88a3.39,3.39,0,0,0-.44,1.42l-.15,1.42A2,2,0,0,0,61,41c.23.21.66.55.63.82s-.3.51-.77.46a1.77,1.77,0,0,1-1.8-2.2l.28-2.58A2.17,2.17,0,0,0,59,36c-.13-.28-.46-.44-.42-.76S59.23,34.62,59.45,34.26Z"
                    />
                    <path
                        className="cls-2"
                        d="M65.74,35.85a4.37,4.37,0,0,0,.92,0,4.54,4.54,0,0,1,.75,0c1.34.14,1.91,1,1.74,2.49l-.4,3.81c0,.09-.1.59-.47.55-.62-.07-.7-.25-.65-.67l.27-2.51c.2-1.9-.32-2.91-1.56-3s-2,.8-2.22,2.84l-.24,2.31c0,.09-.1.59-.47.55-.62-.07-.62-.4-.64-.49a.82.82,0,0,1,0-.27l.85-8a.57.57,0,1,1,1.11.21C64.58,35,64.94,35.76,65.74,35.85Z"
                    />
                    <path
                        className="cls-2"
                        d="M76.09,40l-2-.24c-1.92-.2-2.64,0-2.75,1a2.07,2.07,0,0,0,1.89,2,2,2,0,0,0,1.11-.09l.86-.42c.16-.07.21-.18.52-.15.84.09-.22,1.5-2.64,1.24a3.22,3.22,0,0,1-3-3.76c.23-2.19,1.56-3.49,3.8-3.25a3,3,0,0,1,3,3.19C76.79,39.89,76.59,40,76.09,40Zm-2.74-.5.53,0a1.4,1.4,0,0,0,1.58-1,1.57,1.57,0,0,0-1.58-1.64c-1.18-.12-2,.39-2,1.16S72.42,39.39,73.35,39.49Z"
                    />
                    <path
                        className="cls-2"
                        d="M94.59,44.75c0,.15-.22.55-.53.52s-.51-.21-.47-.62l.27-2.52c.2-1.83-.34-2.93-1.7-3.08-1.86-.2-2.15,2.16-2.2,2.64l-.27,2.54c0,.15-.22.57-.53.54s-.51-.23-.46-.65L89,41.43c.2-1.8-.36-2.79-1.69-2.93s-2,.77-2.22,2.6l-.28,2.6c0,.15-.22.58-.53.52s-.43-.11-.45-.47c0-.15,0-.92.18-2.26l.23-2.18c.14-1.31.29-1.5.85-1.44.18,0,.12,0,.79.18-.18,0,.43.1.49.11a5.09,5.09,0,0,0,1.17-.06,5.46,5.46,0,0,1,.88-.05l.31,0a1.28,1.28,0,0,1,.55.18l.68.37a2.14,2.14,0,0,0,.82.27,1.92,1.92,0,0,0,.75,0l.75-.25a2.67,2.67,0,0,1,1.08-.09c1.3.14,1.83.76,1.69,2.06Z"
                    />
                    <path
                        className="cls-2"
                        d="M101.35,40.14c.07-.62.41-.59.6-.57.34,0,.44.29.38.85l-.53,4.94c0,.45-.29.63-.75.58s-.89-.24-1.41-.3-1.34.18-1.93.12c-1.4-.15-1.81-.7-1.62-2.42l.4-3.75c0-.42.22-.58.5-.55s.53.27.49.68L97.13,43a1.93,1.93,0,0,0,1.67,2.33A2.11,2.11,0,0,0,101,43.19Z"
                    />
                    <path
                        className="cls-2"
                        d="M105.46,43.51c-1.64-.44-2-1.26-1.95-2,.15-1.36,1.65-1.92,3.14-1.76,1.11.12,2.32.58,2.27,1,0,.15-.15.25-.47.22-.55-.06-.92-.46-1.76-.55s-1.47.29-1.53.88.34,1.15,2,1.69c1.4.45,2.13.94,2,2s-1.47,2-3.21,1.84-2.92-.82-2.87-1.3c0-.26.19-.3.44-.28.53.06,1.11.72,2.32.88s1.67-.39,1.74-1S107.14,44,105.46,43.51Z"
                    />
                    <path
                        className="cls-2"
                        d="M111.81,46.33c-.07.68-.33,1-.76,1-.68-.08-.76-.27-.61-1.63l.45-4.2c.07-.71.27-1,.79-1,.75.08.71.41.58,1.68ZM112,38.6c-.47-.05-.61-.22-.58-.45s.2-.37.54-.33.61.15.58.45S112.17,38.62,112,38.6Z"
                    />
                    <path
                        className="cls-2"
                        d="M119.23,42.67c-.34,0-.9-.7-1.74-.79A2.33,2.33,0,0,0,115,44.25c-.15,1.42.49,2.74,1.92,2.9,1.15.12,1.49-.47,2.11-.4.31,0,.45.16.43.43s-1.29,1.18-2.9,1a3.42,3.42,0,0,1-3.27-4.06,3.54,3.54,0,0,1,4.06-3.34c1.46.16,2.66.88,2.6,1.48C119.92,42.62,119.66,42.71,119.23,42.67Z"
                    />
                </g>
            </g>
            <g id="text_submissions" data-name="text submissions">
                <g className="cls-3">
                    <path d="M25.33,4.47l-1.6-.2c-1.59-.17-2.18,0-2.27.87A1.7,1.7,0,0,0,23,6.76a1.64,1.64,0,0,0,.91-.08l.71-.34c.14-.06.17-.15.43-.13.69.08-.19,1.24-2.18,1a2.66,2.66,0,0,1-2.51-3.1,2.67,2.67,0,0,1,3.13-2.68,2.47,2.47,0,0,1,2.43,2.63C25.91,4.38,25.74,4.51,25.33,4.47Zm-2.25-.42.43,0a1.15,1.15,0,0,0,1.3-.8A1.28,1.28,0,0,0,23.51,2c-1-.11-1.61.32-1.68,1S22.31,4,23.08,4.05Z" />
                    <path d="M28.92,5.44c.36,0,.69-.19.9-.69l.74-1.86a.81.81,0,0,1,.86-.55c.08,0,.43.07.43.39a3,3,0,0,1-.25.64L29.71,7.25a.87.87,0,0,1-.94.57,1,1,0,0,1-1.06-.93l-1-4.17c-.17-.66,0-.86.39-.82.59.06.76.18.9,1.33C28.17,4.7,28.31,5.38,28.92,5.44Z" />
                    <path d="M37,5.72l-1.61-.2c-1.58-.17-2.17,0-2.26.87A1.71,1.71,0,0,0,34.66,8a1.79,1.79,0,0,0,.92-.08l.7-.34c.14-.06.18-.16.43-.13.69.08-.18,1.24-2.17,1A2.66,2.66,0,0,1,32,5.38a2.66,2.66,0,0,1,3.12-2.67,2.46,2.46,0,0,1,2.43,2.63C37.55,5.63,37.38,5.76,37,5.72ZM34.72,5.3l.44,0a1.14,1.14,0,0,0,1.29-.8,1.29,1.29,0,0,0-1.3-1.35c-1-.1-1.61.33-1.68,1S34,5.22,34.72,5.3Z" />
                    <path d="M41.42,3.53c.2,0,.43.07.39.44s-.22.34-.65.52a2.21,2.21,0,0,0-.9.49,1.8,1.8,0,0,0-.48,1l-.22,2.09c-.06.56-.27.81-.6.78-.66-.08-.75-.23-.64-1.26L38.71,4c.06-.56.3-.83.73-.79a12.49,12.49,0,0,1,1.21.33C41.06,3.54,41.06,3.49,41.42,3.53Z" />
                    <path d="M42.07,4.07c-.1-.5.25-.59.5-.56.62.06.62.53.65.73L43.44,6c.1.77.22,1.3.74,1.36.12,0,.61,0,.93-.79.25-.64.35-1,.74-2.09a.74.74,0,0,1,.81-.53c.3,0,.57.24.4.61l-2.65,5.63a1.82,1.82,0,0,1-2,1.22c-.46-.05-.78-.18-.75-.43s.64-.4.85-.5a1,1,0,0,0,.63-.82A3.4,3.4,0,0,0,43,8.41Z" />
                    <path d="M48.15,3.66c.05,0,0,0,.26-.71A.55.55,0,0,1,49,2.6c.25,0,.45.12.48.52a2.49,2.49,0,0,0,.19.9c.12.34.62.47.59.73s-.3.14-.8.73a2.66,2.66,0,0,0-.35,1.17L49,7.82a1.71,1.71,0,0,0,.37,1.37c.19.17.55.45.52.67s-.25.42-.63.38a1.46,1.46,0,0,1-1.49-1.81L48,6.31a1.8,1.8,0,0,0-.23-1.23c-.1-.24-.37-.36-.35-.63S48,4,48.15,3.66Z" />
                    <path d="M53.33,5a4,4,0,0,0,.75,0,3.11,3.11,0,0,1,.62,0c1.1.11,1.57.81,1.44,2.05l-.34,3.14c0,.07-.08.48-.38.45-.51-.05-.58-.21-.54-.55l.22-2.07c.17-1.56-.26-2.39-1.28-2.5S52.17,6.1,52,7.78l-.2,1.9c0,.07-.08.49-.38.45-.51-.05-.51-.32-.53-.4a.54.54,0,0,1,0-.22L51.58,3a.46.46,0,1,1,.91.17C52.37,4.3,52.67,4.9,53.33,5Z" />
                    <path d="M58.27,10.07c-.06.56-.27.83-.63.79-.56-.06-.62-.21-.5-1.33l.37-3.46c.06-.59.22-.87.66-.82.61.07.58.33.47,1.38Zm.14-6.37c-.38,0-.5-.18-.48-.37s.17-.3.45-.27.5.12.47.37S58.56,3.72,58.41,3.7Z" />
                    <path d="M64.7,7.61l-.36,3.29c0,.49-.28.51-.46.49s-.4-.14-.37-.48l.22-2.24c.2-1.83-.55-2.3-1.32-2.39-1-.1-1.7.66-1.83,1.85l-.26,2.44a.41.41,0,0,1-.48.39c-.28,0-.37-.16-.33-.55L60,6.12a.45.45,0,0,1,.54-.41c.41,0,.86.19,1.37.25a9.31,9.31,0,0,1,1.53-.11C64.66,6,64.78,6.81,64.7,7.61Z" />
                    <path d="M69.66,6.81a5.18,5.18,0,0,1,.86,0c.51.05.61.29.57.68l-.55,5.11c-.12,1.15-.88,1.68-2.92,1.46-1.74-.18-2.16-.85-2.14-1s.17-.33.37-.31c.51.06,1,1.07,1.87,1.17a1.58,1.58,0,0,0,1.88-1.21,1,1,0,0,0-1-1.19,4.89,4.89,0,0,0-.56,0c.25,0-.5,0-.7,0-1.07-.19-1.94-.92-1.75-2.68.16-1.56,1.23-2.58,2.35-2.46a4.27,4.27,0,0,1,.95.28A5.16,5.16,0,0,0,69.66,6.81ZM68,11.15a1.83,1.83,0,0,0,2-1.76c.14-1.29-.41-2.43-1.51-2.55a1.83,1.83,0,0,0-2,1.83C66.37,10.08,67,11,68,11.15Z" />
                    <path d="M77,7.81c-.1-.5.24-.59.5-.56.61.07.61.53.64.73l.23,1.75c.1.78.22,1.31.73,1.36.13,0,.62,0,.94-.78.25-.64.34-1.05.74-2.09a.73.73,0,0,1,.81-.53c.3,0,.56.23.4.61l-2.65,5.63a1.81,1.81,0,0,1-2,1.21c-.46-.05-.78-.18-.75-.42s.63-.4.85-.5a1,1,0,0,0,.63-.82A4,4,0,0,0,78,12.15Z" />
                    <path d="M85,13.89a2.8,2.8,0,0,1-2.75-3.23,2.77,2.77,0,0,1,3.11-2.57c2.15.23,3.24,1.36,3,3.26A2.81,2.81,0,0,1,85,13.89Zm.08-.53a2.13,2.13,0,0,0,2.29-2.1,2.08,2.08,0,0,0-1.81-2.61,2.11,2.11,0,0,0-2.3,2C83.1,12.18,83.82,13.22,85.09,13.36Z" />
                    <path d="M93.52,9.67c.06-.52.34-.49.49-.47s.36.24.31.7L93.89,14c0,.36-.24.51-.62.47s-.73-.2-1.16-.24-1.11.15-1.59.1c-1.15-.13-1.49-.58-1.34-2l.33-3.1c0-.34.18-.47.41-.45s.44.22.41.56L90,12a1.6,1.6,0,0,0,1.37,1.92,1.73,1.73,0,0,0,1.84-1.72Z" />
                    <path d="M105.67,15.39a.48.48,0,0,1-.55.48,4.78,4.78,0,0,1-.93-.22,1,1,0,0,0-.53-.06s-.74.12-.79.12,0,0-.21,0a.75.75,0,0,1-.26,0c-1.53-.16-2.45-1.2-2.24-3.15.1-.95.66-2.83,2.7-2.62.41,0,1,.26,1.32.29A.79.79,0,0,0,105,9.9c.56-.9.14-1.73.9-1.65.41,0,.46.27.42.66Zm-2.88-.11c.41,0,1.87-.12,2.1-2.29.14-1.31-.51-2.42-1.54-2.48-.51,0-1.91-.06-2.15,2.21C101.05,14.11,101.61,15.15,102.79,15.28Z" />
                    <path d="M109.64,16.53a2.8,2.8,0,0,1-2.75-3.23A2.77,2.77,0,0,1,110,10.73c2.15.23,3.24,1.36,3,3.26A2.81,2.81,0,0,1,109.64,16.53Zm.08-.53A2.13,2.13,0,0,0,112,13.9a2.08,2.08,0,0,0-1.81-2.61,2.1,2.1,0,0,0-2.3,2C107.73,14.82,108.45,15.86,109.72,16Z" />
                </g>
                <g className="cls-3">
                    <path d="M53.23,19.2c-1.36-.36-1.67-1-1.61-1.62C51.74,16.46,53,16,54.2,16.13c.92.1,1.91.48,1.87.87,0,.12-.12.2-.38.18-.46-.05-.76-.38-1.45-.45S53,17,53,17.45s.28.94,1.68,1.39c1.15.37,1.75.78,1.66,1.68S55.1,22.19,53.67,22s-2.4-.68-2.36-1.07c0-.22.15-.25.36-.23.43,0,.92.59,1.91.72S55,21.14,55,20.6,54.61,19.57,53.23,19.2Z" />
                    <path d="M59.58,17.08a5.18,5.18,0,0,0,.75,0A3.11,3.11,0,0,1,61,17c1.1.11,1.57.8,1.44,2l-.34,3.14c0,.07-.08.48-.39.45-.51-.05-.57-.21-.53-.55l.22-2.07c.17-1.56-.26-2.39-1.28-2.5s-1.65.66-1.83,2.34l-.2,1.9c0,.07-.08.48-.38.45-.52,0-.51-.32-.53-.4a.54.54,0,0,1,0-.22l.7-6.55a.46.46,0,1,1,.91.17C58.62,16.41,58.91,17,59.58,17.08Z" />
                    <path d="M65.93,23.29a2.81,2.81,0,0,1-2.76-3.23,2.77,2.77,0,0,1,3.12-2.57c2.14.23,3.24,1.35,3,3.25A2.8,2.8,0,0,1,65.93,23.29Zm.08-.54a2.12,2.12,0,0,0,2.29-2.09c.18-1.71-.74-2.5-1.81-2.61a2.11,2.11,0,0,0-2.31,2C64,21.58,64.73,22.62,66,22.75Z" />
                    <path d="M74.44,19.06c0-.51.33-.48.49-.46s.36.23.31.7l-.44,4.06c0,.37-.23.52-.62.48s-.72-.2-1.16-.25-1.1.15-1.59.1c-1.14-.12-1.48-.58-1.33-2l.33-3.09c0-.34.18-.48.41-.45s.44.22.4.56L71,21.37a1.59,1.59,0,0,0,1.37,1.93,1.75,1.75,0,0,0,1.84-1.73Z" />
                    <path d="M77.22,23.82a.54.54,0,0,1-.68.54c-.61-.06-.67-.27-.53-1.53l.59-5.48c.07-.66.2-1,.74-.93s.64.33.58.82Z" />
                    <path d="M83.79,24.49a.48.48,0,0,1-.55.48,6.92,6.92,0,0,1-.93-.22c-.3-.09-.12-.07-.53-.06,0,0-.74.12-.79.11s0,0-.21,0a2.25,2.25,0,0,1-.26,0c-1.53-.17-2.45-1.2-2.24-3.15.1-.95.66-2.84,2.7-2.62.41,0,1,.26,1.32.29a.81.81,0,0,0,.86-.35c.56-.9.13-1.74.9-1.66.41.05.46.28.42.67Zm-2.88-.12c.4.05,1.87-.12,2.1-2.28.14-1.32-.52-2.42-1.54-2.48-.52,0-1.91-.06-2.15,2.2C79.17,23.2,79.73,24.25,80.91,24.37Z" />
                    <path d="M90.26,23.69l.55-5.07a.48.48,0,1,1,1,.08c-.15,1.34.12,1.76.93,1.85A12.7,12.7,0,0,1,94,20.49c1.48.16,2.26,1.25,2.08,3s-1.24,2.89-2.59,2.75a15.69,15.69,0,0,1-1.78-.49c-.62-.06-.4.11-1,0S90.06,25.59,90.26,23.69Zm2.66,2a2.1,2.1,0,0,0,2.14-2.14c.18-1.68-.55-2.57-1.6-2.68-1.28-.14-2,.92-2.12,2.14C91.16,24.7,92,25.59,92.92,25.7Z" />
                    <path d="M101.63,24.08l-1.61-.2c-1.58-.17-2.17,0-2.26.87a1.7,1.7,0,0,0,1.56,1.62,1.77,1.77,0,0,0,.91-.07l.71-.35c.13-.06.17-.15.42-.12.69.07-.18,1.23-2.17,1a2.65,2.65,0,0,1-2.51-3.1,2.65,2.65,0,0,1,3.12-2.67,2.47,2.47,0,0,1,2.44,2.62C102.2,24,102,24.12,101.63,24.08Zm-2.26-.41.44,0a1.16,1.16,0,0,0,1.3-.79,1.3,1.3,0,0,0-1.31-1.35c-1-.1-1.61.32-1.67.95S98.61,23.58,99.37,23.67Z" />
                    <path d="M112.12,22.8a3.46,3.46,0,0,1,.86,0c.51.05.61.28.57.67L113,28.59c-.12,1.14-.88,1.68-2.92,1.46-1.74-.19-2.16-.85-2.14-1s.17-.33.37-.31c.51.06,1,1.07,1.87,1.16a1.57,1.57,0,0,0,1.88-1.2c.08-.71-.27-1.11-1-1.19a4.89,4.89,0,0,0-.56,0c.25,0-.5,0-.7,0-1.07-.19-1.94-.93-1.76-2.68.17-1.56,1.24-2.58,2.36-2.46a4.27,4.27,0,0,1,1,.28A5.16,5.16,0,0,0,112.12,22.8Zm-1.63,4.34a1.84,1.84,0,0,0,2-1.76c.14-1.29-.41-2.43-1.51-2.55a1.83,1.83,0,0,0-2,1.83C108.83,26.07,109.42,27,110.49,27.14Z" />
                    <path d="M119.21,26l-1.61-.19c-1.58-.17-2.17,0-2.26.86a1.7,1.7,0,0,0,1.56,1.62,1.65,1.65,0,0,0,.91-.07l.71-.34c.13-.06.17-.16.43-.13.68.07-.19,1.24-2.18,1a2.65,2.65,0,0,1-2.51-3.1A2.66,2.66,0,0,1,117.39,23a2.47,2.47,0,0,1,2.43,2.63C119.79,25.88,119.62,26,119.21,26ZM117,25.55l.44.05c.61.06,1.25-.31,1.3-.8a1.29,1.29,0,0,0-1.3-1.35c-1-.1-1.61.32-1.68,1S116.19,25.47,117,25.55Z" />
                    <path d="M120.19,27.65c.11-1,1.11-1.46,2.3-1.48s1.93-.41,2-.92a1.25,1.25,0,0,0-1.09-1.4l-.21,0c-.74-.08-1.15.61-1.71.55-.15,0-.31,0-.29-.2,0-.49,1.11-.7,2-.6,2.71.29,2.4,1.22,2.15,3.58l-.19,1.78c0,.32-.21.47-.51.44s-.7-.25-1.11-.29c-.77-.08-1,.24-1.82.15A1.53,1.53,0,0,1,120.19,27.65Zm2.15,1.19a1.69,1.69,0,0,0,2-1.37,1.07,1.07,0,0,0-1-1.15c-.84-.09-1.94.48-2,1.26A1.18,1.18,0,0,0,122.34,28.84Z" />
                    <path d="M129.58,24.41c.2,0,.43.07.39.44s-.22.35-.65.52a2.2,2.2,0,0,0-.91.5,1.82,1.82,0,0,0-.47,1L127.72,29c-.06.56-.27.81-.6.78-.67-.07-.75-.23-.64-1.25l.39-3.68c.06-.56.3-.83.73-.79s1.08.32,1.21.33C129.22,24.42,129.22,24.37,129.58,24.41Z" />
                    <path d="M135.07,27.66l-1.61-.19c-1.58-.17-2.17,0-2.26.86A1.7,1.7,0,0,0,132.76,30a1.66,1.66,0,0,0,.91-.07l.71-.35c.13-.05.17-.15.42-.12.69.07-.18,1.23-2.17,1a2.65,2.65,0,0,1-2.51-3.1,2.66,2.66,0,0,1,3.12-2.67,2.47,2.47,0,0,1,2.44,2.62C135.65,27.58,135.48,27.71,135.07,27.66Zm-2.26-.41.44,0a1.16,1.16,0,0,0,1.3-.79,1.3,1.3,0,0,0-1.31-1.35c-1-.1-1.61.32-1.67,1S132.05,27.17,132.81,27.25Z" />
                    <path d="M141.81,30.7a.48.48,0,0,1-.55.48,4.78,4.78,0,0,1-.93-.22,1,1,0,0,0-.53-.06s-.74.12-.79.12,0,0-.21,0a.75.75,0,0,1-.26,0c-1.53-.16-2.45-1.2-2.24-3.15.1-.95.66-2.83,2.7-2.61.41,0,1,.25,1.32.28a.8.8,0,0,0,.86-.35c.56-.9.13-1.73.9-1.65.41,0,.46.27.42.66Zm-2.88-.11c.4,0,1.87-.12,2.1-2.29.14-1.31-.52-2.42-1.54-2.48-.52,0-1.91-.06-2.15,2.21C137.19,29.42,137.75,30.46,138.93,30.59Z" />
                    <path d="M149,25.9c.06,0,0,0,.26-.71a.54.54,0,0,1,.63-.35c.26,0,.45.12.49.52a2.41,2.41,0,0,0,.18.9c.12.34.63.46.6.73s-.3.14-.8.73a2.64,2.64,0,0,0-.36,1.17l-.13,1.17a1.72,1.72,0,0,0,.37,1.37c.19.17.55.45.53.67s-.26.42-.64.38a1.45,1.45,0,0,1-1.48-1.81l.22-2.12a1.73,1.73,0,0,0-.23-1.24c-.1-.23-.37-.36-.34-.62S148.78,26.2,149,25.9Z" />
                    <path d="M154.44,32.77a2.81,2.81,0,0,1-2.76-3.23A2.77,2.77,0,0,1,154.8,27c2.14.23,3.24,1.35,3,3.25A2.8,2.8,0,0,1,154.44,32.77Zm.08-.54a2.12,2.12,0,0,0,2.29-2.09c.18-1.71-.74-2.5-1.81-2.61a2.11,2.11,0,0,0-2.31,2C152.53,31.06,153.24,32.1,154.52,32.23Z" />
                    <path d="M163.26,31.9c-.14-1.1-.33-1.54-.89-1.6a.75.75,0,0,0-.37.09,1.08,1.08,0,0,0-.57.7l-.64,1.66a.77.77,0,0,1-.72.44c-.41,0-.67-.25-.78-.85l-.79-4c-.1-.5-.08-.75.36-.7s.42.37.46.74l.15,1.49c.08.9.44,1.21.69,1.24.52.05.88-.23,1.1-.87l.64-1.81a.66.66,0,0,1,.74-.46c.57.06.73.25.79,1.1l.13,1.42c.06.67.38,1,.76,1.09s.75-.22,1-.93l.72-1.87a.6.6,0,0,1,.53-.34.3.3,0,0,1,.3.31,2.67,2.67,0,0,1-.29.78l-1.72,3.56a.89.89,0,0,1-.83.5C163.44,33.55,163.43,33.2,163.26,31.9Z" />
                    <path d="M166.68,32.63c.11-1,1.11-1.46,2.3-1.48s1.93-.41,2-.92a1.25,1.25,0,0,0-1.09-1.4l-.21,0c-.74-.08-1.15.61-1.71.55-.15,0-.31,0-.29-.2.05-.49,1.11-.7,2-.6,2.71.29,2.4,1.22,2.15,3.58l-.19,1.78a.43.43,0,0,1-.52.44c-.25,0-.69-.25-1.1-.29-.77-.08-1,.24-1.82.15A1.53,1.53,0,0,1,166.68,32.63Zm2.15,1.19a1.69,1.69,0,0,0,1.95-1.37,1.07,1.07,0,0,0-1-1.15c-.84-.09-1.94.48-2,1.26A1.19,1.19,0,0,0,168.83,33.82Z" />
                    <path d="M176.07,29.39c.2,0,.43.07.39.44s-.22.35-.65.52a2.21,2.21,0,0,0-.9.49,1.8,1.8,0,0,0-.48,1L174.21,34c-.06.56-.27.81-.6.78-.67-.07-.75-.23-.64-1.25l.39-3.68c.06-.56.3-.83.73-.79s1.08.32,1.21.33C175.7,29.4,175.71,29.35,176.07,29.39Z" />
                    <path d="M182.13,35a.48.48,0,0,1-.54.48,5.22,5.22,0,0,1-.93-.22,1.09,1.09,0,0,0-.54-.06s-.74.12-.79.12,0,0-.21,0a.75.75,0,0,1-.26,0c-1.53-.16-2.45-1.2-2.24-3.15.1-.95.66-2.83,2.71-2.62.41.05,1,.26,1.31.29a.79.79,0,0,0,.86-.35c.56-.9.14-1.73.9-1.65.41,0,.46.27.42.66Zm-2.88-.11c.41,0,1.87-.12,2.1-2.29.14-1.32-.51-2.42-1.54-2.48-.51,0-1.9-.06-2.15,2.21C177.51,33.74,178.07,34.78,179.25,34.91Z" />
                </g>
                <g className="cls-3">
                    <path d="M8.88,25.24c0,.12-.18.45-.44.42s-.42-.17-.38-.51l.22-2.07c.16-1.51-.28-2.42-1.41-2.54-1.53-.17-1.76,1.78-1.8,2.17L4.84,24.8c0,.13-.18.48-.43.45S4,25.06,4,24.72l.24-2.22C4.42,21,4,20.2,2.87,20.08S1.2,20.72,1,22.23L.81,24.37c0,.12-.18.48-.44.42S0,24.71,0,24.41c0-.13,0-.76.15-1.86l.19-1.8c.12-1.07.24-1.23.7-1.18.15,0,.1,0,.65.14-.15,0,.36.09.41.09a4.19,4.19,0,0,0,1,0,4.79,4.79,0,0,1,.73-.05l.25,0a1.14,1.14,0,0,1,.45.15l.56.31a1.94,1.94,0,0,0,.68.22,1.68,1.68,0,0,0,.62,0L7,20.2a2.26,2.26,0,0,1,.89-.07c1.07.11,1.51.62,1.39,1.7Z" />
                    <path d="M9.73,24.61c.11-1,1.11-1.45,2.3-1.47s1.93-.41,2-.92a1.24,1.24,0,0,0-1.09-1.4l-.21,0c-.74-.08-1.15.61-1.71.55-.15,0-.31,0-.29-.2,0-.49,1.11-.7,2-.6,2.71.29,2.4,1.22,2.15,3.58l-.19,1.78a.43.43,0,0,1-.52.44c-.25,0-.69-.25-1.1-.29-.77-.08-1,.23-1.83.15A1.53,1.53,0,0,1,9.73,24.61Zm2.15,1.19a1.68,1.68,0,0,0,1.95-1.36,1.07,1.07,0,0,0-1-1.15c-.84-.09-1.94.48-2,1.26A1.17,1.17,0,0,0,11.88,25.8Z" />
                    <path d="M20.72,21.59s.29,0,.27.18-.19.27-.33.41c-.63.59-.94.85-1,1.32a1.37,1.37,0,0,0,.09.8l1.06,2.2c.16.37.11.61-.19.57-.74-.07-.82-.55-.95-.79l-.46-.81a1.37,1.37,0,0,0-1-.82,1.3,1.3,0,0,0-1.27,1.22c-.06.56-.31.75-.54.73s-.51-.3-.46-.79l.67-6.26c0-.22.14-.58.5-.54s.51.25.47.64l-.2,1.9a1,1,0,0,0,.75,1.21,1.94,1.94,0,0,0,1.24-.55C20.21,21.56,20.31,21.55,20.72,21.59Z" />
                    <path d="M22.8,26.51c-.06.56-.27.83-.62.79-.56-.06-.63-.21-.51-1.33L22,22.51c.07-.59.23-.87.66-.82.61.07.58.33.47,1.38Zm.14-6.37c-.38,0-.49-.18-.47-.37s.16-.3.44-.27.5.12.48.37S23.1,20.16,22.94,20.14Z" />
                    <path d="M29.23,24.05l-.35,3.29c-.06.49-.29.51-.47.49s-.4-.14-.36-.48l.21-2.24c.2-1.83-.55-2.3-1.32-2.39-1-.1-1.7.66-1.82,1.85L24.86,27a.41.41,0,0,1-.49.39c-.28,0-.37-.16-.32-.55l.46-4.29a.43.43,0,0,1,.53-.41c.41.05.86.19,1.37.25a9.31,9.31,0,0,1,1.53-.11C29.2,22.42,29.31,23.25,29.23,24.05Z" />
                    <path d="M34.2,23.26a3.61,3.61,0,0,1,.85,0c.51.05.61.29.57.68L35.07,29c-.12,1.15-.87,1.68-2.92,1.46-1.73-.18-2.15-.85-2.13-1s.16-.33.37-.31c.51.06,1,1.07,1.86,1.17a1.6,1.6,0,0,0,1.89-1.21,1,1,0,0,0-1-1.19,5.16,5.16,0,0,0-.57,0c.26,0-.5,0-.7,0-1.06-.19-1.94-.92-1.75-2.68.17-1.56,1.23-2.58,2.35-2.46a4.33,4.33,0,0,1,1,.28A6.44,6.44,0,0,0,34.2,23.26Zm-1.63,4.33a1.83,1.83,0,0,0,2-1.76c.14-1.29-.41-2.43-1.5-2.55a1.83,1.83,0,0,0-2,1.83C30.9,26.52,31.5,27.47,32.57,27.59Z" />
                    <path d="M42.3,23.27c.06,0,0,0,.26-.71a.56.56,0,0,1,.63-.35c.26,0,.45.12.49.52a2.35,2.35,0,0,0,.19.91c.12.33.62.46.59.73s-.3.14-.8.72a2.73,2.73,0,0,0-.36,1.17l-.12,1.17a1.64,1.64,0,0,0,.37,1.37c.18.17.54.46.52.67s-.25.42-.64.38A1.46,1.46,0,0,1,42,28l.23-2.12A1.82,1.82,0,0,0,42,24.69c-.11-.23-.38-.36-.35-.63S42.12,23.57,42.3,23.27Z" />
                    <path d="M47.48,24.58a3.39,3.39,0,0,0,.76,0,3.1,3.1,0,0,1,.62,0c1.1.12,1.57.81,1.43,2L50,29.72c0,.08-.08.49-.39.46-.51-.06-.57-.21-.53-.56l.22-2.07C49.43,26,49,25.16,48,25.05s-1.65.66-1.83,2.34l-.2,1.9c0,.08-.08.49-.39.46-.51-.06-.51-.33-.52-.41a.54.54,0,0,1,0-.22l.7-6.55a.46.46,0,1,1,.91.17C46.52,23.91,46.82,24.51,47.48,24.58Z" />
                    <path d="M56,28l-1.6-.19c-1.58-.17-2.18,0-2.26.86a1.89,1.89,0,0,0,2.47,1.55l.7-.34c.14-.06.18-.16.43-.13.69.07-.18,1.24-2.18,1a2.65,2.65,0,0,1-2.51-3.1A2.67,2.67,0,0,1,54.18,25a2.46,2.46,0,0,1,2.43,2.62C56.58,27.91,56.41,28,56,28Zm-2.25-.41.43.05c.62.06,1.25-.31,1.3-.8a1.29,1.29,0,0,0-1.3-1.35c-1-.1-1.61.32-1.68,1S53,27.5,53.75,27.58Z" />
                    <path d="M71.24,31.92c0,.12-.18.45-.44.42s-.42-.17-.38-.51l.22-2.07c.16-1.51-.28-2.42-1.4-2.54-1.54-.17-1.77,1.78-1.81,2.17l-.23,2.09c0,.13-.18.48-.43.45s-.42-.19-.38-.53l.23-2.22c.16-1.49-.29-2.3-1.39-2.42s-1.67.64-1.83,2.15l-.23,2.14c0,.12-.18.48-.43.42s-.36-.08-.38-.38c0-.13,0-.76.15-1.86l.19-1.8c.12-1.07.24-1.23.7-1.18.15,0,.1,0,.65.14-.15,0,.36.09.41.09a4.19,4.19,0,0,0,1,0,4.79,4.79,0,0,1,.73-.05l.25,0a1.09,1.09,0,0,1,.45.15l.56.3a1.6,1.6,0,0,0,.68.22,1.75,1.75,0,0,0,.62,0l.61-.21a2.47,2.47,0,0,1,.89-.08c1.07.12,1.51.63,1.39,1.71Z" />
                    <path d="M76.81,28.12c.06-.52.34-.49.49-.47s.36.24.31.7l-.43,4.07c0,.36-.24.51-.62.47s-.73-.2-1.16-.24-1.11.15-1.59.1c-1.15-.13-1.49-.58-1.34-2l.33-3.1c0-.34.18-.47.41-.45s.45.22.41.56l-.29,2.66a1.6,1.6,0,0,0,1.37,1.92,1.74,1.74,0,0,0,1.84-1.73Z" />
                    <path d="M80.19,30.89c-1.35-.36-1.67-1-1.6-1.62.12-1.12,1.35-1.58,2.58-1.45.92.1,1.91.47,1.87.86,0,.13-.13.21-.38.18-.46,0-.76-.37-1.45-.45s-1.22.24-1.27.73.29.94,1.69,1.39c1.15.37,1.75.78,1.65,1.68s-1.21,1.67-2.64,1.51-2.41-.67-2.37-1.06c0-.22.16-.26.36-.23.44,0,.92.59,1.92.72s1.37-.32,1.43-.86S81.57,31.26,80.19,30.89Z" />
                    <path d="M85.42,33.21c-.06.56-.27.84-.63.8-.56-.06-.62-.22-.5-1.34l.37-3.45c.06-.59.22-.87.66-.82.61.06.58.33.47,1.38Zm.14-6.36c-.38,0-.5-.18-.48-.37s.17-.31.45-.28.5.13.47.37S85.71,26.86,85.56,26.85Z" />
                    <path d="M91.53,30.2c-.28,0-.74-.57-1.43-.65a1.92,1.92,0,0,0-2,1.95,2,2,0,0,0,1.58,2.39c.94.1,1.23-.39,1.74-.33.25,0,.37.13.35.35s-1.06,1-2.39.83a2.8,2.8,0,0,1-2.69-3.34A2.91,2.91,0,0,1,90,28.65c1.2.13,2.2.73,2.14,1.22C92.1,30.16,91.89,30.24,91.53,30.2Z" />
                </g>
            </g>
        </g>
    </SubmissionsText1Svg>
);

interface SubmissionsText2Props extends Animatable {}

const grow = keyframes`
    from {
        transform: scale(0.25)
    }
    to {
        transform: scale(1);
    }
`;

const SubmissionsText2SVG = styled.svg`
    left: 312px;
    position: absolute;
    top: 412px;
    transform: scale(0.25);
    width: 300px;
    z-index: 1;

    ${(props: SubmissionsText2Props) =>
        props.isAnimating &&
        css`
            animation: ${grow} 900ms linear infinite;
        `}

    .cls-1 {
        opacity: 0.8;
    }

    ${fadeIn}
`;

const SubmissionsText2: React.FC<SubmissionsText2Props> = props => (
    <SubmissionsText2SVG
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 75.84 15.41"
        {...props}
    >
        <g id="Layer_2" data-name="Layer 2">
            <g id="text_submissions" data-name="text submissions">
                <g className="cls-1">
                    <path d="M6.66,2.13c-.38,0-1-.78-1.94-.88C3.33,1.1,2.14,2.11,2,3.9,1.78,5.48,2.5,7,4.09,7.13c1.28.14,1.67-.52,2.36-.44.35,0,.51.18.47.48S5.49,8.49,3.68,8.3A3.82,3.82,0,0,1,0,3.76,4,4,0,0,1,4.57,0c1.63.17,3,1,2.91,1.65C7.44,2.08,7.14,2.18,6.66,2.13Z" />
                    <path d="M11.85,9c-2.56-.27-4-2-3.74-4.38A3.77,3.77,0,0,1,12.34,1.1c2.91.31,4.4,1.84,4.12,4.42C16.21,7.9,14.42,9.24,11.85,9ZM12,8.25A2.9,2.9,0,0,0,15.07,5.4c.25-2.31-1-3.38-2.46-3.54A2.86,2.86,0,0,0,9.49,4.54C9.26,6.65,10.23,8.06,12,8.25Z" />
                    <path d="M29.41,9.85c0,.16-.24.61-.59.57s-.57-.23-.52-.69l.3-2.81c.22-2-.39-3.29-1.91-3.45-2.08-.22-2.4,2.42-2.45,2.95l-.31,2.84c0,.17-.24.64-.59.61s-.57-.27-.52-.73l.32-3c.22-2-.4-3.12-1.89-3.28S19,3.71,18.77,5.76l-.32,2.91c0,.17-.24.65-.58.58s-.48-.12-.51-.53c0-.17,0-1,.2-2.52l.26-2.44c.16-1.46.32-1.67.95-1.61.21,0,.13.05.89.2-.21,0,.48.12.55.12a5.13,5.13,0,0,0,1.3-.06,5.92,5.92,0,0,1,1-.06l.34,0a1.38,1.38,0,0,1,.61.2l.77.41a2.22,2.22,0,0,0,.91.3,2.16,2.16,0,0,0,.84,0L26.81,3a3.09,3.09,0,0,1,1.2-.1c1.46.16,2,.85,1.89,2.31Z" />
                    <path d="M37.6,7.62l-2.18-.27c-2.15-.23-3,.05-3.07,1.17a2.31,2.31,0,0,0,2.12,2.2,2.26,2.26,0,0,0,1.23-.1l1-.46c.18-.09.23-.21.57-.18.94.1-.25,1.68-3,1.39a3.59,3.59,0,0,1-3.4-4.21c.26-2.44,1.74-3.89,4.24-3.62a3.35,3.35,0,0,1,3.3,3.56C38.39,7.5,38.16,7.67,37.6,7.62Zm-3.06-.57.59.07C36,7.2,36.83,6.7,36.9,6a1.75,1.75,0,0,0-1.77-1.82c-1.32-.14-2.19.43-2.28,1.29S33.5,6.94,34.54,7.05Z" />
                    <path d="M45.87,10.64c.14-1.36,1.51-2,3.12-2s2.62-.55,2.7-1.25a1.68,1.68,0,0,0-1.48-1.89l-.28,0c-1-.11-1.56.83-2.33.75-.2,0-.42,0-.39-.27.07-.67,1.51-1,2.75-.81,3.68.39,3.26,1.65,2.92,4.86l-.26,2.41c0,.43-.28.64-.69.59s-1-.33-1.5-.39c-1-.11-1.37.32-2.48.2A2.05,2.05,0,0,1,45.87,10.64Zm2.91,1.62a2.3,2.3,0,0,0,2.65-1.86A1.46,1.46,0,0,0,50,8.84c-1.14-.12-2.63.66-2.74,1.72A1.61,1.61,0,0,0,48.78,12.26Z" />
                    <path d="M55.94,12.77a.73.73,0,0,1-.92.74c-.83-.09-.9-.36-.72-2.08L55.1,4c.09-.89.28-1.34,1-1.26s.86.46.79,1.12Z" />
                    <path d="M59.23,12.74c-.08.76-.37,1.13-.86,1.08-.76-.08-.84-.29-.68-1.81l.5-4.7c.09-.79.31-1.17.9-1.11.83.09.79.46.64,1.88Zm.19-8.64c-.52-.06-.68-.24-.65-.5s.22-.42.6-.38.68.18.65.51S59.62,4.12,59.42,4.1Z" />
                    <path d="M64.07,11.25c.48.05.94-.27,1.22-.94l1-2.53A1.1,1.1,0,0,1,67.46,7c.11,0,.59.1.58.53a3.33,3.33,0,0,1-.34.87l-2.57,5.28a1.16,1.16,0,0,1-1.27.76,1.4,1.4,0,0,1-1.44-1.25L61.06,7.55c-.22-.89,0-1.17.54-1.11.8.09,1,.24,1.21,1.8C63.05,10.24,63.23,11.16,64.07,11.25Z" />
                    <path d="M75,11.62l-2.18-.27c-2.15-.23-2.95.06-3.07,1.18a2.31,2.31,0,0,0,2.11,2.2,2.29,2.29,0,0,0,1.24-.1l1-.47c.18-.08.23-.21.58-.17.93.1-.25,1.68-3,1.39a3.6,3.6,0,0,1-3.41-4.21c.26-2.45,1.75-3.89,4.24-3.63a3.34,3.34,0,0,1,3.3,3.57C75.78,11.5,75.55,11.68,75,11.62Zm-3.06-.56.59.06A1.56,1.56,0,0,0,74.29,10a1.75,1.75,0,0,0-1.77-1.83c-1.31-.14-2.18.44-2.27,1.3S70.9,11,71.94,11.06Z" />
                </g>
            </g>
        </g>
    </SubmissionsText2SVG>
);

interface SubmissionsText3Props extends Animatable {}

const shrink = keyframes`
    to {
        transform: scale(0.7);
    }
`;

const SubmissionsText3SVG = styled.svg`
    left: 439px;
    position: absolute;
    top: 447px;
    width: 86px;

    ${(props: SubmissionsText3Props) =>
        props.isAnimating &&
        css`
            animation: ${shrink} 900ms linear infinite;
        `}

    .cls-1 {
        opacity: 0.6;
    }

    .cls-2 {
        fill: white;
    }

    ${fadeIn}
`;

const SubmissionsText3: React.FC<SubmissionsText3Props> = props => (
    <SubmissionsText3SVG
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 87.81 17.85"
        {...props}
    >
        <g id="Layer_2" data-name="Layer 2">
            <g
                id="text_submissions_shadowy"
                data-name="text submissions shadowy"
            >
                <g className="cls-1">
                    <path
                        className="cls-2"
                        d="M7.71,2.46c-.44,0-1.16-.9-2.25-1-1.6-.17-3,1-3.21,3.06-.19,1.84.64,3.55,2.48,3.75,1.49.16,1.94-.6,2.74-.52.4,0,.59.22.55.56C8,8.8,6.35,9.83,4.27,9.6A4.41,4.41,0,0,1,0,4.35,4.56,4.56,0,0,1,5.29,0c1.89.2,3.45,1.14,3.37,1.9C8.61,2.4,8.27,2.52,7.71,2.46Z"
                    />
                    <path
                        className="cls-2"
                        d="M13.72,10.39c-3-.32-4.62-2.32-4.33-5.08a4.37,4.37,0,0,1,4.9-4c3.37.36,5.09,2.13,4.77,5.12C18.77,9.14,16.69,10.7,13.72,10.39Zm.13-.84a3.34,3.34,0,0,0,3.6-3.3c.29-2.67-1.16-3.91-2.85-4.09A3.31,3.31,0,0,0,11,5.25C10.72,7.7,11.85,9.33,13.85,9.55Z"
                    />
                    <path
                        className="cls-2"
                        d="M34.05,11.4c0,.19-.28.71-.68.66s-.66-.26-.6-.8L33.11,8c.26-2.37-.44-3.8-2.21-4-2.4-.26-2.77,2.8-2.84,3.41l-.35,3.29c0,.19-.28.75-.68.7s-.66-.3-.6-.84L26.8,7.1c.25-2.33-.47-3.61-2.19-3.8S22,4.3,21.73,6.67L21.37,10c0,.19-.28.75-.68.67s-.55-.14-.59-.61a29,29,0,0,1,.24-2.92l.3-2.83c.18-1.68.37-1.94,1.09-1.86.24,0,.16.06,1,.23-.24,0,.56.14.64.14a6.44,6.44,0,0,0,1.51-.07,8.35,8.35,0,0,1,1.14-.07l.4,0a1.6,1.6,0,0,1,.71.23l.88.48a2.64,2.64,0,0,0,1.06.34,2.64,2.64,0,0,0,1,0l1-.32a3.49,3.49,0,0,1,1.39-.12c1.69.18,2.37,1,2.19,2.67Z"
                    />
                    <path
                        className="cls-2"
                        d="M43.54,8.82,41,8.51c-2.49-.27-3.42.06-3.56,1.36a2.68,2.68,0,0,0,2.45,2.55,2.74,2.74,0,0,0,1.43-.12l1.11-.54c.22-.09.27-.24.67-.2,1.09.12-.28,1.94-3.41,1.61a4.18,4.18,0,0,1-4-4.88c.31-2.83,2-4.5,4.92-4.19A3.88,3.88,0,0,1,44.5,8.22C44.45,8.68,44.18,8.89,43.54,8.82ZM40,8.17l.68.07a1.81,1.81,0,0,0,2-1.25,2,2,0,0,0-2-2.12c-1.53-.16-2.53.5-2.64,1.5S38.79,8,40,8.17Z"
                    />
                    <path
                        className="cls-2"
                        d="M53.11,12.32c.17-1.57,1.75-2.29,3.62-2.32s3-.65,3.12-1.45a2,2,0,0,0-1.72-2.2l-.32,0c-1.16-.13-1.81,1-2.69.87-.24,0-.49,0-.45-.32.08-.76,1.74-1.09,3.18-.93,4.26.45,3.78,1.91,3.38,5.62l-.3,2.8a.67.67,0,0,1-.81.69c-.4-.05-1.09-.39-1.73-.46-1.21-.13-1.59.37-2.87.23A2.38,2.38,0,0,1,53.11,12.32Zm3.37,1.87A2.66,2.66,0,0,0,59.55,12a1.67,1.67,0,0,0-1.63-1.8c-1.32-.14-3.05.76-3.18,2A1.87,1.87,0,0,0,56.48,14.19Z"
                    />
                    <path
                        className="cls-2"
                        d="M64.78,14.79a.85.85,0,0,1-1.07.85c-1-.1-1.05-.42-.83-2.41l.92-8.61c.11-1,.33-1.56,1.17-1.46s1,.53.92,1.29Z"
                    />
                    <path
                        className="cls-2"
                        d="M68.58,14.75c-.1.88-.43,1.31-1,1.25-.88-.09-1-.34-.79-2.1l.58-5.43c.1-.92.35-1.36,1-1.29,1,.11.92.53.75,2.17Zm.22-10c-.61-.07-.78-.28-.75-.59s.25-.47.69-.43.79.2.75.59S69,4.77,68.8,4.75Z"
                    />
                    <path
                        className="cls-2"
                        d="M74.18,13c.56.06,1.09-.31,1.42-1.09L76.76,9a1.27,1.27,0,0,1,1.35-.87c.12,0,.68.12.67.62a4.06,4.06,0,0,1-.39,1l-3,6.11a1.37,1.37,0,0,1-1.48.89,1.64,1.64,0,0,1-1.67-1.46L70.7,8.75c-.25-1,0-1.36.63-1.29.92.1,1.19.28,1.4,2.08C73,11.86,73.22,12.92,74.18,13Z"
                    />
                    <path
                        className="cls-2"
                        d="M86.84,13.45l-2.52-.3c-2.49-.27-3.42.06-3.56,1.36a3,3,0,0,0,3.88,2.43l1.11-.54c.22-.1.27-.24.67-.2,1.09.11-.28,1.94-3.41,1.61a4.18,4.18,0,0,1-4-4.88c.31-2.83,2-4.51,4.92-4.2a3.89,3.89,0,0,1,3.82,4.13C87.75,13.32,87.48,13.52,86.84,13.45ZM83.3,12.8l.68.08a1.81,1.81,0,0,0,2-1.26,2,2,0,0,0-2-2.11c-1.52-.17-2.53.5-2.63,1.5S82.09,12.67,83.3,12.8Z"
                    />
                </g>
            </g>
        </g>
    </SubmissionsText3SVG>
);

interface SubmissionsTextProps extends Animatable, Hideable {}

const SubmissionsText: React.FC<SubmissionsTextProps> = props => (
    <React.Fragment>
        <CSSTransition in={props.isVisible} mountOnEnter timeout={1000}>
            <SubmissionsText1 />
        </CSSTransition>
        <CSSTransition in={props.isVisible} mountOnEnter timeout={1000}>
            <SubmissionsText2 isAnimating={props.isAnimating} />
        </CSSTransition>
        <CSSTransition in={props.isVisible} mountOnEnter timeout={1000}>
            <SubmissionsText3 isAnimating={props.isAnimating} />
        </CSSTransition>
    </React.Fragment>
);

export default SubmissionsText;
