import * as React from 'react';
import styled from 'styled-components';
import { fadeIn } from '../../../pages/main/components/main-content/styles';

const LogoTextSVG = styled.svg`
    margin-top: -20px;
    margin-left: 8px;
    width: 108px;

    ${fadeIn}
`;

const LogoText: React.FC = () => (
    <LogoTextSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 186.63 8.33">
        <g id="Layer_2" data-name="Layer 2">
            <g id="type_outlines" data-name="type outlines">
                <path d="M8.1,6.2c.21.37.35.61.85.61a1.57,1.57,0,0,0,.52-.08V8a5.51,5.51,0,0,1-1.76.29A2,2,0,0,1,6,7.55c-.69-1.22-.56-2.34-2.43-2.34a3.57,3.57,0,0,0-.91.11V8.2H0V.13H4.6C7.16.13,8.45,1.3,8.45,2.66A2.32,2.32,0,0,1,7.08,4.73,5.88,5.88,0,0,1,8.1,6.2ZM2.61,1.87V3.53H4.55c.87,0,1.32-.33,1.32-.83s-.45-.83-1.32-.83Z" />
                <path d="M16.16,8.33c-3.34,0-4.52-1.69-4.52-4.16S12.82,0,16.16,0s4.52,1.69,4.52,4.17S19.5,8.33,16.16,8.33Zm0-6.57c-1.52,0-1.9.93-1.9,2.41s.38,2.41,1.9,2.41,1.9-.92,1.9-2.41S17.68,1.76,16.16,1.76Z" />
                <path d="M31.58,2.26a1.81,1.81,0,0,1-1,1.6c1.29.37,1.92,1.15,1.92,2,0,1.26-1.29,2.3-3.86,2.3H23.93V.13H28C30.42.13,31.58,1.09,31.58,2.26ZM29,2.49c0-.42-.36-.68-1.06-.68H26.55V3.17h1.34C28.59,3.17,29,2.92,29,2.49Zm.85,3.17c0-.5-.45-.85-1.32-.85H26.55V6.5h1.93C29.35,6.5,29.8,6.17,29.8,5.66Z" />
                <path d="M34.92,5.91A2.14,2.14,0,0,1,36.48,4a1.88,1.88,0,0,1-1.26-1.73C35.22,1,36.4,0,39.14,0a30.24,30.24,0,0,1,3.53.2V2a33.55,33.55,0,0,0-3.53-.2c-.92,0-1.32.23-1.32.62s.4.64,1.32.64h3.08V4.92H39.13c-1,0-1.6.21-1.6.78s.64.8,1.6.8a30.93,30.93,0,0,0,3.77-.2V8.13c-.94.07-2,.2-3.77.2C36.24,8.33,34.92,7.24,34.92,5.91Z" />
                <path d="M54.19,6.2c.21.37.35.61.85.61a1.53,1.53,0,0,0,.52-.08V8a5.45,5.45,0,0,1-1.76.29A2,2,0,0,1,52,7.55c-.69-1.22-.56-2.34-2.43-2.34a3.41,3.41,0,0,0-.9.11V8.2H46.09V.13h4.6c2.56,0,3.85,1.17,3.85,2.53a2.32,2.32,0,0,1-1.37,2.07A5.63,5.63,0,0,1,54.19,6.2ZM48.71,1.87V3.53h1.93c.87,0,1.32-.33,1.32-.83s-.45-.83-1.32-.83Z" />
                <path d="M62.19,8.2H59.58V2H56.77V.13H65V2H62.19Z" />
                <path d="M80.2,2.88c0,1.5-1.27,2.75-4.09,2.75h-1.5V8.2H72V.13h4.12C78.93.13,80.2,1.4,80.2,2.88Zm-2.62,0c0-.64-.42-1-1.59-1H74.61V3.94H76C77.16,3.94,77.58,3.51,77.58,2.88Z" />
                <path d="M87.07,8.33c-3.33,0-4.51-1.69-4.51-4.16S83.74,0,87.07,0s4.52,1.69,4.52,4.17S90.41,8.33,87.07,8.33Zm0-6.57c-1.51,0-1.9.93-1.9,2.41s.39,2.41,1.9,2.41S89,5.66,89,4.17,88.59,1.76,87.07,1.76Z" />
                <path d="M99.28,1.88h1.58l1.83,2.66L103.3.13h2.5L104.61,8.2h-2l-2.5-3.64L97.57,8.2h-2L94.35.13h2.5l.61,4.41Z" />
                <path d="M108.49,5.91A2.14,2.14,0,0,1,110.06,4a1.86,1.86,0,0,1-1.26-1.73C108.8,1,110,0,112.72,0a30.37,30.37,0,0,1,3.53.2V2a33.87,33.87,0,0,0-3.53-.2c-.92,0-1.33.23-1.33.62s.41.64,1.33.64h3.08V4.92h-3.1c-1,0-1.6.21-1.6.78s.65.8,1.6.8a30.93,30.93,0,0,0,3.77-.2V8.13c-.93.07-2,.2-3.77.2C109.81,8.33,108.49,7.24,108.49,5.91Z" />
                <path d="M127.76,6.2c.21.37.36.61.86.61a1.61,1.61,0,0,0,.52-.08V8a5.51,5.51,0,0,1-1.76.29,2,2,0,0,1-1.76-.78c-.69-1.22-.57-2.34-2.44-2.34a3.55,3.55,0,0,0-.9.11V8.2h-2.61V.13h4.59c2.57,0,3.86,1.17,3.86,2.53a2.32,2.32,0,0,1-1.37,2.07A5.61,5.61,0,0,1,127.76,6.2Zm-5.48-4.33V3.53h1.94c.87,0,1.32-.33,1.32-.83s-.45-.83-1.32-.83Z" />
                <path d="M138.41,8.2h-2.5L137.1.13h2l2.5,3.65,2.5-3.65h2l1.19,8.07h-2.5l-.61-4.41-1.83,2.66h-1.58L139,3.79Z" />
                <path d="M154.62,6.46c1,0,1.61-.43,1.61-1.1V.13h2.61V5.7c0,1.58-1.66,2.63-4.22,2.63s-4.23-1-4.23-2.63V.13H153V5.36C153,6,153.55,6.46,154.62,6.46Z" />
                <path d="M161.83,5.25a9.3,9.3,0,0,0,4,1.24c.73,0,1.29-.13,1.29-.6s-.42-.58-1.55-.86c-1.82-.46-3.64-1-3.64-2.66,0-1.32,1.45-2.37,3.92-2.37A10.08,10.08,0,0,1,169,.55v1.9a9.58,9.58,0,0,0-3.16-.6c-.77,0-1.11.17-1.11.5s.43.47,1.32.71c1.82.5,3.89,1.08,3.89,2.83,0,1.42-1.52,2.44-4.11,2.44a8.84,8.84,0,0,1-4-1Z" />
                <path d="M175.71.13V8.2H173.1V.13Z" />
                <path d="M184.12,1.88c-1.91,0-2.31,1-2.31,2.29s.4,2.29,2.31,2.29A6.55,6.55,0,0,0,186.63,6V7.86a9.37,9.37,0,0,1-3,.47c-3.2,0-4.42-1.68-4.42-4.16S180.42,0,183.62,0a9.36,9.36,0,0,1,3,.47v1.9A6.41,6.41,0,0,0,184.12,1.88Z" />
            </g>
        </g>
    </LogoTextSVG>
);

export default LogoText;
