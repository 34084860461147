import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import * as assets from './assets';
import PageContainer from 'shared/components/page-container';
import { LogoContainer, LogoRecord } from 'shared/components/logo';
import { NavDot } from 'shared/components/nav';
import { Paths, DISCOGRAPHY_LINK } from 'shared/constants';
import Area from 'shared/components/area';
import { usePopup } from 'shared/hooks';
import Popups from './popups';
import { loadBeforeDisplay } from 'shared/hocs';

const ClickItImg = styled.img.attrs(() => assets.projectsClickIt)`
    cursor: pointer;
    left: 536px;
    position: absolute;
    top: 371px;
`;

const ArticlesInterviewsImg = styled.img.attrs(
    () => assets.projectsArticlesInterviewsHover
)`
    cursor: pointer;
    left: 653px;
    opacity: 0;
    position: absolute;
    top: 214px;

    &:hover {
        opacity: 1;
    }
`;

const LogoContainerOuter = styled.div`
    left: 498px;
    top: 23px;
    position: absolute;
`;

const ROOT_ID = 'projects-root';

type HoverTarget =
    | 'Studio'
    | 'Projects'
    | 'Discography'
    | 'Bob Power'
    | 'Timeline'
    | 'Submissions'
    | 'Contact';

const HoverTargetMultipliers = {
    Studio: 0,
    Projects: 1,
    Discography: 2,
    'Bob Power': 3,
    Timeline: 4,
    Submissions: 5,
    Contact: 6,
};

const ProjectsNavDot = styled(NavDot)<{ hoverTarget: HoverTarget }>`
    left: 486px;
    top: ${props => `${46 + 41 * HoverTargetMultipliers[props.hoverTarget]}px`};
`;

const Projects = () => {
    const { PopupModal, setCurrentPopup } = usePopup(Popups, ROOT_ID);
    const [hoverTarget, setHoverTarget] = useState<HoverTarget | null>(null);
    const resetHoverTarget = () => setHoverTarget(null);
    return (
        <PageContainer id={ROOT_ID}>
            <img
                alt={assets.projectsBg.alt}
                src={assets.projectsBg.src}
                useMap="#projects-map"
            />
            <ClickItImg onClick={() => setCurrentPopup('Aguilar')} />
            <Link to={Paths.ARTICLES_AND_INTERVIEWS}>
                <ArticlesInterviewsImg />
            </Link>
            <LogoContainerOuter>
                <LogoContainer>
                    <Link to={Paths.HOME}>
                        <LogoRecord />
                    </Link>
                </LogoContainer>
            </LogoContainerOuter>
            <map name="projects-map">
                <Area
                    target="_blank"
                    alt="a-tribe-called-quest"
                    href="https://atribecalledquest.com/home/"
                    coords="115,138,148,149,30,465,3,449"
                />
                <Area
                    target="_blank"
                    alt="dangelo"
                    href="https://www.okayplayer.com/dangelo/interface.htm"
                    coords="150,142,125,468,158,469,180,143"
                />
                <Area
                    target="_blank"
                    alt="de-la-soul"
                    href="https://www.wearedelasoul.com"
                    coords="175,139,176,468,208,470,207,139,180,139"
                />
                <Area
                    target="_blank"
                    alt="the-roots"
                    href="https://www.okayplayer.com/artist/the-roots"
                    coords="209,470,241,468,235,136,202,135"
                />
                <Area
                    target="_blank"
                    alt="erykah-badu"
                    href="https://en.wikipedia.org/wiki/Erykah_Badu"
                    coords="234,140,282,470,316,467,264,137"
                />
                <Area
                    target="_blank"
                    alt="ozomatli"
                    href="https://ozomatli.com/"
                    coords="294,139,352,466,317,470,263,144"
                />
                <Area
                    target="_blank"
                    alt="citizen-cope"
                    href="https://citizencope.com/"
                    coords="402,145,355,467,383,470,430,151"
                />
                <Area
                    target="_blank"
                    alt="common"
                    href="https://www.thinkcommon.com"
                    coords="435,467,464,467,455,163,432,161"
                />
                <Area
                    target="_blank"
                    alt="meshell"
                    href="http://www.meshell.com/"
                    coords="459,159,469,469,497,468,488,161"
                    shape="poly"
                />
                <Area
                    alt="aguilar"
                    coords="586,508,53"
                    shape="circle"
                    onClick={() => setCurrentPopup('Aguilar')}
                />
                <Link to={Paths.STUDIO}>
                    <Area
                        alt="studio"
                        coords="565,102,626,117"
                        shape="rect"
                        onMouseEnter={() => setHoverTarget('Studio')}
                        onMouseLeave={resetHoverTarget}
                    />
                </Link>
                <Link to={Paths.PROJECTS}>
                    <Area
                        alt="projects"
                        coords="562,144,646,158"
                        shape="rect"
                        onMouseEnter={() => setHoverTarget('Projects')}
                        onMouseLeave={resetHoverTarget}
                    />
                </Link>
                <Area
                    alt="discography"
                    coords="563,184,678,200"
                    href={DISCOGRAPHY_LINK}
                    shape="rect"
                    target="blank"
                    onMouseEnter={() => setHoverTarget('Discography')}
                    onMouseLeave={resetHoverTarget}
                    rel="noopener noreferrer"
                />
                <Link to={Paths.BIO}>
                    <Area
                        alt="bob-power"
                        coords="564,224,655,241"
                        shape="rect"
                        onMouseEnter={() => setHoverTarget('Bob Power')}
                        onMouseLeave={resetHoverTarget}
                    />
                </Link>
                <Link to={Paths.TIMELINE}>
                    <Area
                        alt="timeline"
                        coords="564,266,646,281"
                        shape="rect"
                        onMouseEnter={() => setHoverTarget('Timeline')}
                        onMouseLeave={resetHoverTarget}
                    />
                </Link>
                <Link to={Paths.SUBMISSIONS}>
                    <Area
                        alt="submissions"
                        coords="564,307,676,323"
                        shape="rect"
                        onMouseEnter={() => setHoverTarget('Submissions')}
                        onMouseLeave={resetHoverTarget}
                    />
                </Link>
                <Link to={Paths.CONTACT}>
                    <Area
                        alt="contact"
                        coords="562,349,637,364"
                        shape="rect"
                        onMouseEnter={() => setHoverTarget('Contact')}
                        onMouseLeave={resetHoverTarget}
                    />
                </Link>
                <Link to={Paths.HOME}>
                    <Area alt="logo-text" coords="593,42,716,56" shape="rect" />
                </Link>
            </map>
            <PopupModal />
            {hoverTarget && <ProjectsNavDot hoverTarget={hoverTarget} />}
        </PageContainer>
    );
};

export default loadBeforeDisplay(Projects, assets.forPreload);
