import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
    Area,
    LogoContainer,
    LogoRecord,
    Nav,
    PageContainer,
} from 'shared/components';
import Popups from './popups';
import { usePopup } from 'shared/hooks';
import { Paths } from 'shared/constants';
import { ContactText } from './components';
import * as assets from './assets';
import { loadBeforeDisplay } from 'shared/hocs';

const ContactClickItImg = styled.img.attrs(() => assets.contactClickIt)`
    cursor: pointer;
    left: 536px;
    position: absolute;
    top: 371px;
`;

const LogoContainerOuter = styled.div`
    left: 498px;
    position: absolute;
    top: 24px;
`;

const ContactNav = styled(Nav)`
    left: 566px;
    position: absolute;
    top: 105px;
`;

const ContactPageTitle = styled.img.attrs(() => ({
    ...assets.contactPageTitle,
    useMap: '#contact-title-map',
}))`
    left: 52px;
    position: absolute;
    top: 23px;
`;

const ContactEmailLinkImg = styled.img.attrs(() => assets.contactEmailLink)`
    left: 191px;
    position: absolute;
    top: 148px;
`;

const StyledContactText = styled(ContactText)`
    left: 221px;
    position: absolute;
    top: 178px;
`;

const ROOT_ID = 'contact-root';

const Contact = () => {
    const { PopupModal, setCurrentPopup } = usePopup(Popups, ROOT_ID);
    return (
        <PageContainer id={ROOT_ID}>
            <img
                alt={assets.contactBg.alt}
                src={assets.contactBg.src}
                useMap="#contact-map"
            />
            <map name="contact-map">
                <Link to={Paths.HOME}>
                    <Area alt="logo-text" coords="591,43,715,55" shape="rect" />
                </Link>
                <Area
                    alt="guild"
                    onClick={() => setCurrentPopup('GuildF50')}
                    coords="503,387,486,400,474,419,448,425,432,436,429,456,433,464,350,497,336,494,312,502,310,517,318,527,345,522,351,510,439,484,452,504,471,505,493,491,511,495,533,499,536,523,558,550,588,557,619,543,638,516,626,487,602,462,572,458,569,433,554,404,537,389,519,385"
                    shape="poly"
                />
                <Area
                    alt="vetta"
                    coords="133,510,53"
                    onClick={() => setCurrentPopup('Vetta')}
                    shape="circle"
                />
                <Area
                    alt="pensa-suhr"
                    coords="151,408,206,368,206,344,150,278,135,299,32,138,19,153,105,299,96,304,82,289,73,292,85,323,111,345,120,392"
                    onClick={() => setCurrentPopup('PensaSuhr')}
                />
                <Link to={Paths.HOME}>
                    <Area alt="logo-text" coords="592,43,713,56" />
                </Link>
            </map>
            <ContactClickItImg />
            <LogoContainerOuter>
                <LogoContainer>
                    <Link to={Paths.HOME}>
                        <LogoRecord />
                    </Link>
                </LogoContainer>
            </LogoContainerOuter>
            <ContactNav />
            <ContactPageTitle />
            <map name="contact-title-map">
                <Area
                    alt="U67"
                    coords="51,35,73,54,73,63,24,112,17,114,6,110,1,99"
                    onClick={() => setCurrentPopup('U67')}
                />
            </map>
            <a href="mailto:info@bobpower.com">
                <ContactEmailLinkImg />
            </a>
            <StyledContactText />
            <PopupModal />
        </PageContainer>
    );
};

export default loadBeforeDisplay(Contact, assets.forPreload);
