import * as React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { mainGuitarScribbles } from 'pages/main/assets';
import { Hideable } from 'shared/types';
import { fadeIn } from '../../styles';

interface GuitarScribblesProps extends Hideable {}

const GuitarScribblesImg = styled.img.attrs(() => mainGuitarScribbles)`
    left: 93px;
    position: absolute;
    top: 310px;
    width: 200px;
    z-index: 1;

    ${fadeIn}
`;

const GuitarScribbles: React.FC<GuitarScribblesProps> = props => (
    <CSSTransition in={props.isVisible} mountOnEnter timeout={1000}>
        <GuitarScribblesImg />
    </CSSTransition>
);

export default GuitarScribbles;
