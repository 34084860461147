import * as React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { Hideable } from 'shared/types';
import { fadeIn } from '../../styles';

import { bobScribbles } from 'pages/main/assets';

interface BobScribblesProps extends Hideable {}

const BobScribblesImg = styled.img.attrs(() => bobScribbles)`
    position: absolute;
    top: 48px;

    ${fadeIn}
`;

const BobScribbles: React.FC<BobScribblesProps> = props => (
    <CSSTransition in={props.isVisible} mountOnEnter timeout={1000}>
        <BobScribblesImg />
    </CSSTransition>
);

export default BobScribbles;
