import * as React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { Hoverable, Hideable } from 'shared/types';
import { fadeIn } from '../../styles';

import { mainMicrophone } from 'pages/main/assets';

interface MicrophoneProps extends Hideable, Hoverable {}

const MicrophoneImg = styled.img.attrs(() => mainMicrophone)`
    left: 395px;
    position: absolute;
    top: 214px;
    z-index: 2;

    ${fadeIn}
`;

const Microphone: React.FC<MicrophoneProps> = props => (
    <CSSTransition in={props.isVisible} mountOnEnter timeout={1000}>
        <MicrophoneImg {...props} />
    </CSSTransition>
);

export default Microphone;
