import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { usePopup, useAssetPreload } from 'shared/hooks';
import Popups from './popups';
import PageContainer from 'shared/components/page-container';
import Area from 'shared/components/area';
import * as assets from './assets';
import { loadBeforeDisplay } from 'shared/hocs';

const BackHoverImg = styled.img.attrs(() => assets.micClosetBackHover)`
    left: 561px;
    opacity: 0;
    position: absolute;

    &:hover {
        opacity: 1;
    }
`;

const ROOT_ID = 'mic-closet-root';

const MicCloset = () => {
    useAssetPreload(assets.forPreload);
    const { PopupModal, setCurrentPopup } = usePopup(Popups, ROOT_ID);
    return (
        <PageContainer id={ROOT_ID}>
            <img
                alt={assets.micClosetBg.alt}
                src={assets.micClosetBg.src}
                useMap="#mic-closet-map"
            />
            <Link to="/studio">
                <BackHoverImg />
            </Link>
            <map name="mic-closet-map">
                <Area
                    alt="l47"
                    coords="606,80,612,228,624,236,648,223,650,83,641,78"
                    onClick={() => setCurrentPopup('L47')}
                />
                <Area
                    alt="c24"
                    coords="501,84,521,236,474,240,426,241,434,98,469,103,492,79"
                    onClick={() => setCurrentPopup('C24')}
                />
                <Area
                    alt="c12"
                    coords="185,141,220,205,235,214,267,204,286,203,314,218,345,181,357,139,327,125,285,113,253,114,214,123"
                    onClick={() => setCurrentPopup('C12')}
                />
                <Area
                    alt="vm1"
                    coords="70,81,76,242,112,245,123,82"
                    onClick={() => setCurrentPopup('VM1')}
                />
                <Area
                    alt="u47"
                    coords="54,309,53,438,104,439,109,310"
                    onClick={() => setCurrentPopup('U47')}
                />
                <Area
                    alt="l251"
                    coords="185,297,191,444,229,449,238,299"
                    onClick={() => setCurrentPopup('L251')}
                />
                <Area
                    alt="u67"
                    coords="321,294,329,435,337,444,356,445,362,434,364,298"
                    onClick={() => setCurrentPopup('U67')}
                />
                <Area
                    alt="sm57"
                    coords="458,295,455,339,459,446,470,451,482,445,488,330,484,292,467,287"
                    onClick={() => setCurrentPopup('SM57')}
                />
                <Area
                    alt="schoeps"
                    coords="562,271,562,438,701,434,690,269"
                    onClick={() => setCurrentPopup('Schoeps')}
                />
            </map>
            <PopupModal />
        </PageContainer>
    );
};

export default loadBeforeDisplay(MicCloset, assets.forPreloadBeforeDisplay);
