import * as React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
`;

const LogoRecordSvg = styled.svg`
    cursor: pointer;
    margin-top: -20px;
    width: 91px;
    animation: ${rotate} 7s linear infinite;

    .cls-1 {
        fill-rule: evenodd;
    }

    .cls-2 {
        fill: white;
    }
`;

const LogoRecord: React.FC = () => (
    <LogoRecordSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151.2 151.2">
        <g id="Layer_2" data-name="Layer 2">
            <g id="disks">
                <path
                    className="cls-1"
                    d="M75.6,0a75.6,75.6,0,1,0,75.6,75.6A75.63,75.63,0,0,0,75.6,0Zm-.4,102.21A27.22,27.22,0,1,1,102.41,75,27.22,27.22,0,0,1,75.2,102.21Z"
                />
                <circle className="cls-2" cx="75.2" cy="75" r="27" />
                <path
                    className="cls-1"
                    d="M77.21,74.59a1.82,1.82,0,1,1-1.81-1.81,1.82,1.82,0,0,1,1.81,1.81Z"
                />
            </g>
            <g id="type_outlines" data-name="type outlines">
                <path
                    className="cls-2"
                    d="M118.34,76.94c.21.38.35.62.85.62a1.82,1.82,0,0,0,.52-.08v1.3a5.4,5.4,0,0,1-1.76.3,2,2,0,0,1-1.76-.78c-.69-1.22-.56-2.34-2.43-2.34a3.41,3.41,0,0,0-.9.11v2.87h-2.62V70.88h4.6c2.56,0,3.85,1.17,3.85,2.53a2.33,2.33,0,0,1-1.37,2.07A5.57,5.57,0,0,1,118.34,76.94Zm-5.48-4.33v1.67h1.93c.87,0,1.32-.34,1.32-.83s-.45-.84-1.32-.84Z"
                />
                <path
                    className="cls-2"
                    d="M131,73.62c0,1.51-1.28,2.76-4.1,2.76h-1.5v2.56h-2.61V70.88h4.11C129.69,70.88,131,72.14,131,73.62Zm-2.63,0c0-.63-.42-1.05-1.58-1.05h-1.39v2.11h1.39C127.92,74.68,128.34,74.25,128.34,73.62Z"
                />
                <path
                    className="cls-2"
                    d="M135.71,78.94h-2.5l1.19-8.06h2l2.5,3.64,2.49-3.64h2l1.19,8.06h-2.5l-.61-4.4-1.82,2.66h-1.59l-1.82-2.66Z"
                />
            </g>
        </g>
    </LogoRecordSvg>
);

export default LogoRecord;
