import * as React from 'react';
import styled from 'styled-components';

import { LogoText } from 'shared/components/logo';
import { fadeIn } from '../../styles';
import { Hideable } from 'shared/types';
import { CSSTransition } from 'react-transition-group';

interface MainLogoTextProps extends Hideable {}

const LogoTextWithFade = styled(LogoText)`
    ${fadeIn}
`;

const MainLogoText: React.FC<MainLogoTextProps> = props => (
    <CSSTransition in={props.isVisible} mountOnEnter timeout={1000}>
        <LogoTextWithFade />
    </CSSTransition>
);

export default MainLogoText;
