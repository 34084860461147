import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { Howl } from 'howler';
import { withRouter, RouteComponentProps } from 'react-router';

import MainAnimation from './components/main-animation';
import MainContent from './components/main-content';
import MixingAndMastering from './components/mixing-and-mastering';
import Interview from './components/interview';

import * as assets from './assets';
import { loadBeforeDisplay } from 'shared/hocs';

interface MainProps extends RouteComponentProps {
    shouldPlayIntro: boolean;
    setShouldPlayIntro: Dispatch<SetStateAction<boolean>>;
}

const introSoundtrackAudio = new Howl({
    src: [assets.introSoundtrackMp3],
});

enum IntroAnimationStatus {
    Loading = 'LOADING',
    Ready = 'READY',
    Playing = 'PLAYING',
    Finished = 'FINISHED',
}

const MainContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
`;

const AnimationContainer = styled.div`
    position: relative;
`;

const AnimationOverlay = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;

const EnterButton = styled.button`
    background: transparent;
    border: 1px solid white;
    color: white;
    cursor: pointer;
    font-family: ${({ theme }) => theme.fonts.main.family};
    font-size: 22px;
    letter-spacing: 2px;
    padding: 8px 16px;
    transition: all 200ms;

    &:hover {
        background-color: white;
        color: darkslategray;
    }
`;

const SkipButton = styled.button`
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-family: ${({ theme }) => theme.fonts.main.family};
    margin-top: 12px;
    opacity: 0.7;
    padding: 0;
    transition: opacity 200ms;

    &:hover {
        opacity: 1;
    }
`;

const Main = (props: MainProps) => {
    const [isEntered, setIsEntered] = React.useState(!props.shouldPlayIntro);
    const [introAnimationStatus, setIntroAnimationStatus] = React.useState(
        props.shouldPlayIntro
            ? IntroAnimationStatus.Loading
            : IntroAnimationStatus.Finished
    );
    const [isIntroSkipped, setIsIntroSkipped] = React.useState(false);

    async function playIntro() {
        introSoundtrackAudio.play();
        await new Promise(resolve => setTimeout(resolve, 100));
        setIntroAnimationStatus(IntroAnimationStatus.Playing);
        await new Promise(resolve => setTimeout(resolve, 3800));
        setIntroAnimationStatus(IntroAnimationStatus.Finished);
    }

    React.useEffect(() => {
        if (props.shouldPlayIntro && isEntered) {
            setIntroAnimationStatus(IntroAnimationStatus.Ready);
        }
    }, [props.shouldPlayIntro, isEntered]);

    React.useEffect(() => {
        if (introAnimationStatus === IntroAnimationStatus.Ready) {
            playIntro();
        }
    }, [introAnimationStatus]);

    React.useEffect(
        () => () => {
            introSoundtrackAudio.stop();
        },
        []
    );

    return (
        <MainContainer>
            <MixingAndMastering />
            {introAnimationStatus === IntroAnimationStatus.Finished ||
            isIntroSkipped ? (
                <MainContent
                    shouldPlayIntro={props.shouldPlayIntro && !isIntroSkipped}
                />
            ) : (
                <AnimationContainer className="target-me">
                    <MainAnimation
                        isPlaying={
                            introAnimationStatus ===
                            IntroAnimationStatus.Playing
                        }
                    />
                    {!isEntered && (
                        <AnimationOverlay className="inner">
                            <EnterButton onClick={() => setIsEntered(true)}>
                                ENTER
                            </EnterButton>
                        </AnimationOverlay>
                    )}
                </AnimationContainer>
            )}
            <Interview />
        </MainContainer>
    );
};

export default loadBeforeDisplay(withRouter(Main), assets.forPreload);
