import React, { ReactElement, ComponentClass } from 'react';

import { useAssetPreload } from 'shared/hooks';
import LoadingIndicator from 'shared/components/loading-indicator';

export default function loadBeforeDisplay<P>(
    Component: ((props: P) => ReactElement) | ComponentClass<P>,
    assets: { audio: string[]; img: string[] }
) {
    return (props: P) => {
        const { loadedCount, totalCount } = useAssetPreload(assets);
        return loadedCount === totalCount ? (
            <Component {...props} />
        ) : (
            <LoadingIndicator percent={(loadedCount * 100) / totalCount} />
        );
    };
}
