import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import * as assets from './assets';
import { usePopup, useAssetPreload } from 'shared/hooks';
import Popups from './popups';
import PageContainer from 'shared/components/page-container';
import Area from 'shared/components/area';
import { loadBeforeDisplay } from 'shared/hocs';

type HoverableArea = keyof typeof Popups | 'Back';

const MixRoomHoverImg = styled.img.attrs(() => ({
    ...assets.mixRoomHover,
    useMap: '#mix-room-map',
}))<{ hovered: HoverableArea | null }>`
    left: 0;
    opacity: ${props => (props.hovered ? 1 : 0)};
    position: absolute;

    ${props => {
        switch (props.hovered) {
            case 'Back': {
                return css`
                    clip-path: polygon(
                        569px 4px,
                        569px 30px,
                        695px 30px,
                        695px 3px
                    );
                `;
            }
            case 'Genelecs': {
                return css`
                    clip-path: polygon(
                        585px 221px,
                        583px 331px,
                        645px 355px,
                        672px 353px,
                        673px 220px,
                        668px 216px
                    );
                `;
            }
            case 'NeedQuiet': {
                return css`
                    clip-path: polygon(
                        467px 232px,
                        507px 224px,
                        545px 182px,
                        542px 100px,
                        640px 85px,
                        658px 148px,
                        524px 230px,
                        560px 344px,
                        440px 370px,
                        424px 326px
                    );
                `;
            }
            case 'Neve1073': {
                return css`
                    clip-path: polygon(
                        448px 193px,
                        447px 231px,
                        502px 222px,
                        501px 192px
                    );
                `;
            }
            case 'Pendulum': {
                return css`
                    clip-path: polygon(
                        384px 147px,
                        384px 191px,
                        502px 191px,
                        502px 147px
                    );
                `;
            }
            case 'Neve1095': {
                return css`
                    clip-path: polygon(
                        302px 148px,
                        306px 244px,
                        379px 223px,
                        380px 148px
                    );
                `;
            }
            case 'Compressors': {
                return css`
                    clip-path: polygon(
                        209px 128px,
                        213px 220px,
                        212px 225px,
                        214px 288px,
                        297px 274px,
                        294px 216px,
                        297px 201px,
                        298px 114px
                    );
                `;
            }
            case 'MixRack': {
                return css`
                    clip-path: polygon(
                        42px 128px,
                        36px 257px,
                        116px 271px,
                        129px 328px,
                        208px 341px,
                        208px 139px,
                        132px 139px
                    );
                `;
            }
            default:
                return null;
        }
    }}
`;

const ROOT_ID = 'mix-room-root';

const MixRoom = () => {
    useAssetPreload(assets.forPreload);
    const [hovered, setHovered] = useState<HoverableArea | null>(null);
    const { PopupModal, setCurrentPopup } = usePopup(Popups, ROOT_ID);
    const resetHovered = () => setHovered(null);
    return (
        <PageContainer id={ROOT_ID}>
            <img alt={assets.mixRoom.alt} src={assets.mixRoom.src} />
            <MixRoomHoverImg hovered={hovered} />
            <map name="mix-room-map">
                <Link to="/studio">
                    <Area
                        alt="Back"
                        coords="569,4,569,22,690,22,690,3"
                        onMouseEnter={() => setHovered('Back')}
                        onMouseLeave={resetHovered}
                    />
                </Link>
                <Area
                    alt="Genelecs"
                    coords="585,221,583,331,645,355,672,353,673,220,668,216"
                    onMouseEnter={() => setHovered('Genelecs')}
                    onMouseLeave={resetHovered}
                    onClick={() => setCurrentPopup('Genelecs')}
                />
                <Area
                    alt="Bob"
                    coords="440,293,435,349,457,361,548,342,541,304,520,264,513,230,484,227,465,245,460,275"
                    onMouseEnter={() => setHovered('NeedQuiet')}
                    onMouseLeave={resetHovered}
                    onClick={() => setCurrentPopup('NeedQuiet')}
                />
                <Area
                    alt="Neve1073"
                    coords="448,193,447,231,502,222,501,192"
                    onMouseEnter={() => setHovered('Neve1073')}
                    onMouseLeave={resetHovered}
                    onClick={() => setCurrentPopup('Neve1073')}
                />
                <Area
                    alt="Pendulum1"
                    coords="384,147,445,166"
                    shape="rect"
                    onMouseEnter={() => setHovered('Pendulum')}
                    onMouseLeave={resetHovered}
                    onClick={() => setCurrentPopup('Pendulum')}
                />
                <Area
                    alt="Pendulum2"
                    coords="448,176,502,191"
                    shape="rect"
                    onMouseEnter={() => setHovered('Pendulum')}
                    onMouseLeave={resetHovered}
                    onClick={() => setCurrentPopup('Pendulum')}
                />
                <Area
                    alt="Neve1095"
                    coords="302,148,306,244,379,223,380,148"
                    onMouseEnter={() => setHovered('Neve1095')}
                    onMouseLeave={resetHovered}
                    onClick={() => setCurrentPopup('Neve1095')}
                />
                <Area
                    alt="Compressors1"
                    coords="212,225,214,288,297,274,294,216"
                    onMouseEnter={() => setHovered('Compressors')}
                    onMouseLeave={resetHovered}
                    onClick={() => setCurrentPopup('Compressors')}
                />
                <Area
                    alt="Compressors2"
                    coords="209,128,213,220,297,201,298,114"
                    onMouseEnter={() => setHovered('Compressors')}
                    onMouseLeave={resetHovered}
                    onClick={() => setCurrentPopup('Compressors')}
                />
                <Area
                    alt="MixRack"
                    coords="42,128,36,257,116,271,129,328,208,341,208,139,132,139"
                    onMouseEnter={() => setHovered('MixRack')}
                    onMouseLeave={resetHovered}
                    onClick={() => setCurrentPopup('MixRack')}
                />
            </map>
            <PopupModal />
        </PageContainer>
    );
};

export default loadBeforeDisplay(MixRoom, assets.forPreloadBeforeDisplay);
