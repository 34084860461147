import { useEffect, useState } from 'react';
import { Howl } from 'howler';

export default function useAssetPreload({
    audio = [],
    img = [],
    onFinish = () => {},
}: {
    audio?: string[];
    img?: string[];
    onFinish?: () => void;
}) {
    const totalCount = audio.length + img.length;
    const [loadedCount, setLoadedCount] = useState(0);

    useEffect(() => {
        audio.forEach(audioSample => {
            new Howl({
                src: [audioSample],
                onload: () => setLoadedCount(prevState => prevState + 1),
            });
        });

        img.forEach(image => {
            const img = new Image();
            img.src = image;
            img.onload = () => setLoadedCount(prevState => prevState + 1);
        });
    }, [audio, img]);

    useEffect(() => {
        if (loadedCount === totalCount) {
            onFinish && onFinish();
        }
    }, [loadedCount, onFinish, totalCount]);

    return { loadedCount, totalCount };
}
