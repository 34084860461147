import * as React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { NavDot } from 'shared/components/nav';
import { Hideable } from 'shared/types';
import { fadeIn } from '../../styles';

const BASE_TOP = 47;

const StyledNavDot = styled(NavDot)<TravellingDotProps>`
    left: 490px;
    position: absolute;
    transition: top 500ms;
    top: ${props => `${BASE_TOP + props.location * 41}px`};
    ${fadeIn};

    &.exit {
        opacity: 1;
    }

    &.exit-active {
        opacity: 0;
        transition: opacity 1s;
    }
`;

interface TravellingDotProps extends Hideable {
    location: number;
}

const TravellingDot: React.FC<TravellingDotProps> = props => (
    <CSSTransition
        in={props.isVisible}
        mountOnEnter
        unmountOnExit
        timeout={1000}
    >
        <StyledNavDot {...props} />
    </CSSTransition>
);

export default TravellingDot;
