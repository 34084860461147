import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import * as assets from './assets';
import { usePopup, useAssetPreload } from 'shared/hooks';
import Popups from './popups';
import PageContainer from 'shared/components/page-container';
import Area from 'shared/components/area';
import { loadBeforeDisplay } from 'shared/hocs';

const GuitarsBackHoverImg = styled.img.attrs(() => assets.guitarsBackHover)`
    left: 561px;
    opacity: 0;
    position: absolute;

    &:hover {
        opacity: 1;
    }
`;

const ROOT_ID = 'guitars-root';

const Guitars = () => {
    useAssetPreload(assets.forPreload);
    const { PopupModal, setCurrentPopup } = usePopup(Popups, ROOT_ID);
    return (
        <PageContainer id={ROOT_ID}>
            <img
                alt={assets.guitarsBg.alt}
                src={assets.guitarsBg.src}
                useMap="#guitars-map"
            />
            <Link to="/studio">
                <GuitarsBackHoverImg />
            </Link>
            <map name="guitars-map">
                <Area
                    alt="sadowsky"
                    onClick={() => setCurrentPopup('Sadowsky')}
                    coords="669,75,652,85,627,122,598,169,597,195,628,214,650,207,677,106,684,84"
                />
                <Area
                    alt="gl"
                    onClick={() => setCurrentPopup('GL')}
                    coords="455,214,484,228,531,201,455,56,437,58,434,90"
                />
                <Area
                    alt="gibson-es335"
                    onClick={() => setCurrentPopup('GibsonEs335')}
                    coords="315,97,343,103,384,92,381,58,366,38,311,54,245,68,245,89"
                />
                <Area
                    alt="fender-tele-custom"
                    onClick={() => setCurrentPopup('FenderTeleCustom')}
                    coords="123,23,80,63,48,114,57,133,105,151,126,137,133,84,145,23,134,13"
                />
                <Area
                    alt="yamaha-sg1500"
                    onClick={() => setCurrentPopup('YamahaSg1500')}
                    coords="155,158,140,242,155,247,213,135,202,114,169,108,158,130"
                />
                <Area
                    alt="fender-strat"
                    onClick={() => setCurrentPopup('FenderStrat')}
                    coords="251,208,268,249,299,257,334,226,411,158,405,144,312,174"
                />
                <Area
                    alt="gibson-j200"
                    onClick={() => setCurrentPopup('GibsonJ200')}
                    coords="10,260,62,310,100,339,121,330,135,302,124,276,17,244"
                />
                <Area
                    alt="gibson-es295"
                    onClick={() => setCurrentPopup('GibsonEs295')}
                    coords="389,230,383,311,407,337,438,335,461,305,430,256,401,226"
                />
                <Area
                    alt="session-strat"
                    onClick={() => setCurrentPopup('SessionStrat')}
                    coords="546,243,598,325,638,377,681,348,680,325,561,229"
                />
                <Area
                    alt="fender-tele-deluxe"
                    onClick={() => setCurrentPopup('FenderTeleDeluxe')}
                    coords="182,327,153,397,165,407,250,320,249,302,214,284,198,299"
                />
                <Area
                    alt="gibson-howard-roberts"
                    onClick={() => setCurrentPopup('GibsonHowardRoberts')}
                    coords="299,321,324,429,345,457,381,443,389,415,316,314"
                />
                <Area
                    alt="pensa-suhr"
                    onClick={() => setCurrentPopup('PensaSuhr')}
                    coords="447,414,473,441,489,442,515,403,559,330,547,321,483,374"
                />
                <Area
                    alt="martin-d16h"
                    onClick={() => setCurrentPopup('MartinD16h')}
                    coords="47,479,59,503,82,510,108,507,124,484,98,396,84,394"
                />
                <Area
                    alt="guild-f50"
                    onClick={() => setCurrentPopup('GuildF50')}
                    coords="420,504,483,530,524,544,538,536,544,503,529,489,425,482"
                />
                <Area
                    alt="fender-jazz-bass"
                    onClick={() => setCurrentPopup('FenderJazzBass')}
                    coords="565,457,696,519,705,518,710,506,693,486,648,446,620,418,598,409,567,435"
                />
            </map>
            <PopupModal />
        </PageContainer>
    );
};

export default loadBeforeDisplay(Guitars, assets.forPreloadBeforeDisplay);
