import * as React from 'react';
import { Howl } from 'howler';
import { Link } from 'react-router-dom';

import {
    MainLogoContainer,
    MainLogoContainerOuter,
    MainLogoRecord,
    MainLogoText,
} from './components/main-logo';
import ArticlesAndInterviews from './components/articles-and-interviews';
import Background from './components/background';
import MainArrow from './components/main-arrow';
import MainBob from './components/main-bob';
import MainBobScribbles from './components/main-bob-scribbles';
import MainBobText from './components/main-bob-text';
import MainContactArt from './components/main-contact-art';
import MainContainer from './components/main-container';
import MainGuitar from './components/main-guitar';
import MainGuitarArt from './components/main-guitar-art';
import MainGuitarScribbles from './components/main-guitar-scribbles';
import MainGuitarText from './components/guitar-text';
import MainMicrophone from './components/main-microphone';
import MainMicrophoneLightning from './components/main-microphone-lightning';
import MainMixingBoardOver from './components/main-mixing-board-over';
import MainMixingBoardText from './components/main-mixing-board-text';
import MainMixingBoardUnder from './components/main-mixing-board-under';
import MainNav from './components/main-nav';
import MainSubmissionsText from './components/main-submissions-text';
import MainTimelineText from './components/main-timeline-text';
import { Nav } from 'shared/types';

import {
    bobPowerMp3,
    contactMp3,
    heartbeatMp3,
    projectsMp3,
    studioCloseMp3,
    studioEnterMp3,
    submissionsMp3,
    timelineMp3,
} from 'pages/main/assets';

import TravellingDot from './components/travelling-dot';
import { Paths, DISCOGRAPHY_LINK } from 'shared/constants';

interface MainContentProps {
    shouldPlayIntro: boolean;
}

const bobPowerAudio = new Howl({
    sprite: { __default: [0, 1875, true] },
    src: [bobPowerMp3],
    volume: 0.6,
});
const contactAudio = new Howl({
    sprite: { __default: [0, 700, true] },
    src: [contactMp3],
    volume: 0.6,
});
const discographyAudio = new Howl({
    sprite: { __default: [0, 932, true] },
    src: heartbeatMp3,
});
const timelineAudio = new Howl({ src: [timelineMp3], volume: 0.5 });
const projectsAudio = new Howl({ src: [projectsMp3] });
const studioEnterAudio = new Howl({ src: [studioEnterMp3] });
const studioCloseAudio = new Howl({ src: [studioCloseMp3] });
const submissionsAudio = new Howl({ src: [submissionsMp3] });

const delay = (timeout: number) =>
    new Promise(resolve => setTimeout(resolve, timeout));

enum VisibleSectionActionTypes {
    AddSection = 'ADD SECTION',
}

interface Action<T extends string> {
    type: T;
}

interface Payload<T> {
    payload: T;
}

function visibleSectionsReducer(state: Set<Nav>, action: VisibleSectionAction) {
    switch (action.type) {
        case VisibleSectionActionTypes.AddSection: {
            const newState = new Set(state);
            newState.add(action.payload);
            return newState;
        }
        default: {
            return state;
        }
    }
}

interface AddSectionAction
    extends Action<VisibleSectionActionTypes.AddSection>,
        Payload<Nav> {}

const addSectionAction = (
    payload: AddSectionAction['payload']
): AddSectionAction => ({
    type: VisibleSectionActionTypes.AddSection,
    payload,
});

type VisibleSectionAction = AddSectionAction;

const ROOT_ID = 'main-root';

const MainContent: React.FC<MainContentProps> = props => {
    const isMounted = React.useRef(true);
    const [activeNav, setActiveNav] = React.useState<Nav | null>(null);
    const [isDotVisible, setIsDotVisible] = React.useState(false);
    const [isInteractive, setIsInteractive] = React.useState(
        !props.shouldPlayIntro
    );
    const [dotLocation, setDotLocation] = React.useState(0);
    const [visibleSections, dispatch] = React.useReducer(
        visibleSectionsReducer,
        new Set<Nav>()
    );

    const animationSequence = React.useCallback(function*() {
        yield 1000;
        dispatch(addSectionAction('Studio'));
        setIsDotVisible(true);
        yield 1000;
        setDotLocation(1);
        yield 500;
        dispatch(addSectionAction('Projects'));
        yield 1000;
        setDotLocation(2);
        yield 500;
        dispatch(addSectionAction('Discography'));
        yield 1200;
        setDotLocation(3);
        yield 500;
        dispatch(addSectionAction('Bob Power'));
        yield 800;
        setDotLocation(4);
        yield 500;
        dispatch(addSectionAction('Timeline'));
        yield 800;
        setDotLocation(5);
        yield 500;
        dispatch(addSectionAction('Submissions'));
        yield 800;
        setDotLocation(6);
        yield 500;
        dispatch(addSectionAction('Contact'));
        yield 1000;
        setIsDotVisible(false);
        setIsInteractive(true);
    }, []);

    const runAnimationSequence = React.useCallback(async () => {
        const animations = animationSequence();
        while (true) {
            if (!isMounted.current) break;
            const step = animations.next();
            if (step.done) break;
            await delay(step.value);
        }
    }, [animationSequence]);

    React.useEffect(() => {
        if (props.shouldPlayIntro) {
            runAnimationSequence();
        }
        return () => {
            isMounted.current = false;
        };
    }, [props.shouldPlayIntro, runAnimationSequence]);

    const resetActiveNav = () => setActiveNav(null);

    const activateStudio = () => {
        if (!isInteractive) return;
        studioEnterAudio.play();
        setActiveNav('Studio');
    };

    const deactivateStudio = () => {
        if (!isInteractive) return;
        studioCloseAudio.play();
        resetActiveNav();
    };

    const activateProjects = () => {
        if (!isInteractive) return;
        projectsAudio.play();
        setActiveNav('Projects');
    };

    const activateDiscography = () => {
        if (!isInteractive) return;
        discographyAudio.play();
        setActiveNav('Discography');
    };

    const deactivateDiscography = () => {
        if (!isInteractive) return;
        discographyAudio.pause();
        discographyAudio.seek(0);
        resetActiveNav();
    };

    const activateBobPower = () => {
        if (!isInteractive) return;
        bobPowerAudio.play();
        setActiveNav('Bob Power');
    };

    const deactivateBobPower = () => {
        if (!isInteractive) return;
        bobPowerAudio.pause();
        bobPowerAudio.seek(0);
        resetActiveNav();
    };

    const activateTimeline = () => {
        if (!isInteractive) return;
        if (!timelineAudio.playing()) {
            timelineAudio.play();
        }
        setActiveNav('Timeline');
    };

    const activateSubmissions = () => {
        if (!isInteractive) return;
        submissionsAudio.play();
        setActiveNav('Submissions');
    };

    const activateContact = () => {
        if (!isInteractive) return;
        contactAudio.play();
        setActiveNav('Contact');
    };

    const deactivateContact = () => {
        if (!isInteractive) return;
        contactAudio.pause();
        contactAudio.seek(0);
        resetActiveNav();
    };

    React.useEffect(
        () => () => {
            bobPowerAudio.stop();
            contactAudio.stop();
            discographyAudio.stop();
            timelineAudio.stop();
            projectsAudio.stop();
            studioEnterAudio.stop();
            studioCloseAudio.stop();
            submissionsAudio.stop();
        },
        []
    );

    const getVisibility = (nav: Nav): boolean =>
        !props.shouldPlayIntro || visibleSections.has(nav);

    return (
        <MainContainer id={ROOT_ID}>
            <ArticlesAndInterviews isVisible={getVisibility('Bob Power')} />
            <MainLogoContainerOuter>
                <MainLogoContainer
                    isAnimating={visibleSections.has('Discography')}
                >
                    <a
                        href={DISCOGRAPHY_LINK}
                        onMouseEnter={activateDiscography}
                        onMouseLeave={deactivateDiscography}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <MainLogoRecord />
                    </a>
                    <MainLogoText isVisible={getVisibility('Bob Power')} />
                </MainLogoContainer>
            </MainLogoContainerOuter>
            <Link to={Paths.SUBMISSIONS}>
                <MainArrow
                    isAnimating={activeNav === 'Submissions'}
                    isVisible={getVisibility('Submissions')}
                    onMouseEnter={activateSubmissions}
                    onMouseLeave={resetActiveNav}
                />
            </Link>
            <Link to={Paths.BIO}>
                <MainBob
                    isAnimating={activeNav === 'Bob Power'}
                    isVisible={getVisibility('Bob Power')}
                    onMouseEnter={activateBobPower}
                    onMouseLeave={deactivateBobPower}
                />
            </Link>
            <MainBobScribbles isVisible={getVisibility('Bob Power')} />
            <MainBobText isVisible={getVisibility('Bob Power')} />
            <MainContactArt isVisible={getVisibility('Contact')} />
            <Link to={Paths.CONTACT}>
                <MainMicrophone
                    isVisible={getVisibility('Contact')}
                    onMouseEnter={activateContact}
                    onMouseLeave={deactivateContact}
                />
            </Link>
            <MainMicrophoneLightning
                isAnimating={activeNav === 'Contact'}
                isVisible={getVisibility('Contact')}
            />
            <Link to={Paths.PROJECTS}>
                <MainMixingBoardOver
                    isAnimating={activeNav === 'Projects'}
                    isVisible={getVisibility('Projects')}
                    onMouseEnter={activateProjects}
                    onMouseLeave={resetActiveNav}
                />
            </Link>
            <MainMixingBoardText
                isAnimating={activeNav === 'Projects'}
                isVisible={getVisibility('Projects')}
            />
            <MainMixingBoardUnder isVisible={getVisibility('Projects')} />
            <Link to={Paths.STUDIO}>
                <MainGuitar
                    isAnimating={activeNav === 'Studio'}
                    isVisible={getVisibility('Studio')}
                    onMouseEnter={activateStudio}
                    onMouseLeave={deactivateStudio}
                />
            </Link>
            <MainGuitarArt isVisible={getVisibility('Studio')} />
            <MainGuitarScribbles isVisible={getVisibility('Studio')} />
            <MainGuitarText
                isAnimating={activeNav === 'Studio'}
                isVisible={getVisibility('Studio')}
            />
            <MainNav
                activeNav={activeNav}
                items={[
                    {
                        isVisible: getVisibility('Studio'),
                        nav: 'Studio',
                        onMouseEnter: activateStudio,
                        onMouseLeave: deactivateStudio,
                        title: 'Studio',
                        to: Paths.STUDIO,
                    },
                    {
                        isVisible: getVisibility('Projects'),
                        nav: 'Projects',
                        onMouseEnter: activateProjects,
                        onMouseLeave: resetActiveNav,
                        title: 'Projects',
                        to: Paths.PROJECTS,
                    },
                    {
                        isAnimating: true,
                        isVisible: getVisibility('Discography'),
                        nav: 'Discography',
                        onMouseEnter: activateDiscography,
                        onMouseLeave: deactivateDiscography,
                        title: 'Discography',
                        to: DISCOGRAPHY_LINK,
                        external: true,
                    },
                    {
                        nav: 'Bob Power',
                        isVisible: getVisibility('Bob Power'),
                        onMouseEnter: activateBobPower,
                        onMouseLeave: deactivateBobPower,
                        title: 'Bob Power',
                        to: Paths.BIO,
                    },
                    {
                        nav: 'Timeline',
                        isVisible: getVisibility('Timeline'),
                        onMouseEnter: activateTimeline,
                        onMouseLeave: resetActiveNav,
                        title: 'Timeline',
                        to: Paths.TIMELINE,
                    },
                    {
                        nav: 'Submissions',
                        isVisible: getVisibility('Submissions'),
                        onMouseEnter: activateSubmissions,
                        onMouseLeave: resetActiveNav,
                        title: 'Submissions',
                        to: Paths.SUBMISSIONS,
                    },
                    {
                        nav: 'Contact',
                        isVisible: getVisibility('Contact'),
                        onMouseEnter: activateContact,
                        onMouseLeave: deactivateContact,
                        title: 'Contact',
                        to: Paths.CONTACT,
                    },
                ]}
            />
            <MainSubmissionsText
                isAnimating={activeNav === 'Submissions'}
                isVisible={getVisibility('Submissions')}
            />
            <Link to={Paths.TIMELINE}>
                <MainTimelineText
                    isAnimating={activeNav === 'Timeline'}
                    isVisible={getVisibility('Timeline')}
                    onMouseEnter={activateTimeline}
                    onMouseLeave={resetActiveNav}
                />
            </Link>
            <Background />
            <TravellingDot location={dotLocation} isVisible={isDotVisible} />
        </MainContainer>
    );
};

export default MainContent;
