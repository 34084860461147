import React from 'react';
import styled from 'styled-components';

const White = styled.span`
    color: white;
    font-weight: bold;
`;

const BioTextContainer = styled.div`
    font-family: ${({ theme }) => theme.fonts.body.family};
    font-size: 9px;
    left: 23px;
    line-height: 13px;
    max-width: 478px;
    position: absolute;
    top: 160px;
`;

const BioP = styled.p`
    margin: 0 0;
`;

const BioText = () => (
    <BioTextContainer>
        <BioP>
            <White>In the recording world,</White>{' '}
            <strong>Power has produced, recorded, or mixed</strong> hundreds of
            pop and jazz recordings for such artists and groups as{' '}
            <strong>Erykah Badu</strong> (1998 Grammy “Best Female R&B Vocal”,
            “Best Female R&B Album”),{' '}
            <strong>
                D'Angelo, India.Arie, Chaka Khan, MeShell N'degeOcello Ozomatli
            </strong>{' '}
            (2002 Grammy “Best Alternative Latin Album”),{' '}
            <strong>
                De La Soul, A Tribe Called Quest, Miles Davis, the Roots, Tony
                Toni Toné, Macy Gray, Curtis Mayfield, David Byrne (Talking
                Heads), Spike Lee, The Brand New Heavies, Big Daddy Kane, Maceo
                Parker, Pat Metheny, KRS 1, Phylis Hyman, The Jungle Brothers,
                Quincy Jones (Remix), Greg Osby, and Run DMC.
            </strong>
        </BioP>
        <BioP>
            <White>A 1997 Grammy Award nominee</White> himself, Bob Power has
            had over 50 charting records, and has received more than 20 gold or
            platinum records.
        </BioP>
        <BioP>
            <White>As a feature T.V. composer,</White> Power is best known for
            his theme music for the Emmy Award winning{' '}
            <strong>OVER EASY</strong> on <strong>P.B.S.</strong>; he also
            composed the original theme and score for the{' '}
            <strong>Disney</strong> family series{' '}
            <strong>THE SCHEME OF THINGS.</strong> Other major television
            credits include the theme and score for the hour-long prime time
            special <strong>FOUR ALONE: THE OLDER WOMAN IN AMERICA</strong>, the
            National Endowment for the Humanities funded{' '}
            <strong>THE STATE OF THE LANGUAGE</strong>, and music for the{' '}
            <strong>LIFETIME CHANNEL</strong> and{' '}
            <strong>Disney's IMAGINE.</strong>
        </BioP>
        <BioP>
            <White>
                An accomplished instrumentalist and conductor as well,
            </White>{' '}
            Bob Power has accompanied many prominent artists on national
            television appearances; among them{' '}
            <strong>
                Mary Martin, Bob Hope, Judy Collins, Anthony Newley, Earl"Fatha"
                Hines, Margaret Whiting, Eartha Kitt, Patti Page, Ethel Merman,
                and Joel Grey.
            </strong>
        </BioP>
        <BioP>
            <White>In the broadcast advertising world,</White> Bob Power has
            written and produced music for{' '}
            <strong>
                Coca Cola, Philip Morris, Mercedes, Casio, Hertz, Jello, AT&T,
                Sprite, the U.S. Postal Service, Illinois Bell, Hasbro,
                Hardee's, Duracell, Fritos, Ragu, A & W, L'Oreal, Sharp
                Electronics, Chef Boy-ar-dee, Intel, Elizabeth Arden,
                Warner-Lambert, Purina, the American Cancer Society (EMMY Award
                winner), Chesebrough-Ponds, Cutex, and the Sun Company.
            </strong>
        </BioP>
    </BioTextContainer>
);

export default BioText;
