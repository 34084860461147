import * as React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { fadeIn } from '../../styles';
import { Hideable } from 'shared/types';
import { ArticlesAndInterviews } from 'shared/components/nav';

const StyledArticlesAndInterviews = styled(ArticlesAndInterviews)`
    left: 661px;
    position: absolute;
    top: 239px;

    ${fadeIn}
`;

export default (props: Hideable) => (
    <CSSTransition in={props.isVisible} mountOnEnter timeout={1000}>
        <StyledArticlesAndInterviews />
    </CSSTransition>
);
