import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
    fonts: {
        main: {
            family: 'Ubuntu, sans-serif',
        },
        body: {
            family: 'Verdana, Arial, Helvetica, sans-serif',
        },
    },
};

export default theme;
