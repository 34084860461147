import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import * as assets from './assets';
import Popups from './popups';
import { BioText } from './components';
import { Area, PageContainer } from 'shared/components';
import { LogoContainer, LogoRecord } from 'shared/components/logo';
import { Nav } from 'shared/components/nav';
import { usePopup } from 'shared/hooks';
import { Paths } from 'shared/constants';
import { loadBeforeDisplay } from 'shared/hocs';

export const images = [];

const ClickItImg = styled.img.attrs(() => assets.clickIt)`
    cursor: pointer;
    left: 536px;
    position: absolute;
    top: 371px;
`;

const PageTitleImg = styled.img.attrs(() => assets.bioPageTitle)`
    left: 56px;
    position: absolute;
    top: 23px;
`;

const ArticlesInterviewsButton = styled.div`
    background-image: url(${assets.articlesAndInterviewsButton.src});
    cursor: pointer;
    height: 23px;
    left: 18px;
    position: absolute;
    top: 0;
    width: 175px;

    &:hover {
        background-image: url(${assets.articlesAndInterviewsButtonHover.src});
    }
`;

const LogoContainerOuter = styled.div`
    left: 498px;
    position: absolute;
    top: 24px;
`;

const ROOT_ID = 'bio-root';

const BioNav = styled(Nav)`
    left: 566px;
    position: absolute;
    top: 105px;
`;

const Bio = () => {
    const { PopupModal, setCurrentPopup } = usePopup(Popups, ROOT_ID);
    return (
        <PageContainer id={ROOT_ID}>
            <img
                alt={assets.bioBg.alt}
                src={assets.bioBg.src}
                useMap="#bio-map"
            />
            <map name="bio-map">
                <Area
                    alt="suitcase"
                    coords="586,508,52"
                    onClick={() => setCurrentPopup('Suitcase')}
                    shape="circle"
                />
                <Link to={Paths.HOME}>
                    <Area alt="logo-text" coords="591,43,715,55" shape="rect" />
                </Link>
            </map>
            <Link to={Paths.ARTICLES_AND_INTERVIEWS}>
                <ArticlesInterviewsButton />
            </Link>
            <ClickItImg onClick={() => setCurrentPopup('Suitcase')} />
            <PageTitleImg />
            <LogoContainerOuter>
                <LogoContainer>
                    <Link to={Paths.HOME}>
                        <LogoRecord />
                    </Link>
                </LogoContainer>
            </LogoContainerOuter>
            <PopupModal />
            <BioNav />
            <BioText />
        </PageContainer>
    );
};

export default loadBeforeDisplay(Bio, assets.forPreload);
