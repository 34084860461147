import {
    articlesAndInterviewsBg,
    articlesAndInterviewsTitle,
    clickIt,
} from 'shared/assets/img';

import { fenderTeleCustom } from './popups';

export { articlesAndInterviewsBg, articlesAndInterviewsTitle, clickIt };

export const forPreload = {
    img: [
        articlesAndInterviewsBg,
        articlesAndInterviewsTitle,
        clickIt,
        fenderTeleCustom,
    ].map(x => x.src),
    audio: [fenderTeleCustom.audio || ''],
};
