import {
    articlesAndInterviewsButtonHover,
    articlesAndInterviewsButton,
    bioBg,
    bioPageTitle,
    clickIt,
} from 'shared/assets/img';

import { suitcase } from './popups';

export {
    articlesAndInterviewsButtonHover,
    articlesAndInterviewsButton,
    bioBg,
    bioPageTitle,
    clickIt,
};

export const forPreload = {
    img: [
        articlesAndInterviewsButtonHover,
        articlesAndInterviewsButton,
        bioBg,
        bioPageTitle,
        clickIt,
        suitcase,
    ].map(x => x.src),
    audio: [suitcase.audio || ''],
};
