export const Paths = {
    HOME: '/',
    BIO: '/bio',
    CONTACT: '/contact',
    GUITARS: '/guitars',
    MIC_CLOSET: '/mic-closet',
    MIX_ROOM: '/mix-room',
    PROJECTS: '/projects',
    STUDIO: '/studio',
    SUBMISSIONS: '/submissions',
    TIMELINE: '/timeline',
    ARTICLES_AND_INTERVIEWS: '/articles-and-interviews',
};

export const DISCOGRAPHY_LINK =
    'https://www.discogs.com/artist/67370-Bob-Power?sort=year,asc&limit=500';
