import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Paths } from 'shared/constants';

const ContactTextContainer = styled.div`
    color: white;
    font-family: ${({ theme }) => theme.fonts.body.family};
    font-size: 10px;
    line-height: 150%;
    max-width: 290px;
`;

const StyledLink = styled(Link)`
    color: white;
    text-decoration: underline;
`;

const A = styled.a`
    color: white;
    text-decoration: underline;
`;

const P = styled.p`
    margin: 16px 0;
`;

const ContactText = (props: { className?: string }) => (
    <ContactTextContainer className={props.className}>
        <P>
            <strong>
                I'm delivering mixes and masterings from my studio, Chez Bob. As
                always, tons of the finest vintage and modern analog gear (see{' '}
                <StyledLink to={Paths.STUDIO}>Studio</StyledLink>),
                super-accurate monitoring, almost every plug-in known to
                humankind...and the same insane commitment to quality.
            </strong>
        </P>
        <P>
            For mix and mastering bookings:{' '}
            <strong>
                <A href="mailto:bookings@bobpower.com">bookings@bobpower.com</A>
            </strong>
        </P>
        <p>
            For production:{' '}
            <strong>
                <A href="mailto:info@bobpower.com">info@bobpower.com</A>
            </strong>
        </p>
    </ContactTextContainer>
);

export default ContactText;
