import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { LogoContainer, LogoRecord } from 'shared/components/logo';
import Popups from './popups';
import { usePopup } from 'shared/hooks';
import Links from './components/links';
import PageContainer from 'shared/components/page-container';
import Area from 'shared/components/area';
import * as assets from './assets';
import { Paths } from 'shared/constants';
import { loadBeforeDisplay } from 'shared/hocs';

const StudioRecordBgImg = styled.img.attrs(() => assets.studioRecordBg)`
    left: 502px;
    position: absolute;
    top: 24px;
`;

const LogoContainerOuter = styled.div`
    left: 498px;
    top: 23px;
    position: absolute;
`;

const ROOT_ID = 'studio-root';

const Studio = () => {
    const { PopupModal, setCurrentPopup } = usePopup(Popups, ROOT_ID);
    return (
        <PageContainer id={ROOT_ID}>
            <img
                alt={assets.studioBg.alt}
                src={assets.studioBg.src}
                useMap="#studio-map"
            />
            <StudioRecordBgImg />
            <LogoContainerOuter>
                <LogoContainer>
                    <Link to={Paths.HOME}>
                        <LogoRecord />
                    </Link>
                </LogoContainer>
            </LogoContainerOuter>
            <Links />
            <map name="studio-map">
                <Link to={Paths.HOME}>
                    <Area alt="logo-text" coords="591,43,715,55" shape="rect" />
                </Link>
                <Link to={Paths.MIC_CLOSET}>
                    <Area
                        alt="mic-closet"
                        coords="626,132,701,169,725,114,722,85,666,83,672,71,651,80"
                    />
                </Link>
                <Area
                    alt="console"
                    coords="536,264,602,433,610,438,724,366,724,218,680,185"
                    onClick={() => setCurrentPopup('Console')}
                />
                <Link to={Paths.MIX_ROOM}>
                    <Area
                        alt="mix-room"
                        coords="504,392,579,390,497,615,439,569"
                    />
                </Link>
                <Area
                    alt="synth-racks"
                    coords="159,491,88,618,424,619,402,511,224,478,213,505"
                    onClick={() => setCurrentPopup('Synths')}
                />
                <Area
                    alt="aguilar"
                    coords="16,235,13,442,211,504,270,359,24,223"
                    onClick={() => setCurrentPopup('Aguilar')}
                />
                <Link to={Paths.GUITARS}>
                    <Area
                        alt="guitars"
                        coords="15,70,82,215,101,211,123,158,63,39"
                    />
                </Link>
                <Area
                    alt="audio-rack"
                    coords="112,115,230,333,271,357,259,388,275,409,440,370,488,326,444,49"
                    onClick={() => setCurrentPopup('AudioRack')}
                />
                <Area
                    alt="stompboxes"
                    coords="246,431,267,431,271,442,257,454,275,486,287,489,356,503,382,496,397,474,429,433,429,422,421,413,376,385,275,410"
                    onClick={() => setCurrentPopup('Stompboxes')}
                />
            </map>
            <PopupModal />
        </PageContainer>
    );
};

export default loadBeforeDisplay(Studio, assets.forPreload);
