import { css } from 'styled-components';

export default css`
    &.enter {
        opacity: 0;
    }

    &.enter-active {
        opacity: 1;
        transition: opacity 1s;
    }

    &.enter-done {
        opacity: 1;
    }
`;
