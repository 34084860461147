import React, { ReactElement, useState, useCallback } from 'react';

import Modal from 'shared/components/modal';

export default function usePopup<T extends Record<string, () => ReactElement>>(
    popups: T,
    rootId: string
) {
    const [currentPopup, setCurrentPopup] = useState<
        keyof typeof popups | null
    >(null);

    const PopupImage: (() => ReactElement) | null = currentPopup
        ? popups[currentPopup]
        : null;

    const clearPopup = useCallback(() => {
        setCurrentPopup(null);
    }, [setCurrentPopup]);

    const PopupModal = useCallback(
        () => (
            <Modal
                isOpen={currentPopup !== null}
                onClose={clearPopup}
                root={document.getElementById(rootId)}
            >
                {PopupImage && <PopupImage />}
            </Modal>
        ),
        [PopupImage, clearPopup, currentPopup, rootId]
    );

    return { PopupModal, setCurrentPopup };
}
