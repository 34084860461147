import React from 'react';
import styled from 'styled-components';

const White = styled.span`
    color: white;
    font-weight: 700;
`;

const EmailLink = styled.a.attrs(() => ({
    href: 'mailto:tracks@bobpower.com',
}))`
    font-weight: 700;
    text-decoration: underline;

    &:hover {
        color: white;
    }
`;

const SubmissionsTextContainer = styled.div`
    font-family: ${({ theme }) => theme.fonts.body.family};
    font-size: 10px;
    line-height: 15px;
    max-width: 480px;
`;

const SubmissionsText = (props: { className?: string }) => {
    return (
        <SubmissionsTextContainer className={props.className}>
            <p>
                <White>I’m always interested</White>{' '}
                <strong>in hearing what music people are making,</strong> and
                I'd love to hear what you’re doing.
            </p>
            <p>
                <White>If you’d like to send me some music:</White>
            </p>
            <ol>
                <li>
                    Email us at <EmailLink>tracks@bobpower.com</EmailLink>, and
                    give us a little background about you (and/or band), i.e.
                    where you’re from, how many band members, who writes, and
                    ultimately what you want to do with your music.
                </li>
                <li>
                    We’ll email you back with instructions on how to upload
                    music to us.
                </li>
            </ol>
            <p>
                <White>Right now,</White>{' '}
                <strong>
                    I have to limit submissions to 2 songs, totaling 9 minutes
                    or less.
                </strong>{' '}
                Any more than that becomes an overload to our system. Sometimes
                I’m a little overwhelmed with work, so you may not hear back
                from me right away, but{' '}
                <strong>I will listen and mail you back!</strong>
            </p>
        </SubmissionsTextContainer>
    );
};

export default SubmissionsText;
