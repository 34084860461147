import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Animatable } from 'shared/types';

interface NavDotProps extends Partial<Animatable> {}

const animateSvg = keyframes`
    0% {
        transform: scale(0.125);
    }
    30% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.125);
    }
`;

const Svg = styled.svg.attrs(() => ({
    viewBox: '0 0 40 40',
}))<NavDotProps>`
    ${props =>
        props.isAnimating &&
        css`
            animation: ${animateSvg} 920ms ease-out infinite;
        `}
    left: -82px;
    position: absolute;
    transform: scale(0.125);
    width: 128px;
`;

const animateCircle = keyframes`
    0% {}
    30% {
        stroke-width: 8;
    }
    100% {}
`;

const Circle = styled.circle<NavDotProps>`
    ${props =>
        props.isAnimating &&
        css`
            animation: ${animateCircle} 920ms ease-out infinite;
        `}
    fill: black;
    stroke: white;
    stroke-width: 3;
`;

const InnerCircle = styled.circle`
    fill: white;
`;

const MainNavDot: React.FC<NavDotProps> = props => (
    <Svg {...props}>
        <Circle cx="20" cy="20" r="8" {...props} />
        {props.isAnimating ? <InnerCircle cx="20" cy="20" r="0.4" /> : null}
    </Svg>
);

MainNavDot.defaultProps = {
    isAnimating: false,
};

export default MainNavDot;
