import {
    fenderJazzBass,
    fenderStrat,
    fenderTeleCustom,
    fenderTeleDeluxe,
    gibsonEs295,
    gibsonEs335,
    gibsonHowardRoberts,
    gibsonJ200,
    gl,
    guildF50,
    martinD16h,
    pensaSuhr,
    sadowsky,
    sessionStrat,
    yamahaSg1500,
} from 'shared/components/popups';
export {
    fenderJazzBass,
    fenderStrat,
    fenderTeleCustom,
    fenderTeleDeluxe,
    gibsonEs295,
    gibsonEs335,
    gibsonHowardRoberts,
    gibsonJ200,
    gl,
    guildF50,
    martinD16h,
    pensaSuhr,
    sadowsky,
    sessionStrat,
    yamahaSg1500,
};

export default {
    FenderJazzBass: fenderJazzBass.component,
    FenderStrat: fenderStrat.component,
    FenderTeleCustom: fenderTeleCustom.component,
    FenderTeleDeluxe: fenderTeleDeluxe.component,
    GibsonEs295: gibsonEs295.component,
    GibsonEs335: gibsonEs335.component,
    GibsonHowardRoberts: gibsonHowardRoberts.component,
    GibsonJ200: gibsonJ200.component,
    GL: gl.component,
    GuildF50: guildF50.component,
    MartinD16h: martinD16h.component,
    PensaSuhr: pensaSuhr.component,
    Sadowsky: sadowsky.component,
    SessionStrat: sessionStrat.component,
    YamahaSg1500: yamahaSg1500.component,
};
