import * as React from 'react';
import styled, { css } from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { Animatable, Hideable } from 'shared/types';
import { fadeIn } from '../../styles';

import { projectsText } from 'pages/main/assets';

interface MixingBoardTextProps extends Animatable, Hideable {}

// eslint-disable-next-line
const MixingBoardTextImg = styled.img.attrs(() => projectsText)<
    MixingBoardTextProps
>`
    filter: blur(1px);
    left: 177px;
    opacity: 0.6;
    position: absolute;
    top: 440px;
    transition: all 500ms;
    width: 330px;
    z-index: 1;

    ${props =>
        props.isAnimating &&
        css`
            filter: none;
            opacity: 1;
        `}

    ${fadeIn}
`;

const MixingBoardText: React.FC<MixingBoardTextProps> = props => (
    <CSSTransition in={props.isVisible} mountOnEnter timeout={1000}>
        <MixingBoardTextImg {...props} />
    </CSSTransition>
);

export default MixingBoardText;
