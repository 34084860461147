import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { mainArrow } from 'pages/main/assets';
import { CSSTransition } from 'react-transition-group';
import { Animatable, Hoverable, Hideable } from 'shared/types';

const translate = keyframes`
    0% { transform: translateX(0) }
    8% { transform: translateX(5px); }
    25% { transform: translateX(5px); }
    30% { transform: translateX(0) }
    35% { transform: translateX(-5px); }
    45% { transform: translateX(-5px); }
    50% { transform: translateX(0) }
    55% { transform: translateX(5px); }
    70% { transform: translateX(5px); }
    75% { transform: translateX(0) }
    85% { transform: translateX(0) }
    90% { transform: translateX(-5px); }
    96% { transform: translateX(-5px); }
    100% { transform: translateX(0) }
`;

interface MainArrowProps extends Animatable, Hideable, Hoverable {}

const MainArrowImg = styled.img.attrs(() => mainArrow)<MainArrowProps>`
    ${props =>
        props.isAnimating &&
        css`
            animation: ${translate} 4s linear infinite;
        `}
    cursor: pointer;
    left: 580px;
    position: absolute;
    top: 395px;
    width: 100px;

    &.enter {
        transform: translateX(200px);
    }

    &.enter-active {
        transform: translateX(0);
        transition: transform 1s;
    }
`;

const MainArrow: React.FC<MainArrowProps> = props => (
    <CSSTransition in={props.isVisible} mountOnEnter timeout={1000}>
        <MainArrowImg {...props} />
    </CSSTransition>
);

export default MainArrow;
