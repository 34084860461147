import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { Animatable, Hoverable, Hideable } from 'shared/types';

import { mainBob } from 'pages/main/assets';

interface MainBobProps extends Animatable, Hideable, Hoverable {}

const rotateStart = keyframes`
    from {
        opacity: 0;
        transform: rotate(0deg);
    }
    to {
        opacity: 1;
        transform: rotate(360deg);
    }
`;

const rotate = keyframes`
    0% { 
        transform:rotate(0deg);
    }
    25% {
        transform: rotate(360deg);
    }
    50% {
        transform: rotate(360deg);
    }
    70% {
        transform: rotate(200deg);
    }
    80% {
        transform: rotate(200deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const BobImg = styled.img.attrs(() => mainBob)<MainBobProps>`
    ${props =>
        props.isAnimating &&
        css`
            animation: ${rotate} 1875ms linear infinite;
        `}
    left: 52px;
    position: absolute;
    top: 55px;
    z-index: 1;

    &.enter-active {
        animation: ${rotateStart} 1s ease-out;
    }
`;

const Bob: React.FC<MainBobProps> = props => (
    <CSSTransition in={props.isVisible} mountOnEnter timeout={1000}>
        <BobImg {...props} />
    </CSSTransition>
);

export default Bob;
